import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';

// models
import { ClientModel } from '../core/models/client.model';

const firebaseConfig: FirebaseOptions = {
	apiKey: 'AIzaSyDAZyp7MEkUrO4XZzpQe7dgLNLbsI-5l4g',
	authDomain: 'sumasix-app.firebaseapp.com',
	databaseURL: 'https://sumasix-app.firebaseio.com',
	projectId: 'sumasix-app',
	storageBucket: 'sumasix-app.appspot.com',
	messagingSenderId: '623073046552',
	appId: '1:623073046552:web:15b93b2e5e5529b3ec136d',
	measurementId: 'G-WHRTPGRJ5R',
};

export const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);

export function logPageView(pageTitle: string, client?: ClientModel) {
	setClientProperties(client);
	pageTitle = `Web ${pageTitle}`;
	logEvent(analytics, 'page_view', { page_title: pageTitle });
}

export function logClickEvent(name: string, client?: ClientModel) {
	setClientProperties(client);
	name = name.replace(/\s/g, '_').toLowerCase();
	logEvent(analytics, `web_click_${name}`);
}

function setClientProperties(client?: ClientModel) {
	if (client?.clienteId) {
		setUserProperties(analytics, {
			client_id: client.clienteId,
			client_name: client.nombre + ' ' + client.apellidos,
		});
	}
}
