// models
import { ActionModel } from '../../core/models/redux.model';
import {
	CouponStateModel,
	CouponModel,
	RedeemCouponModel,
	CouponCategoryModel,
} from '../../core/models/coupon.model';

// types
import type from '../types/coupon';
import clientType from '../types/client';

const INITIAL_STATE: CouponStateModel = {
	error: '',
	loading: false,
	categories: [],
	available: [],
	used: [],
	redeem: [],
};

export default function (coupon = INITIAL_STATE, action: ActionModel) {
	switch (action.type) {
		case type.addCoupon: {
			const unRepeated: CouponStateModel = action.payload;
			return {
				...coupon,
				available: updateCoupons(coupon.available, unRepeated.available),
				used: updateCoupons(coupon.used, unRepeated.used),
				categories: updateCategories(coupon.categories, unRepeated.categories),
				loading: false,
				error: '',
			};
		}
		case type.addUsed: {
			const unRepeated: CouponModel[] = action.payload;
			return {
				...coupon,
				used: updateCoupons(coupon.used, unRepeated),
				loading: false,
				error: '',
			};
		}
		case type.addCategory: {
			const unRepeated: CouponCategoryModel[] = action.payload;
			return {
				...coupon,
				categories: updateCategories(coupon.categories, unRepeated),
				loading: false,
				error: '',
			};
		}
		case type.error: {
			return { ...coupon, loading: false, error: action.payload };
		}
		case type.loading: {
			return { ...coupon, loading: true, error: '' };
		}
		case type.removeCoupon: {
			const updateCoupons = coupon.available.map(coupon => {
				let res = coupon;
				const couponUpdate = action.payload;
				if (res.cuponId === couponUpdate.cuponId) {
					res = {
						...coupon,
						monto: coupon.monto - couponUpdate.monto,
					};
				}
				return res;
			});
			return {
				...coupon,
				error: '',
				loading: false,
				data: updateCoupons.filter(coupon => coupon.monto > 0),
			};
		}
		case type.addRedeem: {
			const unRepeated: RedeemCouponModel[] = action.payload;
			return {
				...coupon,
				redeem: coupon.redeem
					.map(redeem => {
						const index = unRepeated.findIndex(u => u.code === redeem.code);
						let res = redeem;
						if (index !== -1) {
							res = {
								...redeem,
								...unRepeated[index],
							};
							unRepeated.splice(index, 1);
						}
						return res;
					})
					.concat(unRepeated),
				error: '',
				loading: false,
			};
		}
		case type.removeRedeem: {
			return {
				...coupon,
				redeem: coupon.redeem.map(redeem => {
					let res = redeem;
					if (action.payload === redeem.code) {
						res = {
							...redeem,
							isRedeem: true,
						};
					}
					return res;
				}),
				error: '',
				loading: false,
			};
		}
		case clientType.logout:
			return INITIAL_STATE;
		default:
			return coupon;
	}
}

function updateCoupons(coupons: CouponModel[], unRepeated: CouponModel[]) {
	return coupons
		.map(coupon => {
			const index = unRepeated.findIndex(
				item => item.cuponId === coupon.cuponId,
			);
			let res = coupon;
			if (index !== -1) {
				res = {
					...coupon,
					...unRepeated[index],
				};
				unRepeated.splice(index, 1);
			}
			return res;
		})
		.concat(unRepeated);
}

function updateCategories(
	categories: CouponCategoryModel[],
	unRepeated: CouponCategoryModel[],
) {
	return categories
		.map(category => {
			const index = unRepeated.findIndex(item => item.id === category.id);
			let res = category;
			if (index !== -1) {
				res = {
					...category,
					...unRepeated[index],
				};
				unRepeated.splice(index, 1);
			}
			return res;
		})
		.concat(unRepeated);
}
