import { useEffect } from 'react';
import { connect, DispatchProp } from 'react-redux';
import QRCode from 'react-qr-code';

// actions
import { addToast } from '../../redux/actions/toast';

// models
import { StateModel } from '../../core/models/state.model';
import { ClientModel } from '../../core/models/client.model';

// components
import AdCarousel from '../../components/Ads/Carousel';
import NavLeft from '../../components/NavLeft';

// utils
import { logPageView } from '../../utils/firebaseConfig';

// assets
import shareIcon from '../../assets/icons/share.png';

interface InviteFriendsProps {
	client: ClientModel;
}

function InviteFriends({
	client,
	dispatch,
}: DispatchProp & InviteFriendsProps) {
	const canShare = !!navigator.share;

	useEffect(() => {
		logPageView('Invite Friends', client);
	}, []);

	const copyCode = () => {
		const text = `Hola, te regalo un latón de cerveza de 16oz, de tu marca HEINEKEN favorita. Descarga la APP SumaSix ingresa mi codigo ${client.clienteId}`;
		const message = {
			title: 'SumaSix',
			text,
		};
		if (canShare) {
			navigator.share(message);
		} else {
			navigator.clipboard.writeText(text);
			addToast(dispatch, {
				title: 'Código copiado al portapapeles',
			});
		}
	};

	return (
		<>
			<NavLeft name='Invita y Gana' />
			<AdCarousel />
			<section className='pb-10 flex flex-col items-center md:flex-row md:justify-evenly'>
				<p className='text-center leading-5 mb-10 lg:text-xl'>
					Recibe 300 puntos por cada amigo
					<br />
					que descargue la aplicación y utilice
					<br />
					tu código de promoción
				</p>
				<section className='md:text-center'>
					<p className='text-xl text-center font-bold mb-5 lg:text-xl'>
						Comparte tu código
					</p>
					<QRCode
						value={client.clienteId.toString()}
						size={200}
						color='black'
						bgColor='white'
					/>
					<p className='text-2xl text-center font-bold mt-2'>
						{client.clienteId}
					</p>
					<button
						className='bg-primary w-[170px] h-8 flex items-center justify-center rounded-lg px-3 mt-5 lg:w-56 lg:h-12'
						onClick={copyCode}>
						<img src={shareIcon} />
						<p className='text-white text-sm ml-2 lg:text-lg'>Compartir</p>
					</button>
				</section>
			</section>
		</>
	);
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(InviteFriends);
