import { connect, DispatchProp } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// actions
import { addBrand } from '../../redux/actions/brand';
import { removeModal } from '../../redux/actions/modals';
import {
	addFavoriteBrand,
	clientSetFavoriteBrands,
} from '../../redux/actions/client';

// models
import { StateModel } from '../../core/models/state.model';
import { ModalModel } from '../../core/models/modal.model';
import { BrandModel, BrandStateModel } from '../../core/models/brand.model';
import { ClientModel } from '../../core/models/client.model';

interface AddModalProps {
	id: ModalModel['id'];
	brand: BrandStateModel;
	client: ClientModel;
}

function AddModalComponent({
	id,
	brand,
	client,
	dispatch,
}: AddModalProps & DispatchProp) {
	const location = useLocation();
	const isProfileScreen = location.pathname.includes('perfil');

	const form = useFormik({
		initialValues: initialValues(),
		validationSchema: Yup.object(validationSchema()),
		validateOnChange: false,
		onSubmit: values => {
			const defaultBrand = brand.data.find(
				brand => brand.nombreMarca.toLowerCase() === 'otra',
			);
			if (!defaultBrand) return;

			const customBrandId =
				brand.data.reduce((prev, current) => {
					if (current.marcaId > prev) return current.marcaId;
					return prev;
				}, 0) + 1;

			const customBrand = {
				...defaultBrand,
				marcaId: customBrandId,
				nombreMarca: values.name.trim(),
				isCustom: true,
			};

			if (isProfileScreen) {
				const newFavoriteBrands: BrandModel[] = JSON.parse(
					JSON.stringify(client.favoriteBrand),
				);
				newFavoriteBrands.push(customBrand);
				clientSetFavoriteBrands(dispatch, client, newFavoriteBrands);
			} else {
				addFavoriteBrand(dispatch, customBrand);
				addBrand(dispatch, customBrand);
			}
			removeModal(dispatch, id);
		},
	});

	return (
		<>
			<p className='text-center mt-4'>Dinos ¿Cuál?</p>
			<form onSubmit={form.handleSubmit}>
				<section className='form-input'>
					<section className='input-wrapper'>
						<input
							type='text'
							name='name'
							id='name'
							className='input'
							onChange={form.handleChange('name')}
							autoFocus
							value={form.values.name}
							autoComplete='off'
						/>
					</section>
					{form.errors.name && <p className='error'>{form.errors.name}</p>}
				</section>
				<button
					type='submit'
					className='btn-primary w-2/5 !py-2 mx-auto'
					disabled={form.isSubmitting}>
					Agregar
				</button>
			</form>
		</>
	);
}

function initialValues() {
	return {
		name: '',
	};
}

function validationSchema() {
	return {
		name: Yup.string().required('Ingresa un nombre').trim(),
	};
}

const mapStateToProps = (state: StateModel) => ({
	brand: state.brand,
	client: state.client,
});

export default connect(mapStateToProps)(AddModalComponent);
