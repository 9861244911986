import { Dispatch } from '@reduxjs/toolkit';

// services
import { getBrandApi } from '../../core/services/brand.service';

// models
import { BrandModel } from '../../core/models/brand.model';

// types
import type from '../types/brand';

// utils
import { parseError } from '../../utils';

/**
 * Fetches brand data and updates the Redux state with the retrieved data.
 *
 * @param dispatch - The Redux dispatch function.
 */
export function getBrand(dispatch: Dispatch) {
	dispatch({
		type: type.loading,
	});

	getBrandApi(dispatch)
		.then((brands: BrandModel[]) => {
			addBrand(dispatch, brands);
			const defaultImage =
				brands.find(brand => brand.nombreMarca.toLocaleLowerCase() === 'otra')
					?.imagen ?? '';
			dispatch({
				type: type.addDefaultImage,
				payload: defaultImage,
			});
		})
		.catch((error: string | object) => {
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}

/**
 * Adds brand data to the Redux state.
 *
 * @param dispatch - The Redux dispatch function.
 * @param brand - The brand or array of brands to be added to the state.
 */
export function addBrand(dispatch: Dispatch, brand: BrandModel | BrandModel[]) {
	const brands = Array.isArray(brand) ? brand : [brand];

	dispatch({
		type: type.setBrands,
		payload: brands,
	});
}

/**
 * Removes brand data from the Redux state.
 *
 * @param dispatch - The Redux dispatch function.
 * @param brandId - The id of the brand to be removed from the state.
 */
export function removeBrand(dispatch: Dispatch, brandId: number) {
	dispatch({
		type: type.removeBrand,
		payload: brandId,
	});
}
