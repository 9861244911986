import { Dispatch } from '@reduxjs/toolkit';
import { METHODS, request } from '../../utils/http';

// models
import { ClientModel, DirectionModel } from '../models/client.model';

const clientUri = '/customer';

export function getClientApi(
	dispatch: Dispatch,
	clienteId: ClientModel['clienteId'],
) {
	return request(dispatch, `${clientUri}/${clienteId}`, METHODS.GET);
}

export function getProfileApi(
	dispatch: Dispatch,
	cellphone: ClientModel['celular'],
) {
	return request(dispatch, `${clientUri}/profile/${cellphone}`, METHODS.GET);
}

export function getDirectionsApi(
	dispatch: Dispatch,
	clienteId: ClientModel['clienteId'],
) {
	return request(dispatch, `${clientUri}/directions/${clienteId}`);
}

export function loginApi(dispatch: Dispatch, body: Partial<ClientModel>) {
	return request(dispatch, `${clientUri}/login`, METHODS.POST, body);
}

export function logoutApi(dispatch: Dispatch, body: Partial<ClientModel>) {
	return request(dispatch, '/logout', METHODS.POST, body);
}

export function registerApi(dispatch: Dispatch, body: Partial<ClientModel>) {
	return request(dispatch, `${clientUri}/register`, METHODS.POST, body);
}

export function validateCellPhoneApi(
	dispatch: Dispatch,
	cellphone: ClientModel['celular'],
	resend = false,
) {
	const param = resend ? 'resend' : 'validate';
	return request(dispatch, `${clientUri}/${cellphone}/${param}`);
}

export function registerInfoApi(
	dispatch: Dispatch,
	cellphone: ClientModel['celular'],
) {
	return request(dispatch, `${clientUri}/register_info/${cellphone}`);
}

export function registerValidationApi(
	dispatch: Dispatch,
	cellphone: ClientModel['celular'],
	pin: ClientModel['pin'],
) {
	return request(
		dispatch,
		`${clientUri}/${cellphone}/${pin}/registerPinValidation`,
	);
}

export function updateClientApi(
	dispatch: Dispatch,
	clienteId: ClientModel['clienteId'],
	body: Partial<ClientModel>,
) {
	return request(dispatch, `${clientUri}/${clienteId}`, METHODS.PATCH, body);
}

export function changePasswordApi(
	dispatch: Dispatch,
	body: Partial<ClientModel>,
) {
	return request(dispatch, `${clientUri}/changePass`, METHODS.PATCH, body);
}

export function temporalPasswordApi(
	dispatch: Dispatch,
	celular: ClientModel['celular'],
) {
	return request(dispatch, `${clientUri}/${celular}/temporalPass`, METHODS.GET);
}

export function temporalLoginApi(
	dispatch: Dispatch,
	body: Partial<ClientModel>,
) {
	return request(dispatch, `${clientUri}/temporalLogin`, METHODS.POST, body);
}

export function resetPasswordApi(
	dispatch: Dispatch,
	body: Partial<ClientModel>,
) {
	return request(dispatch, `${clientUri}/resetPass`, METHODS.PATCH, body);
}

export function updateBrandApi(dispatch: Dispatch, body: any) {
	return request(dispatch, `${clientUri}/brand`, METHODS.PATCH, body);
}

export function updateTokenFirebaseApi(
	dispatch: Dispatch,
	body: Partial<ClientModel>,
) {
	return request(dispatch, `${clientUri}/updateToken`, METHODS.POST, body);
}

export function deleteClientApi(
	dispatch: Dispatch,
	clienteId: ClientModel['clienteId'],
) {
	return request(dispatch, `${clientUri}/${clienteId}`, METHODS.DELETE);
}

export function saveDirectionApi(
	dispatch: Dispatch,
	direction: Partial<DirectionModel>,
	clienteId: ClientModel['clienteId'],
) {
	return request(dispatch, `${clientUri}/direction`, METHODS.POST, {
		id_cliente: clienteId,
		direction,
	});
}

export function getUpdateCellphonePinApi(
	dispatch: Dispatch,
	clienteId: ClientModel['clienteId'],
	celular: ClientModel['celular'],
) {
	return request(
		dispatch,
		`${clientUri}/${clienteId}/${celular}/updateCellphonePin`,
		METHODS.GET,
	);
}

export function updateCellphoneApi(
	dispatch: Dispatch,
	body: Partial<ClientModel>,
) {
	return request(dispatch, `${clientUri}/cellphone`, METHODS.PATCH, body);
}

export function getPasswordPinApi(
	dispatch: Dispatch,
	clienteId: ClientModel['clienteId'],
	celular: ClientModel['celular'],
) {
	return request(
		dispatch,
		`${clientUri}/${clienteId}/${celular}/pinPasswordValidate`,
	);
}

export function validatePasswordPinApi(
	dispatch: Dispatch,
	clienteId: ClientModel['clienteId'],
	celular: ClientModel['celular'],
	pin: ClientModel['pin'],
) {
	return request(
		dispatch,
		`${clientUri}/${clienteId}/${celular}/${pin}/pinPasswordValidation`,
	);
}

export function savePublicKeyApi(
	dispatch: Dispatch,
	clienteId: ClientModel['clienteId'],
	publicKey: string,
) {
	return request(
		dispatch,
		`${clientUri}/${clienteId}/public_key`,
		METHODS.POST,
		{
			public_key: publicKey,
		},
	);
}

export function deletePublicKeyApi(dispatch: Dispatch, publicKeyId: number) {
	return request(
		dispatch,
		`${clientUri}/public_key/${publicKeyId}`,
		METHODS.DELETE,
	);
}

export function loginBiometricApi(
	dispatch: Dispatch,
	clienteId: ClientModel['clienteId'],
	signature: string,
	timestamp: string,
) {
	return request(dispatch, `${clientUri}/biometric/login`, METHODS.POST, {
		clienteId,
		signature,
		timestamp,
	});
}
