// models
import { PublicidadModel } from '../../core/models/ad.model';

interface AdCardProps {
	ad: PublicidadModel;
}

export default function AdCard({ ad }: AdCardProps): JSX.Element {
	if (!ad.imagen) {
		return <></>;
	}

	return (
		<section className='card h-40 items-center justify-center mb-2 !p-0 xl:h-48'>
			<img
				src={`data:image/png;base64,${ad.imagen}`}
				className='w-full h-full rounded-2xl'
			/>
		</section>
	);
}
