import dayjs from 'dayjs';

// models
import { TransactionModel } from '../../core/models/transaction.model';

interface TransactionCardProps {
	date: string;
	trasaction: TransactionModel[];
}

export default function TransactionCard({
	date,
	trasaction,
}: TransactionCardProps) {
	return (
		<section className='w-full'>
			<p className='font-bold'>{dayjs(date).format('DD MMMM')}</p>
			{trasaction.map(item =>
				item.details.map((detail, index) => (
					<section key={item.ticket + index}>
						<section className='flex items-center justify-between mt-3'>
							<section className='mr-4'>
								<p>{item.nombreTienda}</p>
								<p className='text-[10px]'></p>
							</section>
							<p
								className={`font-bold max-w-[50%] 
                ${detail.class === 'coupon' ? 'text-[#ED8205]' : detail.class === 'spending' ? 'text-[#F83900]' : 'text-[#0C7C07]'}`}>
								{detail.value}
							</p>
						</section>
						{index !== item.details.length - 1 && <hr className='my-3' />}
					</section>
				)),
			)}
		</section>
	);
}
