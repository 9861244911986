import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DispatchProp, connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Actions
import { clientLogin } from '../../../redux/actions/client';

// Models
import { StateModel } from '../../../core/models/state.model';
import { ClientModel } from '../../../core/models/client.model';

// components
import EyePassword from '../../../components/EyePassword';

// utils
import { logPageView } from '../../../utils/firebaseConfig';

interface SuccessRegisterProps {
	client: ClientModel;
}

function SuccessRegister({
	client,
	dispatch,
}: SuccessRegisterProps & DispatchProp) {
	const navigate = useNavigate();
	const [isSecureTextEntry, setIsSecureTextEntry] = useState<boolean>(true);
	const form = useFormik({
		initialValues: initialValues(),
		validationSchema: Yup.object(validationSchema()),
		validateOnChange: false,
		onSubmit: values => {
			clientLogin(dispatch, {
				celular: client.celular,
				clave: values.password,
			});
		},
	});

	useEffect(() => {
		logPageView('Success Register');
		if (!client.celular) {
			navigate('/bienvenido');
		}
	}, []);

	const toggleSecureTextEntry = () => {
		setIsSecureTextEntry(!isSecureTextEntry);
	};

	const goToForgotPassword = () => {
		navigate('/recuperar-contrasena');
	};

	return (
		<section className='flex items-center justify-center flex-col md:h-[calc(100vh-11rem)]'>
			<p className='text-xl text-primary font-bold mt-9 md:mt-0'>
				Hola {client.nombre}
			</p>
			<p className='font-bold'>¡Tu registro fue realizado con éxito!</p>
			<p className='my-5 leading-5'>
				Ahora puedes iniciar sesión y empezar a <br /> acumular puntos por tus
				compras en SIX
			</p>
			<form onSubmit={form.handleSubmit}>
				<section className='form-input'>
					<label htmlFor='password' className='form-label'>
						Ingresa tu contraseña
					</label>
					<section className='input-wrapper'>
						<input
							type={isSecureTextEntry ? 'password' : 'text'}
							id='password'
							name='password'
							className='input'
							value={form.values.password}
							onChange={form.handleChange('password')}
							autoComplete='off'
						/>
						<EyePassword
							toggleSecure={toggleSecureTextEntry}
							isSecure={isSecureTextEntry}
						/>
					</section>
					{(form.errors.password || client.error) && (
						<p className='error'>{form.errors.password || client.error}</p>
					)}
				</section>
				<button type='submit' className='btn-primary w-full mt-6'>
					Iniciar Sesión
				</button>
				<button
					type='button'
					onClick={goToForgotPassword}
					className='block mx-auto mt-8 text-center underline'>
					¿Olvidaste tu contraseña?
				</button>
			</form>
		</section>
	);
}

function initialValues() {
	return {
		password: '',
	};
}

function validationSchema() {
	return {
		password: Yup.string().required('La contraseña es requerida'),
	};
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(SuccessRegister);
