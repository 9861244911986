export default {
	setClient: 'SET_CLIENT',
	addFavoriteBrand: 'ADD_FAVORITE_BRAND',
	removeFavoriteBrand: 'REMOVE_FAVORITE_BRAND',
	setEncryptedCard: 'SET_CLIENT_ENCRYPTED_CARD',
	logout: 'LOGOUT',
	error: 'ERROR_CLIENT',
	loading: 'LOADING_CLIENT',
	reset: 'RESET_CLIENT',
};
