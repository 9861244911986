import { Link } from 'react-router-dom';

// assets
import PhoneIcon from '../assets/icons/phone.png';
import FacebookIcon from '../svgs/FacebookIcon';
import InstagramIcon from '../svgs/InstagramIcon';

export default function Footer() {
	return (
		<footer className='footer'>
			<section className='grid grid-cols-[fit-content(8rem)_fit-content(10rem)_fit-content(8rem)] items-center md:order-2 md:justify-center'>
				<p className='md:mx-4'>Línea Suma SIX </p>
				<img
					src={PhoneIcon}
					alt='Phone Icon'
					className='w-4 mx-2 md:w-5 md:order-first md:mx-0'
				/>
				<Link to='tel:1234567890' className='text-primary font-bold'>
					55 4567 8975
				</Link>
			</section>
			<Link to='/terminos' className='text-center my-1 md:order-last'>
				Términos y Condiciones
			</Link>
			<section className='flex items-center justify-center mt-2'>
				<Link
					to='https://www.facebook.com/SIXtiendas'
					target='_blank'
					className='block mr-4'>
					<FacebookIcon className='w-6 h-6 fill-primary' />
				</Link>
				<Link
					to='https://www.instagram.com/tiendassixoficial/'
					target='_blank'
					className='block'>
					<InstagramIcon className='w-6 h-6 fill-primary' />
				</Link>
			</section>
		</footer>
	);
}
