import { DispatchProp, connect } from 'react-redux';

// actions
import { removeModal } from '../../redux/actions/modals';

// components
import BrandList from './List';

interface FavoriteBrandModalProps {
	id: number;
}

function FavoriteBrandModal({
	id,
	dispatch,
}: DispatchProp & FavoriteBrandModalProps) {
	return (
		<>
			<p className='text-primary text-xl font-bold text-center leading-6'>
				Selecciona tu
				<br /> Cerveza Favorita
			</p>
			<p className='text-center mt-2 mb-5'>Puedes seleccionar hasta 5</p>
			<BrandList isModal />
			<button
				className='btn-primary w-full mt-4'
				type='button'
				onClick={() => {
					removeModal(dispatch, id);
				}}>
				Agregar
			</button>
		</>
	);
}

export default connect()(FavoriteBrandModal);
