import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

// utils
import { logPageView } from '../utils/firebaseConfig';

// assets
import LogoApp from '../assets/icons/logo.png';
import Benefit1 from '../assets/images/benefits/uno.png';
import Benefit2 from '../assets/images/benefits/dos.png';
import Benefit3 from '../assets/images/benefits/tres.png';

export default function Welcome() {
	useEffect(() => {
		logPageView('Welcome');
	}, []);

	return (
		<section className='grid grid-cols-1 mt-8 md:grid-cols-2 md:items-center'>
			<section className='md:bg-gray md:h-[calc(100vh-11rem)] md:flex md:items-center'>
				<Swiper
					spaceBetween={0}
					slidesPerView={1}
					modules={[Pagination]}
					pagination={{ clickable: true }}
					className='max-w-full !pb-10'>
					<SwiperSlide>
						<p className='text-primary font-black italic text-2xl text-center'>
							¡REGÍSTRATE!
						</p>
						<p className='text-lg font-bold text-center mt-2'>
							Y gana un cupón
							<br /> de bienvenida para canjear
							<br /> un latón en tu SIX favorito
						</p>
						<img
							src={Benefit1}
							alt='Beneficio 1'
							className='w-28 mx-auto mt-4'
						/>
					</SwiperSlide>
					<SwiperSlide>
						<p className='text-primary font-black italic text-2xl text-center'>
							¡ACUMULA 5 PUNTOS
							<br />
							<span className='text-black'>por cada peso de compra!</span>
						</p>
						<img
							src={Benefit2}
							alt='Beneficio 2'
							className='w-60 mx-auto mt-4'
						/>
						<p className='text-xs text-center font-medium mt-3'>
							<span className='text-primary font-bold'>
								Categorías Participantes:
							</span>
							Cerveza,
							<br /> Sidras, RTDs, Rehidratantes, Spirits,
							<br /> Vinos y Licores, Sueros y Artículos SIX.
						</p>
					</SwiperSlide>
					<SwiperSlide>
						<p className='font-bold italic text-2xl text-center'>
							¡Obtén cupones
							<br />
							<span className='text-primary font-black'>EXCLUSIVOS!</span>
						</p>
						<img
							src={Benefit3}
							alt='Beneficio 3'
							className='w-64 mx-auto mt-4'
						/>
					</SwiperSlide>
				</Swiper>
			</section>
			<section className='mt-6 px-3 md:order-first md:mt-0'>
				<img
					src={LogoApp}
					alt='Logo'
					className='hidden w-52 mx-auto md:block'
				/>
				<Link
					to='/registro/datos'
					className='btn-primary block mx-auto mt-4 md:w-1/2'>
					Registrarme
				</Link>
				<Link to='/login' className='btn-secondary block mx-auto mt-4 md:w-1/2'>
					Iniciar Sesión
				</Link>
			</section>
		</section>
	);
}
