// assets
import EyeIcon from '../svgs/EyeIcon';
import EyeSlashIcon from '../svgs/EyeSlashIcon';

interface EyePasswordProps {
	toggleSecure: () => void;
	isSecure: boolean;
}

export default function EyePassword({
	toggleSecure,
	isSecure,
}: EyePasswordProps) {
	return (
		<button type='button' onClick={toggleSecure}>
			{isSecure ? (
				<EyeSlashIcon className='input-icon fill-primary stroke-primary' />
			) : (
				<EyeIcon className='input-icon fill-primary stroke-primary' />
			)}
		</button>
	);
}
