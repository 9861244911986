import { Dispatch } from '@reduxjs/toolkit';

// models
import { ModalModel } from '../../core/models/modal.model';

// types
import type from '../types/modal';

/**
 * Adds a modal to the Redux state by dispatching an action.
 *
 * @param dispatch - The Redux dispatch function.
 * @param modal - The modal component to be added to the state.
 */
export function addModal(dispatch: Dispatch, modal: Partial<ModalModel>) {
	dispatch({
		type: type.addModal,
		payload: modal,
	});
}

/**
 * Removes a modal from the Redux state by dispatching an action.
 *
 * @param dispatch - The Redux dispatch function.
 * @param id - The unique identifier of the modal to be removed.
 */
export function removeModal(dispatch: Dispatch, id: ModalModel['id']) {
	dispatch({
		type: type.removeModal,
		payload: id,
	});
}
