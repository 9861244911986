import { useState, useEffect } from 'react';
import { DispatchProp, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// actions
import {
	setClient,
	clientValidateCellPhone,
} from '../../../redux/actions/client';

// models
import { ClientModel } from '../../../core/models/client.model';
import { StateModel } from '../../../core/models/state.model';

// components
import NavLeft from '../../../components/NavLeft';

// utils
import { logPageView } from '../../../utils/firebaseConfig';

interface ClientPhoneProps {
	client: ClientModel;
}

function ClientPhone({ client, dispatch }: DispatchProp & ClientPhoneProps) {
	const navigate = useNavigate();
	const [isSentValidation, setIsSentValidation] = useState(false);

	const form = useFormik({
		initialValues: initialValues(client),
		validationSchema: Yup.object(validationSchema()),
		validateOnChange: false,
		onSubmit: values => {
			setClient(dispatch, {
				celular: values.phone,
			});
			clientValidateCellPhone(dispatch, values.phone);
			setIsSentValidation(true);
		},
	});

	useEffect(() => {
		logPageView('Register Client Phone', client);
	}, []);

	useEffect(() => {
		if (isSentValidation && !client.loading && !client.error) {
			navigate('/registro/verificar-celular');
		}
	}, [client.loading, client.error]);

	return (
		<>
			<NavLeft name='Registro' route='/registro/datos' />
			<section className='px-8 md:flex md:items-center md:justify-center md:flex-col md:h-[calc(100vh-15rem)]'>
				<form onSubmit={form.handleSubmit} className='w-full'>
					<section className='form-input'>
						<label
							htmlFor='phone'
							className='form-label md:mx-auto md:block md:text-center'>
							Ingresa tu número de Celular a 10 dígitos
						</label>
						<section className='input-wrapper md:!w-96'>
							<input
								type='tel'
								id='phone'
								name='phone'
								className='input'
								value={form.values.phone}
								onChange={form.handleChange('phone')}
							/>
						</section>
						{(form.errors.phone ?? client.error) && (
							<p className='error'>{form.errors.phone ?? client.error}</p>
						)}
					</section>
					<button
						type='submit'
						className='btn-primary block mx-auto w-full mt-4 md:mt-10 md:w-1/2 lg:w-1/4'>
						Continuar
					</button>
				</form>
			</section>
		</>
	);
}

function initialValues(client: ClientModel) {
	return {
		phone: client.celular || '',
	};
}

function validationSchema() {
	return {
		phone: Yup.string()
			.required('Número de celular es requerido')
			.matches(/^[0-9]+$/, 'Solo se permiten números')
			.max(10, 'Número de celular a 10 dígitos')
			.min(10, 'Número de celular a 10 dígitos')
			.trim(),
	};
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(ClientPhone);
