import { useEffect } from 'react';
import { connect } from 'react-redux';

// models
import { StateModel } from '../../core/models/state.model';
import { ClientModel } from '../../core/models/client.model';

// components
import PointHistoryList from '../../components/Transaction/List';
import NavLeft from '../../components/NavLeft';

// utils
import { logPageView } from '../../utils/firebaseConfig';

interface PointHistoryProps {
	client: ClientModel;
}

function PointHistory({ client }: PointHistoryProps) {
	useEffect(() => {
		logPageView('Point History', client);
	}, []);

	return (
		<>
			<section className='bg-red-points h-[calc(100vh-15rem)] pt-2 xl:h-[calc(100vh-15rem)]'>
				<NavLeft name='Historial de Puntos' />
				<PointHistoryList />
			</section>
		</>
	);
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(PointHistory);
