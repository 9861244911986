// models
import { CouponCategoryModel } from '../../core/models/coupon.model';

interface CouponCardProps {
	category: CouponCategoryModel | undefined;
}

export default function CouponCard({ category }: CouponCardProps) {
	if (!category) {
		return <></>;
	}

	return (
		<section className='card flex flex-col items-center justify-center !p-8 w-80 h-[430px] mb-5 mx-auto'>
			<img
				src={category.url_icono_carrusel_app}
				className='w-16 h-16 mb-5 object-contain opacity-50'
			/>
			<p className='text-xl text-center text-gray-app font-medium'>
				Aún no tienes <br /> acceso a este cupón
			</p>
			{category.slug === 'cumpleanios' ? (
				<p className='text-xl text-center text-gray-app font-light mt-3'>
					Espera la fecha <br /> de tu cumpleaños
				</p>
			) : category.slug === 'cuatro-meses-compra' ? (
				<p className='text-primary text-xl text-center font-medium mt-3'>
					¡Sigue Acumulando!
				</p>
			) : (
				<p className='text-xl text-center text-gray-app font-light mt-3'>
					Espera a que <br /> esté disponible
				</p>
			)}
		</section>
	);
}
