// assets
import { useNavigate } from 'react-router-dom';
import arrowDownIcon from '../assets/icons/arrow_down.png';

interface NavLeftProps {
	name: string;
	route?: string;
}

export default function NavLeft({ name, route }: NavLeftProps) {
	const navigate = useNavigate();
	const onNavigate = () => {
		if (route) {
			navigate(route);
		} else {
			navigate(-1);
		}
	};

	return (
		<button
			className='flex items-center pl-4 mb-3 xl:mt-3 xl:pl-6'
			onClick={onNavigate}>
			<img
				src={arrowDownIcon}
				alt='Go back'
				className='w-4 h-4 rotate-90 mr-2'
			/>
			<p className='text-xl font-medium text-primary'>{name}</p>
		</button>
	);
}
