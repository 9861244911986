import { Dispatch } from '@reduxjs/toolkit';
import { request, METHODS } from '../../utils/http';

// models
import { ClientModel } from '../models/client.model';
import { StoreModel } from '../models/shop.model';
import { LocationModel } from '../models/map.model';

const storeUri = '/store';
const shopNearUri = '/nearshops';

export function getNearsShopsApi(dispatch: Dispatch, body: LocationModel) {
	return request(dispatch, shopNearUri, METHODS.POST, body);
}

export function getOrdersApi(
	dispatch: Dispatch,
	clientId: ClientModel['clienteId'],
) {
	return request(dispatch, `${storeUri}/orders/${clientId}`);
}

export function newOrderApi(dispatch: Dispatch, body: Partial<StoreModel>) {
	return request(dispatch, `${storeUri}/order`, METHODS.POST, body);
}
