import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DispatchProp, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Actions
import { clientLogin, clientReset } from '../../redux/actions/client';

// models
import { ClientModel } from '../../core/models/client.model';
import { StateModel } from '../../core/models/state.model';

// Components
import EyePassword from '../../components/EyePassword';

// utils
import { logPageView } from '../../utils/firebaseConfig';

interface LoginProps {
	client: ClientModel;
}

function Login({ client, dispatch }: DispatchProp & LoginProps) {
	const navigate = useNavigate();
	const [isSecureTextEntry, setIsSecureTextEntry] = useState(true);
	const form = useFormik({
		initialValues: initialValues(),
		validationSchema: Yup.object(validationSchema()),
		validateOnChange: false,
		onSubmit: values => {
			clientLogin(dispatch, values);
		},
	});

	useEffect(() => {
		logPageView('Login');
		clientReset(dispatch);
	}, []);

	const toggleSecureTextEntry = () => {
		setIsSecureTextEntry(!isSecureTextEntry);
	};

	const goToForgotPassword = () => {
		navigate('/recuperar-contrasena');
	};

	return (
		<section className='flex items-center justify-center flex-col md:h-[calc(100vh-11rem)]'>
			<p className='text-xl font-bold text-primary text-center mt-9 md:mt-0'>
				¡HOLA!
			</p>
			<p className='text-2xl text-center font-black mb-10'>Ingresa tus datos</p>
			<form onSubmit={form.handleSubmit} className='w-4/5 md:w-1/2'>
				<section className='form-input'>
					<label htmlFor='phone' className='form-label'>
						Número celular registrado
					</label>
					<section className='input-wrapper'>
						<input
							type='tel'
							name='phone'
							id='phone'
							className='input'
							onChange={form.handleChange('celular')}
							value={form.values.celular}
						/>
					</section>
					{form.errors.celular && (
						<p className='error'>{form.errors.celular}</p>
					)}
				</section>
				<section className='form-input'>
					<label htmlFor='password'>Contraseña</label>
					<section className='input-wrapper'>
						<input
							type={isSecureTextEntry ? 'password' : 'text'}
							id='password'
							name='password'
							className='input'
							value={form.values.clave}
							onChange={form.handleChange('clave')}
							autoCapitalize='none'
							autoComplete='off'
						/>
						<EyePassword
							toggleSecure={toggleSecureTextEntry}
							isSecure={isSecureTextEntry}
						/>
					</section>
					{form.errors.clave && <p className='error'>{form.errors.clave}</p>}
				</section>
				{client.error && <p className='error'>{client.error}</p>}
				<button
					type='submit'
					className='btn-primary w-full mx-auto mt-6 md:w-1/2 '>
					Iniciar Sesión
				</button>
				<button
					type='button'
					onClick={goToForgotPassword}
					className='block mx-auto mt-8 text-center underline'>
					¿Olvidaste tu contraseña?
				</button>
			</form>
		</section>
	);
}

function initialValues() {
	return {
		celular: '',
		clave: '',
	};
}

function validationSchema() {
	return {
		celular: Yup.string()
			.required('Número celular es requerido')
			.max(10, 'Número de celular a 10 dígitos')
			.min(10, 'Número de celular a 10 dígitos')
			.matches(/^[0-9]+$/, 'Sólo se aceptan números'),
		clave: Yup.string().required('Contraseña es requerida'),
	};
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(Login);
