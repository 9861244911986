import { useEffect, useRef, useState } from 'react';
import { DispatchProp, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// actions
import {
	setClient,
	clientValidateCellPhone,
	clientTemporalLogin,
	clientTemporalPassword,
	clientUpdateCellphone,
	clientGetUpdateCellphonePin,
	clientValidatePasswordPin,
	clientGetValidatePasswordPin,
	clientRegisterValidationPin,
} from '../redux/actions/client';

// models
import { StateModel } from '../core/models/state.model';
import { ClientModel } from '../core/models/client.model';

// components
import NavLeft from '../components/NavLeft';

// utils
import { logPageView } from '../utils/firebaseConfig';

interface ValidationPhoneProps {
	client: ClientModel;
}

function ValidationPhone({
	client,
	dispatch,
}: DispatchProp & ValidationPhoneProps) {
	const navigate = useNavigate();
	const codeRefs = Array.from({ length: 6 }, () => useRef(null));
	const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
	const [timeText, setTimeText] = useState('00:00');
	const [time, setTime] = useState(0);
	const [isSentPin, setIsSentPin] = useState(false);
	const phone = ` ${(
		(client.isChangePhone ? client.celularTemporal : client.celular) ?? ''
	).replace(/\d(?=\d{4})/g, '*')}`;

	let nameBack = '';
	let routeBack = '';

	const form: any = useFormik({
		initialValues: initialValues(),
		validationSchema: Yup.object(validationSchema()),
		validateOnChange: false,
		onSubmit: values => {
			const code = Object.values(values).join('');
			if (client.isResetPassword) {
				clientTemporalLogin(dispatch, {
					celular: client.celular,
					claveTemporal: code,
				});
			} else if (client.isChangePhone && client.celularTemporal) {
				clientUpdateCellphone(dispatch, {
					celular: client.celularTemporal,
					pin: code,
					clienteId: client.clienteId,
				});
			} else if (client.isChangePassword) {
				clientValidatePasswordPin(dispatch, {
					...client,
					pin: code,
				});
			} else if (client.isRegister) {
				clientRegisterValidationPin(dispatch, {
					...client,
					pin: code,
				});
			}
			setIsSentPin(true);
		},
	});

	useEffect(() => {
		logPageView('Validation Phone', client);
		timer();
		onRedirect();
		return () => {
			clearTimer();
			setIsSentPin(false);
			setClient(dispatch, {
				hasValidationPin: false,
			});
		};
	}, []);

	useEffect(() => {
		if (
			!client.loading &&
			!client.error &&
			client.hasValidationPin &&
			isSentPin
		) {
			if (
				client.isResetPassword ||
				client.isChangePassword ||
				client.isRegister
			) {
				navigate('/registro/contrasena');
			} else if (client.isChangePhone) {
				navigate('/actualizar-celular/completado');
			}
			setIsSentPin(false);
		}
		if (!client.loading && client.error && isSentPin) {
			setIsSentPin(false);
		}
		onRedirect();
	}, [client, isSentPin]);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
	const codeError = Object.keys(form.errors).reduce(
		(prevError, codeField) => prevError || form.errors[codeField],
		'',
	);

	const timer = () => {
		clearTimer();
		setTime(60);
		let seconds = 60;
		const newTimerId = setInterval(() => {
			seconds--;
			const minutes = Math.floor(seconds / 60);
			const minutesText = minutes < 10 ? `0${minutes}` : minutes;
			const secondsText = seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60;
			setTimeText(`${minutesText}:${secondsText}`);
			if (seconds === 0) {
				clearInterval(newTimerId);
				setTime(0);
			}
		}, 1000);
		setTimerId(newTimerId);
	};

	const resendCode = () => {
		setIsSentPin(false);
		if (client.isResetPassword) {
			clientTemporalPassword(dispatch, client.celular);
		} else if (client.isChangePhone && client.celularTemporal) {
			clientGetUpdateCellphonePin(dispatch, client, client.celularTemporal);
		} else if (client.isChangePassword) {
			clientGetValidatePasswordPin(dispatch, client);
		} else if (client.isRegister) {
			clientValidateCellPhone(dispatch, client.celular, true);
		}
		timer();
	};

	const clearTimer = () => {
		if (timerId) {
			clearInterval(timerId);
		}
	};

	const onRedirect = () => {
		if (!client.hasValidationPin && !client.loading && !client.error) {
			if (client.isResetPassword) {
				navigate('/login');
			} else if (client.isChangePhone || client.isChangePassword) {
				navigate('/perfil/completar-datos');
			} else if (client.isRegister) {
				navigate('/bienvenido');
			} else {
				navigate('/');
			}
			setClient(dispatch, {
				isChangePhone: false,
				isResetPassword: false,
				isChangePassword: false,
				isRegister: false,
			});
		}
	};

	const onChangeText = (value: string, index: number) => {
		if (!value && index > 0) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			codeRefs[index - 1].current?.focus();
		} else if (!!value && index < 5) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			codeRefs[index + 1].current?.focus();
		}

		if (value.length < 2) {
			form.handleChange(`code${index + 1}`)(value);
		} else if (value.length > 1 && value.length === 6) {
			let code = {};
			index = 0;
			for (let i = 0; i < value.length; i++) {
				if (index + i < value.length) {
					code = {
						...code,
						[`code${index + i + 1}`]: value[i],
					};
				}
			}
			form.setValues(code);
		}
	};

	if (client.isRegister) {
		nameBack = 'Registro';
		routeBack = '/registro/datos';
	} else if (client.isChangePhone) {
		nameBack = 'Número Celular';
		routeBack = '/perfil/completar-datos';
	} else if (client.isChangePassword) {
		nameBack = 'Contraseña';
		routeBack = '/perfil/completar-datos';
	} else if (client.isResetPassword) {
		nameBack = 'Login';
		routeBack = '/login';
	}

	return (
		<>
			{nameBack && <NavLeft name={nameBack} route={routeBack} />}
			<section className='md:flex md:items-center md:justify-center md:flex-col md:h-[calc(100vh-15rem)]'>
				{client.isChangePassword && (
					<p className='text-bold text-center mt-5 mb-8 md:text-xl'>
						Para cambiar tu contraseña,
						<br /> necesitamos verificar tu identidad
					</p>
				)}
				<p className='text-center mb-9 md:text-xl'>
					Hemos enviado un código de verificación
					<br /> de 6 dígitos al número
					{phone}
				</p>
				<form onSubmit={form.handleSubmit} className='w-full'>
					<section className='form-input flex'>
						{codeRefs.map((codeRef, index) => (
							<section
								className='input-wrapper !w-12 !h-14 !mr-3 last:!mr-0'
								key={index}>
								<input
									type='number'
									className='input !text-3xl text-center !mr-0'
									ref={codeRef}
									value={form.values[`code${index + 1}`]}
									onChange={e => {
										onChangeText(e.target.value, index);
									}}
									maxLength={1}
									minLength={1}
									autoComplete='off'
								/>
							</section>
						))}
					</section>
					{(codeError || client.error) && (
						<p className='error !mx-auto max-w-80'>
							{codeError || client.error}
						</p>
					)}
					<p className='text-center mt-8'>Código Valido durante</p>
					<p className='text-primary font-bold text-center'>{timeText}</p>
					{time === 0 && (
						<button
							className='btn-primary block mt-5 mx-auto w-4/5 md:w-1/2 lg:w-1/4'
							onClick={resendCode}>
							Reenviar código
						</button>
					)}
					<button
						type='submit'
						className='btn-primary block w-4/5 mx-auto my-5 md:w-1/2 lg:w-1/4'>
						Continuar
					</button>
				</form>
			</section>
		</>
	);
}

function initialValues() {
	return {
		code1: '',
		code2: '',
		code3: '',
		code4: '',
		code5: '',
		code6: '',
	};
}

function validationSchema() {
	const message = 'El código es inválido';
	return Object.fromEntries(
		Array.from({ length: 6 }, (_, index) => [
			`code${index + 1}`,
			Yup.string().required(message).min(1, message).max(1, message).trim(),
		]),
	);
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(ValidationPhone);
