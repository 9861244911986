import { Dispatch } from '@reduxjs/toolkit';
import { request } from '../../utils/http';

// models
import { ClientModel } from '../models/client.model';

const transactionUri = '/movement';

export function getTransactionsApi(
	dispatch: Dispatch,
	clientId: ClientModel['clienteId'],
) {
	return request(dispatch, `${transactionUri}/${clientId}`);
}
