export default {
	addNearbyStoreMap: 'ADD_NEARBY_STORE_MAP',
	addStoresMap: 'ADD_STORES_MAP',
	addPromotionMap: 'ADD_PROMOTION_MAP',
	addLocationMap: 'ADD_LOCATION_MAP',
	removeMapAction: 'REMOVE_MAP_ACTION',
	addLocation: 'ADD_NEW_LOCATION',
	addLocationPermission: 'ADD_LOCATION_PERMISSION',
	loading: 'LOADING_MAP',
	error: 'ERROR_MAP',
};
