// import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

// redux
import store from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

// components
import App from './App';

// analytics
import './utils/firebaseConfig';

const persistor = persistStore(store);

// Config dayjs locale
dayjs.locale('es');

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
	<PersistGate persistor={persistor}>
		<Provider store={store}>
			{/* <StrictMode> */}
			<App />
			{/* </StrictMode> */}
		</Provider>
	</PersistGate>,
);
