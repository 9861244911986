import { Dispatch } from '@reduxjs/toolkit';
import { METHODS, request } from '../../utils/http';

// models
import { ClientModel } from '../models/client.model';
import { LocationModel } from '../models/map.model';

const promotionUri = '/promotion';

export function getPromotionApi(dispatch: Dispatch) {
	return request(dispatch, promotionUri);
}

export function getNearsPromotionsApi(
	dispatch: Dispatch,
	body: LocationModel & { clienteId: ClientModel['clienteId'] },
) {
	return request(dispatch, `${promotionUri}/nearby`, METHODS.POST, body);
}

export function getPromotionsCarouselApi(
	dispatch: Dispatch,
	body: LocationModel & { clienteId: ClientModel['clienteId'] },
) {
	return request(dispatch, `${promotionUri}/carousel`, METHODS.POST, body);
}
