import { Dispatch } from '@reduxjs/toolkit';

// services
import { getProductApi } from '../../core/services/product.service';

// models
import { ProductModel } from '../../core/models/product.model';

// types
import type from '../types/product';

// utils
import { parseError } from '../../utils';

export function getProduct(dispatch: Dispatch) {
	dispatch({
		type: type.loading,
	});

	getProductApi(dispatch)
		.then((data: ProductModel[]) => {
			const products = data.sort((a, b) =>
				a.puntos > b.puntos ? 1 : a.puntos < b.puntos ? -1 : 0,
			);
			dispatch({
				type: type.setProduct,
				payload: products,
			});
		})
		.catch((error: string | object) => {
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}
