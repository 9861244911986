import { Dispatch } from '@reduxjs/toolkit';
import { METHODS, request } from '../../utils/http';

// models
import { ClientModel } from '../models/client.model';

const brandUri = '/brand';

export function getBrandApi(dispatch: Dispatch) {
	return request(dispatch, brandUri, METHODS.GET);
}

export function getBrandsByClientApi(
	dispatch: Dispatch,
	clientId: ClientModel['clienteId'],
) {
	return request(dispatch, `${brandUri}/${clientId}`, METHODS.GET);
}

export function addClientBrandApi(
	dispatch: Dispatch,
	clientId: ClientModel['clienteId'],
	body: {
		old: number[];
		new: string[];
	},
) {
	return request(dispatch, `${brandUri}/${clientId}`, METHODS.PATCH, {
		favoriteBrands: body,
	});
}
