export default {
	addCoupon: 'ADD_COUPON',
	addCategory: 'ADD_COUPON_CATEGORY',
	addAvailable: 'ADD_AVAILABLE_COUPON',
	addUsed: 'ADD_USED_COUPON',
	error: 'ERROR_COUPON',
	loading: 'LOADING_COUPON',
	removeCoupon: 'REMOVE_COUPON',
	addRedeem: 'ADD_REDEEM',
	removeRedeem: 'REMOVE_REDEEM',
};
