import { Dispatch } from '@reduxjs/toolkit';
import { request, METHODS } from '../../utils/http';

// models
import { ClientModel } from '../models/client.model';

const couponUri = '/coupon';

export function getCouponApi(
	dispatch: Dispatch,
	clientId: ClientModel['clienteId'],
) {
	return request(dispatch, `${couponUri}/${clientId}`);
}

export function transferenceCouponApi(dispatch: Dispatch, body: any) {
	return request(dispatch, `${couponUri}/transference`, METHODS.POST, body);
}

export function redeemCouponApi(
	dispatch: Dispatch,
	clientId: ClientModel['clienteId'],
	code: string,
) {
	return request(dispatch, `${couponUri}/redeem${clientId}/${code}`);
}
