import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DispatchProp, connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Actions
import {
	clientChangePassword,
	clientRegister,
	clientResetPassword,
	setClient,
} from '../../redux/actions/client';

// Models
import { ClientModel } from '../../core/models/client.model';
import { StateModel } from '../../core/models/state.model';

// components
import EyePassword from '../../components/EyePassword';
import NavLeft from '../../components/NavLeft';

// utils
import { logPageView } from '../../utils/firebaseConfig';

// assets
import CheckIcon from '../../assets/icons/check.png';

interface ClientPasswordProps {
	client: ClientModel;
}

function ClientPassword({
	client,
	dispatch,
}: ClientPasswordProps & DispatchProp) {
	const navigate = useNavigate();
	const [isSecureTextEntry, setIsSecureTextEntry] = useState(true);
	const [isSecureTextEntryConfirm, setIsSecureTextEntryConfirm] =
		useState(true);
	const [isSentSubmit, setIsSentSubmit] = useState(false);
	const title =
		client.isResetPassword || client.isChangePassword
			? 'Ingresa tu Nueva Contraseña'
			: 'Crea tu contraseña de acceso';
	let nameBack = '';
	let routeBack = '';

	const form = useFormik({
		initialValues: initialValues(),
		validationSchema: Yup.object(validationSchema()),
		onSubmit: values => {
			if (client.isResetPassword) {
				clientResetPassword(dispatch, {
					clienteId: client.clienteId,
					claveNueva: values.password,
				});
			} else if (client.isChangePassword) {
				clientChangePassword(dispatch, {
					...client,
					claveNueva: values.password,
					claveConfirmacion: values.confirmPassword,
				});
			} else if (client.isRegister) {
				const clientData = {
					nombre: client.nombre,
					apellidos: client.apellidos,
					celular: client.celular,
					correo: '',
					cumpleanos: client.cumpleanos?.slice(0, 10),
					clave: values.password,
					codigoPostal: '',
					esEmpleado: client.esEmpleado ?? {},
					referidoId: client.referidoId ?? '',
					sixMovilRolSocio: '0',
					favoriteBrands: {
						new: client.favoriteBrand
							.filter(item => item.isCustom)
							.map(item => item.nombreMarca),
						old: client.favoriteBrand
							.filter(item => !item.isCustom)
							.map(item => item.marcaId),
					},
				};
				clientRegister(dispatch, clientData);
			}
			setIsSentSubmit(true);
		},
	});

	useEffect(() => {
		logPageView('Client Password', client);
		if (!client.canAddPassword) {
			navigate('/');
		}
		return () => {
			setClient(dispatch, {
				canAddPassword: false,
			});
		};
	}, []);

	useEffect(() => {
		if (isSentSubmit && !client.loading && !client.error) {
			if (client.isResetPassword || client.isChangePassword) {
				navigate('/recuperar-contrasena/completado');
			} else if (client.isRegister) {
				navigate('/registro/completado');
			}
			setIsSentSubmit(false);
		}
	}, [client.loading, client.error, client.canAddPassword, isSentSubmit]);

	const toggleSecureTextEntry = () => {
		setIsSecureTextEntry(!isSecureTextEntry);
	};

	const toggleSecureTextEntryConfirm = () => {
		setIsSecureTextEntryConfirm(!isSecureTextEntryConfirm);
	};

	if (client.isRegister) {
		nameBack = 'Registro';
		routeBack = '/registro/datos';
	} else if (client.isChangePassword) {
		nameBack = 'Contraseña';
		routeBack = '/perfil/completar-datos';
	} else if (client.isResetPassword) {
		nameBack = 'Login';
		routeBack = '/login';
	}

	return (
		<>
			{nameBack && <NavLeft name={nameBack} route={routeBack} />}
			<section className='flex items-center justify-center flex-col md:h-[calc(100vh-15rem)]'>
				<p
					className={`text-center mb-6 ${client.isResetPassword || client.isChangePassword ? 'font-bold' : ''}`}>
					{title}
				</p>
				<form onSubmit={form.handleSubmit}>
					<section className='form-input'>
						<label htmlFor='password'>Contraseña</label>
						<section className='input-wrapper'>
							<input
								type={isSecureTextEntry ? 'password' : 'text'}
								id='password'
								name='password'
								className='input'
								value={form.values.password}
								onChange={form.handleChange('password')}
								autoCapitalize='none'
								autoComplete='off'
							/>
							<EyePassword
								toggleSecure={toggleSecureTextEntry}
								isSecure={isSecureTextEntry}
							/>
						</section>
						<p className='text-primary font-bold my-1'>Usa al menos:</p>
						<section className='flex items-center my-1'>
							{form.values.password.length >= 8 && (
								<img src={CheckIcon} className='w-5 h-5' />
							)}
							<p className='ml-2'>8 caracteres</p>
						</section>
						<section className='flex items-center my-1'>
							{form.values.password.match(/^(?=.*[A-Z])/) && (
								<img src={CheckIcon} className='w-5 h-5' />
							)}
							<p className='ml-2'>1 letra mayúscula</p>
						</section>
						<section className='flex items-center my-1'>
							{form.values.password.match(/^(?=.*[0-9])/) && (
								<img src={CheckIcon} className='w-5 h-5' />
							)}
							<p className='ml-2'>1 número</p>
						</section>
					</section>
					<section className='form-input'>
						<label htmlFor='confirmPassword'>Confirmar Contraseña</label>
						<section className='input-wrapper'>
							<input
								type={isSecureTextEntryConfirm ? 'password' : 'text'}
								id='confirmPassword'
								name='confirmPassword'
								className='input'
								value={form.values.confirmPassword}
								onChange={form.handleChange('confirmPassword')}
								autoCapitalize='none'
								autoComplete='off'
							/>
							<EyePassword
								toggleSecure={toggleSecureTextEntryConfirm}
								isSecure={isSecureTextEntryConfirm}
							/>
						</section>
						{((form.errors.confirmPassword && form.touched.confirmPassword) ||
							client.error) && (
							<p className='error'>
								{form.errors.confirmPassword || client.error}
							</p>
						)}
					</section>
					<button type='submit' className='btn-primary w-full mx-auto mt-8'>
						Continuar
					</button>
				</form>
			</section>
		</>
	);
}

function initialValues() {
	return {
		password: '',
		confirmPassword: '',
	};
}

function validationSchema() {
	return {
		password: Yup.string()
			.required('Ingresa tu contraseña')
			.min(8, 'Ingresa al menos 8 caracteres')
			.matches(/^(?=.*[A-Z])/, 'Ingresa al menos una letra mayúscula')
			.matches(/^(?=.*[0-9])/, 'Ingresa al menos un número'),
		confirmPassword: Yup.string()
			.required('Confirma tu contraseña')
			.oneOf([Yup.ref('password')], 'Las contraseñas no coinciden'),
	};
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(ClientPassword);
