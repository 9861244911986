import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// router
import Routes from './router/Routes';

// models
import { StateModel } from './core/models/state.model';

// layouts
import Layout from './layouts/Layout';

// components
import Modals from './components/Modals';
import Toasts from './components/Toast/Toasts';

// styles
import './styles/App.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

interface AppProps {
	isEmployee: boolean;
}

function App({ isEmployee }: AppProps) {
	const rootElement = document.getElementById('root');
	if (rootElement) {
		if (isEmployee) {
			rootElement.classList.add('employee');
		} else {
			rootElement.classList.remove('employee');
		}
	}

	return (
		<BrowserRouter>
			<Layout>
				<>
					<Routes />
					<Modals />
					<Toasts />
				</>
			</Layout>
		</BrowserRouter>
	);
}

const mapStateToProps = (state: StateModel) => ({
	isEmployee: !!(state.client.numeroSocio && state.client.empresa),
});

export default connect(mapStateToProps)(App);
