// models
import { BrandModel } from '../../core/models/brand.model';
import { ClientModel } from '../../core/models/client.model';
import { ActionModel } from '../../core/models/redux.model';
import { updateBrands } from '../../utils';

// types
import type from '../types/client';

const INITIAL_STATE = {
	error: '',
	loading: false,
	tokenFirebase: '',
	directions: [],
	favoriteBrand: [],
	canShowBiometricsModal: false,
	isGetCard: false,
};

export default function (
	client: Partial<ClientModel> = INITIAL_STATE,
	action: ActionModel,
) {
	switch (action.type) {
		case type.setClient: {
			return { ...client, ...action.payload, loading: false, error: '' };
		}
		case type.addFavoriteBrand: {
			const unRepeated: BrandModel[] = action.payload;
			return {
				...client,
				favoriteBrand: updateBrands(client.favoriteBrand ?? [], unRepeated),
			};
		}
		case type.removeFavoriteBrand: {
			return {
				...client,
				favoriteBrand: client.favoriteBrand?.filter(
					brand => action.payload !== brand.marcaId,
				),
			};
		}
		case type.reset: {
			return INITIAL_STATE;
		}
		case type.loading: {
			return { ...client, loading: true, error: '' };
		}
		case type.error: {
			return { ...client, loading: false, error: action.payload };
		}
		case type.setEncryptedCard: {
			return { ...client, encryptedCard: action.payload };
		}
		case type.logout: {
			return INITIAL_STATE;
		}
		default:
			return client;
	}
}
