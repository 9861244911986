import { DispatchProp, connect } from 'react-redux';

// actions
import { removeModal } from '../redux/actions/modals';

// models
import { ModalModel } from '../core/models/modal.model';

interface ModalProps {
	modal: ModalModel;
}

function Modal({ modal, dispatch }: ModalProps & DispatchProp) {
	const closeModal = () => {
		removeModal(dispatch, modal.id);
	};

	return (
		<section
			className='relative z-30'
			aria-labelledby='modal-title'
			role='dialog'
			aria-modal='true'>
			<section className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'></section>

			<section className='fixed inset-0 z-30 w-screen overflow-y-auto'>
				<section className='flex min-h-full items-center justify-center p-4 text-center sm:p-0'>
					<section className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
						<section className='absolute right-0 top-0 pr-4 pt-4 sm:block'>
							<button
								type='button'
								onClick={closeModal}
								className='rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2'>
								<span className='sr-only'>Close</span>
								<svg
									className='h-6 w-6 text-primary'
									fill='none'
									viewBox='0 0 24 24'
									strokeWidth='1.5'
									stroke='currentColor'
									aria-hidden='true'>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										d='M6 18L18 6M6 6l12 12'
									/>
								</svg>
							</button>
						</section>
						<section className='mt-6 text-center'>
							<modal.component {...modal} />
						</section>
					</section>
				</section>
			</section>
		</section>
	);
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(Modal);
