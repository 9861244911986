import { useEffect } from 'react';
import { connect } from 'react-redux';

// models
import { ClientModel } from '../core/models/client.model';
import { StateModel } from '../core/models/state.model';

// utils
import { logPageView } from '../utils/firebaseConfig';

// assets
import { Link } from 'react-router-dom';
import LogoApp from '../assets/icons/logo.png';

interface NotFoundProps {
	client: ClientModel;
	isAuth: boolean;
}

function NotFound({ client, isAuth }: NotFoundProps) {
	useEffect(() => {
		logPageView('Not Found', client);
	}, []);

	return (
		<section>
			<Link to={isAuth ? '/' : '/bienvenido'}>
				<img
					src={LogoApp}
					alt='Logo App'
					className='w-28 mx-auto mt-10 animate-pulse'
				/>
			</Link>
			<p className='text-center text-primary font-bold text-3xl italic'>404</p>
			<h1 className='text-primary font-bold italic text-2xl text-center'>
				Página no encontrada
			</h1>
			<Link
				to={isAuth ? '/' : '/bienvenido'}
				className='block text-center text-primary font-bold italic mt-5 underline'>
				Regresar al inicio
			</Link>
		</section>
	);
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
	isAuth: !!(
		state.client.token &&
		state.client.clienteId &&
		!state.client.claveTemporal
	),
});

export default connect(mapStateToProps)(NotFound);
