import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect, DispatchProp } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// services
import { getEnterpriseApi } from '../../../core/services/enterprise.service';

// actions
import { setClient } from '../../../redux/actions/client';

// models
import { EnterpriseModel } from '../../../core/models/enterprise.model';

// components
import NavLeft from '../../../components/NavLeft';

// utils
import { logPageView } from '../../../utils/firebaseConfig';

function CompanyClient({ dispatch }: DispatchProp) {
	const [hasBenefits, setHasBenefits] = useState(false);
	const [enterprises, setEnterprises] = useState<EnterpriseModel[]>([]);
	const navigate = useNavigate();

	const form = useFormik({
		initialValues: initialValues(),
		validationSchema: Yup.object(validationSchema()),
		validateOnChange: false,
		onSubmit: values => {
			setClient(dispatch, {
				esEmpleado: {
					numeroSocio: parseInt(values.numeroSocio),
					empresa: values.empresa,
				},
			});
			gotoClientPhone();
		},
	});

	useEffect(() => {
		logPageView('Register Company');
		getEnterpriseApi(dispatch)
			.then((data: EnterpriseModel[]) => {
				setEnterprises(data);
			})
			.catch(e => {
				console.error('Error getting enterprises', e);
			});
	}, []);

	const gotoClientPhone = () => {
		navigate('/registro/celular');
	};

	return (
		<>
			<NavLeft name='Registro' route='/registro/datos' />
			<section className='px-8 md:flex md:items-center md:justify-center md:flex-col md:h-[calc(100vh-15em)]'>
				<p className='mx-auto mt-3 md:text-xl md:text-center md:text-gray-app'>
					¿Cuentas con beneficios Suma SIX por parte
					<br className='md:hidden' /> de tu
					<span className='font-bold'> empresa</span>?
				</p>
				<section className='flex md:my-10'>
					<button
						className={`${hasBenefits ? 'btn-primary' : 'btn-secondary'} w-28 mt-4 mr-2 !py-1`}
						onClick={() => {
							setHasBenefits(true);
						}}>
						Sí
					</button>
					<button
						className={`${!hasBenefits ? 'btn-primary' : 'btn-secondary'} w-28 ml-2 mt-4 !py-1`}
						onClick={() => {
							setHasBenefits(false);
						}}>
						No
					</button>
				</section>
				{hasBenefits && (
					<form
						onSubmit={form.handleSubmit}
						className='mt-4 md:flex md:items-center'>
						<section className='form-input !pl-0 !ml-0'>
							<label className='form-label'>Número de Socio</label>
							<section className='input-wrapper'>
								<input
									type='number'
									id='numeroSocio'
									className='input text-center'
									value={form.values.numeroSocio}
									onChange={form.handleChange('numeroSocio')}
								/>
							</section>
							{form.errors.numeroSocio && (
								<p className='error'>{form.errors.numeroSocio}</p>
							)}
						</section>
						<section className='form-input !pl-0 !ml-0 md:!ml-5'>
							<label className='form-label'>Empresa</label>
							<section className='input-wrapper'>
								<select
									id='company'
									name='company'
									className='w-full text-center bg-white focus-visible:outline-none'
									defaultValue=''
									onChange={e => {
										form.setFieldValue('empresa', e.target.value);
									}}>
									<option>- Seleccionar una Empresa -</option>
									{enterprises.map((item, index) => (
										<option key={index} value={item.empresaId}>
											{item.empresa}
										</option>
									))}
								</select>
							</section>
							{form.errors.empresa && (
								<p className='error'>{form.errors.empresa}</p>
							)}
						</section>
					</form>
				)}
				<button
					className='btn-primary block mt-8 w-full mx-auto md:w-1/2 lg:w-1/4'
					onClick={() => {
						if (hasBenefits) {
							form.handleSubmit();
						} else {
							gotoClientPhone();
						}
					}}>
					Continuar
				</button>
			</section>
		</>
	);
}

function initialValues() {
	return {
		numeroSocio: '',
		empresa: '',
	};
}

function validationSchema() {
	return {
		numeroSocio: Yup.string()
			.required('Número de socio requerido')
			.matches(/^[0-9]+$/, 'Solo se permiten números')
			.trim(),
		empresa: Yup.string().required('Empresa requerida'),
	};
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(CompanyClient);
