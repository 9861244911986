import { connect, DispatchProp } from 'react-redux';

// actions
import { clientDeleteAccount } from '../../redux/actions/client';

// models
import { StateModel } from '../../core/models/state.model';
import { ModalModel } from '../../core/models/modal.model';
import { ClientModel } from '../../core/models/client.model';

interface AddModalProps {
	id: ModalModel['id'];
	client: ClientModel;
}

function DeleteAccountModal({
	id,
	client,
	dispatch,
}: AddModalProps & DispatchProp) {
	const deleteAccount = () => {
		clientDeleteAccount(dispatch, client, id);
	};

	return (
		<>
			<p className='text-lg font-bold text-center mt-5'>
				¿Estas seguro de querer
			</p>
			<p className='text-lg font-bold text-center mb-5 text-red-app uppercase'>
				eliminar tu cuenta?
			</p>
			{!client.loading && client.error && (
				<p className='error mt-2 mb-5'>{client.error}</p>
			)}
			<button
				className='btn-primary w-1/2 py-4 px-5 mx-auto'
				onClick={deleteAccount}>
				Aceptar
			</button>
		</>
	);
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(DeleteAccountModal);
