import { useEffect, useState } from 'react';
import { DispatchProp, connect } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';

// actions
import { getProduct } from '../../redux/actions/product';

// models
import { StateModel } from '../../core/models/state.model';
import {
	ProductModel,
	ProductStateModel,
} from '../../core/models/product.model';
import { ClientModel } from '../../core/models/client.model';

// components
import ProductCard from '../../components/Product/Card';

// utils
import { logPageView } from '../../utils/firebaseConfig';

interface ProductProps {
	product: ProductStateModel;
	client: ClientModel;
}

interface ProductsByPoints extends Record<string, ProductModel[]> {}

function Product({ product, client, dispatch }: ProductProps & DispatchProp) {
	const [products, setProducts] = useState<ProductsByPoints>({});

	useEffect(() => {
		logPageView('Product', client);
	}, []);

	useEffect(() => {
		if (!product.data.length) {
			getProduct(dispatch);
		} else {
			const productsByPoints = product.data.reduce(
				(acc: ProductsByPoints, item) => {
					const points = item.puntos.toString();
					if (!acc[points]) {
						acc[points] = [];
					}
					acc[points].push(item);
					return acc;
				},
				{},
			);
			setProducts(productsByPoints);
		}
	}, [product.data]);

	return (
		<>
			{Object.keys(products).map((points, index) => (
				<section
					key={index + points}
					className='mt-5 mb-5 pl-6 pr-2 mx-auto md:w-11/12 lg:w-4/5 xl:w-5/12'>
					<p
						className={`font-bold text-gray-app ${client.saldo >= parseInt(points) ? 'text-primary' : ''}`}>
						{parseInt(points).toLocaleString()} puntos
					</p>
					<Swiper
						spaceBetween={10}
						slidesPerView={1.9}
						className={client.saldo >= parseInt(points) ? '' : 'opacity-30'}
						breakpoints={{
							768: {
								spaceBetween: 0,
								slidesPerView: 3,
							},
						}}>
						{products[points].map(product => (
							<SwiperSlide key={product.productoId} className='py-3'>
								<ProductCard product={product} isCard />
							</SwiperSlide>
						))}
					</Swiper>
				</section>
			))}
		</>
	);
}

const mapStateToProps = (state: StateModel) => ({
	product: state.product,
	client: state.client,
});

export default connect(mapStateToProps)(Product);
