import dayjs from 'dayjs';
import { Dispatch } from '@reduxjs/toolkit';
import { REQUEST_ERROR_MESSAGE } from './http';

// actions
import { addLocation, addLocationPermission } from '../redux/actions/map';

// models
import { BrandModel } from '../core/models/brand.model';
import { LocationModel } from '../core/models/map.model';

// Lista de meses en español
export const MONTHS = [
	{
		value: '01',
		label: 'Enero',
	},
	{
		value: '02',
		label: 'Febrero',
	},
	{
		value: '03',
		label: 'Marzo',
	},
	{
		value: '04',
		label: 'Abril',
	},
	{
		value: '05',
		label: 'Mayo',
	},
	{
		value: '06',
		label: 'Junio',
	},
	{
		value: '07',
		label: 'Julio',
	},
	{
		value: '08',
		label: 'Agosto',
	},
	{
		value: '09',
		label: 'Septiembre',
	},
	{
		value: '10',
		label: 'Octubre',
	},
	{
		value: '11',
		label: 'Noviembre',
	},
	{
		value: '12',
		label: 'Diciembre',
	},
];

/**
 * Replaces all spaces in a given string with newline characters.
 *
 * @param text - The input string in which spaces will be replaced.
 * @returns A new string with spaces replaced by newline characters.
 */
export function replaceSpacesWithNewLine(text: string): string {
	return text.replace(/\s/g, '<br />');
}

/**
 * Parses an error and returns a user-friendly error message.
 *
 * @param {string | object} error - The error to be parsed.
 * @returns {string} A user-friendly error message.
 */
export function parseError(error: string | object): string {
	return typeof error === 'object' ? REQUEST_ERROR_MESSAGE : error;
}

/**
 * Encodes a credit card number as a token with an expiration time.
 *
 * @param {string} card - The credit card number to be encoded.
 * @param {number} minutesAlive - The duration, in minutes, for which the token will be valid. Default is 10 minutes.
 * @returns {string} - The encoded token representing the credit card.
 */
export function encodeCardAsToken(
	card: string,
	minutesAlive: number = 10,
): string {
	const nowStr = `${dayjs().add(minutesAlive, 'minute').unix()}`;
	let cardNumbers = card;
	let encryptedCard = '';
	const seedCard = Math.floor(Math.random() * 10);
	const seedTime = Math.floor(Math.random() * 10);
	if (['V', 'C'].includes(card[0])) {
		cardNumbers = cardNumbers.substring(1);
	}
	let i = 0;
	for (; i < 10; i++) {
		encryptedCard += `${(parseInt(nowStr[i]) + seedTime) % 10}`;
		encryptedCard += `${(parseInt(cardNumbers[i]) + seedCard) % 10}`;
	}

	encryptedCard += `${(parseInt(cardNumbers[i]) + seedCard) % 10}`;
	encryptedCard = `C${encryptedCard}${seedTime}${seedCard}`;
	return encryptedCard;
}

/**
 * Updates an array of brands with new data while preserving unique items.
 *
 * @param brands - An array of brands to be updated.
 * @param unRepeated - An array of new brand data with potentially overlapping items.
 * @returns A new array of brands updated with the latest data.
 */
export function updateBrands(
	brands: BrandModel[],
	unRepeated: BrandModel[],
): BrandModel[] {
	return brands
		.map(item => {
			const index = unRepeated.findIndex(
				updateItem => updateItem.marcaId === item.marcaId,
			);
			let res = item;
			if (index !== -1) {
				res = {
					...item,
					...unRepeated[index],
				};
				unRepeated.splice(index, 1);
			}
			return res;
		})
		.concat(unRepeated);
}

/**
 * Returns a new array with the unique items from the input array.
 *
 * @param array - The array from which unique items will be extracted.
 * @returns A new array with unique items from the input array.
 */
export function getStorageItem(
	name: string,
	otherwise = {},
	session?: boolean,
) {
	const storage = session ? sessionStorage : localStorage;

	return Object.prototype.hasOwnProperty.call(storage, name)
		? JSON.parse(storage[name] as string)
		: otherwise;
}

/**
 * Sets a new item in the storage.
 *
 * @param name - The name of the item to be stored.
 * @param item - The item to be stored.
 * @param session - A flag indicating whether the item should be stored in the session storage.
 * @returns The item stored in the storage.
 */
export function setStorageItem(name: string, item: any, session?: boolean) {
	try {
		(session ? sessionStorage : localStorage).setItem(
			name,
			JSON.stringify(item),
		);
	} catch (err) {
		console.error('Err set item', name, err);
	}
	return item;
}

/**
 * Gets the current location of the device.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {LocationModel} currentLocation - The current location of the device.
 */
export function getCurrentLocation(
	dispatch: Dispatch,
	currentLocation: LocationModel,
) {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(
			(position: GeolocationPosition) => {
				const { latitude, longitude } = position.coords;
				if (
					latitude !== currentLocation.latitud ||
					longitude !== currentLocation.longitud
				) {
					addLocationPermission(dispatch, 'granted');
					addLocation(dispatch, { latitud: latitude, longitud: longitude });
				}
			},
			() => {
				addLocationPermission(dispatch, 'denied');
			},
		);
	}
}
