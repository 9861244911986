import { Dispatch } from '@reduxjs/toolkit';
import { request, METHODS } from '../../utils/http';

// models
import { LocationModel } from '../models/map.model';

const adUri = '/ad';

export function getAdsApi(dispatch: Dispatch, body: LocationModel) {
	return request(dispatch, adUri, METHODS.POST, body);
}
