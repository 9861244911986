import { Dispatch } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

// Config
import { API_URI } from './config';

// actions
import { clientLogout, setClient } from '../redux/actions/client';

// Models
import { initModel } from '../core/models/http.model';

/**
 * HTTP Request Methods.
 *
 * Represents a collection of commonly used HTTP request methods as constants.
 * These constants can be used when configuring and making HTTP requests.
 */
export const METHODS = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	PATCH: 'PATCH',
	DELETE: 'DELETE',
};

export const REQUEST_ERROR_MESSAGE =
	'Error inesperado, vuelve a intentarlo más tarde.';

/**
 * Sends an HTTP request to a specified URL.
 *
 * @param {DispatchModel} dispatch - The dispatch function from the Redux store.
 * @param {string} url - The URL to send the request to.
 * @param {string} method - The HTTP method for the request (default is 'GET').
 * @param {object|FormData} body - The request body data (optional).
 * @returns {AbortableRequest} - An AbortableRequest object to manage the request and its abort functionality.
 */
export function request(
	dispatch: Dispatch,
	url: string,
	method = METHODS.GET,
	body?: any,
): AbortableRequest {
	const controller = new AbortController();
	const init: initModel = {
		method,
		headers: new Headers(),
	};

	const token = Cookies.get('token');
	if (token) {
		init.headers.append('Authorization', `Bearer ${token}`);
	}

	if (body !== undefined) {
		if (!(body instanceof FormData)) {
			body = JSON.stringify(body);
			init.headers.append('Content-Type', 'application/json');
		}
		init.body = body;
	}
	const promise = fetch(`${API_URI}${url}`, init)
		.then(response => {
			return response.json();
		})
		.then(parse => {
			return new Promise<any>((resolve, reject) => {
				if (parse.updateToke) {
					Cookies.set('token', parse.updateToke as string);
					setClient(dispatch, {
						token: parse.updateToke,
					});
				}
				if (parse.error) {
					let error =
						(typeof parse.data === 'string' && parse.data) ||
						parse.error ||
						REQUEST_ERROR_MESSAGE;
					switch (parse.statusCode) {
						case 401:
							if (token) {
								clientLogout(dispatch);
							}
							Cookies.remove('token');
							break;
						case 500:
							error = REQUEST_ERROR_MESSAGE;
							break;
						default:
							break;
					}
					reject(error);
				} else {
					resolve(parse.data);
				}
			});
		})
		.catch(e => {
			console.error('Error: ', e);
			return Promise.reject(typeof e === 'string' ? e : REQUEST_ERROR_MESSAGE);
		});
	return new AbortableRequest(promise, controller);
}

/**
 * Represents an HTTP request that can be aborted.
 */
export class AbortableRequest {
	request: Promise<any>;
	controller: AbortController;

	/**
	 * Create a new instance of the AbortableRequest class.
	 *
	 * @param {Promise} request - The HTTP request wrapped as a Promise.
	 * @param {AbortController} controller - The AbortController associated with the request.
	 */
	constructor(request: Promise<any>, controller: AbortController) {
		this.request = request;
		this.controller = controller;
	}

	/**
	 * Attach a callback function to be executed when the request is successfully resolved.
	 *
	 * @param {function} callback - The callback function to execute on successful resolution.
	 * @returns {Promise} - A Promise that resolves with the result of the callback.
	 */
	then(callback: any): Promise<any> {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		return this.request.then(callback);
	}

	/**
	 * Attach a callback function to be executed when an error occurs during the request.
	 *
	 * @param {function} callback - The callback function to execute on error.
	 * @returns {Promise} - A Promise that resolves with the result of the callback.
	 */
	catch(callback: any): Promise<any> {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		return this.request.catch(callback);
	}

	/**
	 * Abort the associated HTTP request if it is still in progress.
	 */
	abort(): void {
		if (this.controller) {
			this.controller.abort();
		}
	}
}
