import { useEffect } from 'react';
import { connect, DispatchProp } from 'react-redux';

// actions
import { getBrand, addBrand } from '../../redux/actions/brand';

// models
import { StateModel } from '../../core/models/state.model';
import { BrandModel } from '../../core/models/brand.model';
import { ClientModel } from '../../core/models/client.model';

// components
import BrandCard from './Card';

interface BrandListProps {
	favoriteBrand: BrandModel[];
	brands: BrandModel[];
	isModal?: boolean;
	client: ClientModel;
}

function BrandList({
	client,
	brands,
	favoriteBrand,
	isModal,
	dispatch,
}: BrandListProps & DispatchProp) {
	useEffect(() => {
		if (!brands.length) {
			getBrand(dispatch);
		}
		const newFavoriteBrand = favoriteBrand.filter(
			favoriteBrand =>
				!brands.some(brand => brand.marcaId === favoriteBrand.marcaId),
		);
		if (newFavoriteBrand.length) {
			addBrand(dispatch, newFavoriteBrand);
		}
	}, []);

	return (
		<section className='brand-list grid grid-cols-3 gap-4 mx-1 py-2 px-4 overflow-auto max-h-[58vh]'>
			{brands.map(item => (
				<BrandCard
					key={item.marcaId}
					brand={item}
					isSelected={favoriteBrand.some(
						clientItem => clientItem.marcaId === item.marcaId,
					)}
					isModal={isModal}
				/>
			))}
		</section>
	);
}

const mapStateToProps = (state: StateModel) => {
	const newState: StateModel = JSON.parse(JSON.stringify(state));
	const { brand, client } = newState;
	const { favoriteBrand } = client;
	const newBrands = brand.data;

	const defaultBrandIndex = newBrands.findIndex(
		item => item.nombreMarca.toLowerCase() === 'otra',
	);
	if (defaultBrandIndex !== -1) {
		const defaultBrand = newBrands[defaultBrandIndex];
		newBrands.splice(defaultBrandIndex, 1);
		if (favoriteBrand.length < 5) {
			newBrands.push(defaultBrand);
		}
	}
	return {
		brands: newBrands,
		favoriteBrand: state.client.favoriteBrand,
		client: state.client,
	};
};

export default connect(mapStateToProps)(BrandList);
