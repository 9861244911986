import { useEffect, useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// services
import { getEnterpriseApi } from '../../core/services/enterprise.service';

// actions
import {
	clientGetValidatePasswordPin,
	clientUpdate,
	setClient,
} from '../../redux/actions/client';
import { addToast } from '../../redux/actions/toast';

// models
import { ClientModel } from '../../core/models/client.model';
import { StateModel } from '../../core/models/state.model';
import { EnterpriseModel } from '../../core/models/enterprise.model';

// components
import NavLeft from '../../components/NavLeft';

// utils
import { logPageView } from '../../utils/firebaseConfig';

interface ClientDataProps extends DispatchProp {
	client: ClientModel;
}

function ClientData({ client, dispatch }: ClientDataProps) {
	const [isSentUpdateClient, setIsSentUpdateClient] = useState(false);
	const [isSentChangePassword, setIsSentChangePassword] = useState(false);
	const [isEmployee, setIsEmployee] = useState(
		!!(client.empresa && client.numeroSocio),
	);
	const [enterprises, setEnterprises] = useState<EnterpriseModel[]>([]);
	const navigate = useNavigate();

	const form = useFormik({
		initialValues: initialValues(client),
		validationSchema: Yup.object(validationSchema(isEmployee)),
		validateOnChange: false,
		onSubmit: values => {
			if (
				values.name !== client.nombre ||
				values.lastName !== client.apellidos ||
				values.email !== client.correo ||
				values.empresa !== client.empresa ||
				values.numeroSocio !== client.numeroSocio
			) {
				// TODO: Verificar endpoint de actualización de datos cuando el cliente is empleado, o quiere cambiar su empresa, o dejar de ser empleado
				clientUpdate(dispatch, {
					...client,
					nombre: values.name,
					apellidos: values.lastName,
					correo: values.email,
					empresa: values.empresa,
					numeroSocio: values.numeroSocio,
				});
				setIsSentUpdateClient(true);
			}
		},
	});

	useEffect(() => {
		logPageView('Profile Settings', client);
		setClient(dispatch, {});
		getEnterpriseApi(dispatch)
			.then((data: EnterpriseModel[]) => {
				setEnterprises(data);
			})
			.catch((error: any) => {
				// TODO: handle error in the future
				console.log(error);
			});

		return () => {
			setIsSentUpdateClient(false);
			setIsSentChangePassword(false);
		};
	}, []);

	useEffect(() => {
		if (form && parsePhone(client.celular) !== form.values.phone) {
			form.setValues(initialValues(client));
		}
		if (isSentUpdateClient && !client.loading) {
			setIsSentUpdateClient(false);
			const message = client.error
				? client.error
				: 'Datos actualizados correctamente';
			addToast(dispatch, {
				title: message,
				type: client.error ? 'error' : 'success',
			});
		}
		if (
			isSentChangePassword &&
			!client.loading &&
			!client.error &&
			client.isChangePassword &&
			client.hasValidationPin
		) {
			setIsSentChangePassword(false);
			navigate('/perfil/verificar-celular');
		} else if (isSentChangePassword && !client.loading && client.error) {
			setIsSentChangePassword(false);
			const message = 'Ocurrió un error al cambiar la contraseña';
			addToast(dispatch, {
				title: message,
				type: 'error',
			});
		}
	}, [client, isSentUpdateClient, isSentChangePassword]);

	const onChangePassword = () => {
		setIsSentChangePassword(true);
		clientGetValidatePasswordPin(dispatch, client);
	};

	const handleEmployee = () => {
		if (isEmployee) {
			form.setFieldValue('numeroSocio', '');
			form.setFieldValue('empresa', '');
		}
		setIsEmployee(!isEmployee);
	};

	return (
		<section>
			<NavLeft name='Datos de Perfil' route='/perfil' />
			<form
				onSubmit={form.handleSubmit}
				className='w-fit mx-auto max-w-full mt-10 md:grid md:grid-cols-2 md:gap-4 md:w-11/12 lg:w-4/5 xl:w-1/2'>
				<section className='form-input'>
					<label htmlFor='name' className='form-label'>
						Nombre
					</label>
					<section className=' input-wrapper'>
						<input
							type='text'
							id='name'
							className='input'
							value={form.values.name}
							onChange={form.handleChange('name')}
						/>
					</section>
					{form.errors.name && <p className='error'>{form.errors.name}</p>}
				</section>
				<section className='form-input'>
					<label htmlFor='lastname' className='form-label'>
						Apellidos
					</label>
					<section className='input-wrapper'>
						<input
							type='text'
							id='lastname'
							className='input'
							value={form.values.lastName}
							onChange={form.handleChange('lastName')}
						/>
					</section>
					{form.errors.lastName && (
						<p className='error'>{form.errors.lastName}</p>
					)}
				</section>
				<section className='form-input'>
					<label htmlFor='birthday' className='form-label'>
						Cumpleaños
					</label>
					<section className='input-wrapper'>
						<input
							type='text'
							id='birthday'
							className='input disabled:bg-transparent'
							value={form.values.birthday}
							onChange={form.handleChange('birthday')}
							disabled
						/>
					</section>
				</section>
				<section className='form-input'>
					<label htmlFor='phone' className='form-label'>
						Número Celular
					</label>
					<section className='input-wrapper'>
						<input
							type='tel'
							id='phone'
							name='phone'
							className='input !w-3/4 disabled:bg-transparent'
							value={form.values.phone}
							onChange={form.handleChange('phone')}
							disabled
						/>
						<button
							className='mr-2 text-xs font-bold underline uppercase text-primary'
							type='button'
							onClick={() => {
								navigate('/perfil/actualizar-celular');
							}}>
							Cambiar
						</button>
					</section>
				</section>
				<section className='form-input'>
					<label htmlFor='email' className='form-label'>
						Correo electrónico
					</label>
					<section className='input-wrapper'>
						<input
							type='email'
							id='email'
							className='input'
							value={form.values.email}
							onChange={form.handleChange('email')}
							autoCapitalize='off'
							autoComplete='email'
						/>
					</section>
					{form.errors.email && <p className='error'>{form.errors.email}</p>}
				</section>
				<section className='form-input'>
					<label htmlFor='password' className='form-label'>
						Contraseña
					</label>
					<section className='input-wrapper'>
						<input
							type='password'
							id='password'
							name='password'
							className='input !w-3/4 disabled:bg-transparent'
							value='* * * * * *'
							onChange={form.handleChange('password')}
							disabled
						/>
						<button
							className='mr-2 text-xs font-bold underline uppercase text-primary'
							type='button'
							onClick={onChangePassword}>
							Cambiar
						</button>
					</section>
				</section>
				<section className='flex justify-around items-center w-80 mx-auto my-4 md:col-span-2'>
					<section className='w-3/4'>
						<p className='font-bold'>Beneficios SUMA SIX</p>
						<p className='text-xs text-gray-app'>
							Selecciona si cuentas con beneficios <br /> Suma Six por parte de
							tu empresa
						</p>
					</section>
					<button
						type='button'
						className={`switch ${isEmployee ? '!bg-primary' : ''}`}
						onClick={handleEmployee}>
						<span
							className={`switch-status ${isEmployee ? '!translate-x-7 !bg-white' : ''}`}></span>
					</button>
				</section>
				{isEmployee && enterprises.length && (
					<>
						<section className='form-input'>
							<label className='form-label'>Número de Socio</label>
							<section className='input-wrapper'>
								<input
									type='number'
									id='numeroSocio'
									className='input'
									value={form.values.numeroSocio}
									onChange={form.handleChange('numeroSocio')}
								/>
							</section>
							{form.errors.numeroSocio && (
								<p className='error'>{form.errors.numeroSocio}</p>
							)}
						</section>
						<section className='form-input'>
							<label className='form-label'>Empresa</label>
							<section className='input-wrapper'>
								<select
									id='company'
									name='company'
									className='w-full text-center bg-white focus-visible:outline-none'
									defaultValue={form.values.empresa || client.empresa || ''}
									onChange={e => {
										form.setFieldValue('empresa', e.target.value);
									}}>
									<option>- Seleccionar una Empresa -</option>
									{enterprises.map((item, index) => (
										<option key={index} value={item.empresaId}>
											{item.empresa}
										</option>
									))}
								</select>
							</section>
							{form.errors.empresa && (
								<p className='error'>{form.errors.empresa}</p>
							)}
						</section>
					</>
				)}
				<button
					type='submit'
					className='btn-primary w-11/12 mx-auto my-8 py-3 md:col-span-2 md:w-3/5'>
					Guardar Cambios
				</button>
			</form>
		</section>
	);
}

function initialValues(client: ClientModel) {
	const birthday = dayjs(client.fechaNacimiento).format('DD-MMMM-YYYY');
	return {
		name: client.nombre,
		lastName: client.apellidos,
		birthday,
		phone: parsePhone(client.celular),
		email: client.correo,
		numeroSocio: client.numeroSocio,
		empresa: client.empresa,
	};
}

function validationSchema(withEmployeeBenefits: boolean = false) {
	return {
		name: Yup.string().required('Nombre es requerido').trim(),
		lastName: Yup.string().required('Apellidos son requeridos').trim(),
		email: Yup.string()
			.required('Correo electrónico es requerido')
			.email('Correo electrónico inválido')
			.trim(),
		...(withEmployeeBenefits && {
			numeroSocio: Yup.string()
				.required('Número de socio requerido')
				.matches(/^[0-9]+$/, 'Solo se permiten números')
				.trim(),
			empresa: Yup.string().required('Empresa requerida'),
		}),
	};
}

function parsePhone(phone: string): string {
	if (!phone) {
		return '';
	}

	phone = phone.slice(2);
	return `${phone.slice(0, 2)} ${phone.slice(2, 6)} ${phone.slice(6)}`;
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(ClientData);
