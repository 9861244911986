import { Link, NavLinkProps, useLocation } from 'react-router-dom';

interface LinkProps extends NavLinkProps {
	icon: string;
	name: string;
	isNotificationOpen: boolean;
	toogleNotification: () => void;
}

export default function NavLink({
	to,
	name,
	icon,
	isNotificationOpen,
	toogleNotification,
	onClick,
}: LinkProps) {
	const location = useLocation();
	const isActive = location.pathname === to;

	const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		if (onClick) {
			onClick(e);
		}
		if (isNotificationOpen) {
			toogleNotification();
		}
	};

	return (
		<Link
			to={to}
			className={`flex items-center w-fit text-primary p-2 mr-9 my-4 lg:my-0 xl:mr-14 ${isActive ? 'bg-primary !text-white rounded-sm' : ''}`}
			onClick={handleClick}>
			<img src={icon} alt='Icon' className='w-14 mr-2 lg:w-9' />
			{name}
		</Link>
	);
}
