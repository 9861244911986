import { useEffect, useState } from 'react';
import { DispatchProp, connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

// actions
import { clientTemporalPassword } from '../../../redux/actions/client';

// models
import { ClientModel } from '../../../core/models/client.model';
import { StateModel } from '../../../core/models/state.model';

// components
import NavLeft from '../../../components/NavLeft';

// utils
import { logPageView } from '../../../utils/firebaseConfig';

interface RequestResetPasswordProps {
	client: ClientModel;
}

function RequestResetPassword({
	client,
	dispatch,
}: DispatchProp & RequestResetPasswordProps) {
	const navigate = useNavigate();
	const [isSentValidation, setIsSentValidation] = useState(false);
	const form = useFormik({
		initialValues: initialValues(),
		validationSchema: Yup.object(validationSchema()),
		validateOnChange: false,
		onSubmit: values => {
			clientTemporalPassword(dispatch, values.celular);
			setIsSentValidation(true);
		},
	});
	useEffect(() => {
		logPageView('Request Reset Password', client);
	}, []);

	useEffect(() => {
		if (isSentValidation && !client.loading && !client.error) {
			navigate('/recuperar-contrasena/verificar-celular');
		}
	}, [client.loading, client.error]);

	return (
		<>
			<NavLeft name='Login' />
			<section className='flex items-center justify-center flex-col md:h-[calc(100vh-15rem)]'>
				<p className='text-xl text-center mb-4 mt-8 md:mt-0'>
					¿Olvidaste tu contraseña?
				</p>
				<p className='text-center font-bold mb-2'>¡No te preocupes!</p>
				<p className='text-center mb-8'>
					Confirmanos tu número celular para <br /> recuperar tu contraseña
				</p>
				<form onSubmit={form.handleSubmit} className='w-4/5 md:w-1/2'>
					<section className='form-input'>
						<label htmlFor='phone' className='form-label'>
							Ingresa tu número celular registrado
						</label>
						<section className='input-wrapper'>
							<input
								type='tel'
								name='phone'
								id='phone'
								className='input'
								onChange={form.handleChange('celular')}
								value={form.values.celular}
							/>
						</section>
						{form.errors.celular && (
							<p className='error'>{form.errors.celular}</p>
						)}
					</section>

					<button
						type='submit'
						className='btn-primary w-full mx-auto mt-6 md:w-1/2 '>
						Continuar
					</button>
				</form>
			</section>
		</>
	);
}
function initialValues() {
	return {
		celular: '',
	};
}

function validationSchema() {
	return {
		celular: Yup.string()
			.required('Número celular es requerido')
			.max(10, 'Número de celular a 10 dígitos')
			.min(10, 'Número de celular a 10 dígitos')
			.matches(/^[0-9]+$/, 'Sólo se aceptan números'),
	};
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(RequestResetPassword);
