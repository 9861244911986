// models
import { ActionModel } from '../../core/models/redux.model';
import { PromotionStateModel } from '../../core/models/promotion.model';

// types
import type from '../types/promotion';
import clientType from '../types/client';

const INITIAL_STATE = {
	latitud: 0,
	longitud: 0,
	mainPromotion: {
		numPromos: 0,
		promociones: [],
	},
	otherPromotions: [],
	carouselPromotions: [],
	loading: false,
	error: '',
};

export default function (
	promotion: PromotionStateModel = INITIAL_STATE,
	action: ActionModel,
) {
	switch (action.type) {
		case type.addPromotion: {
			return {
				...promotion,
				...action.payload,
				loading: false,
				error: '',
			};
		}
		case type.loading: {
			return {
				...promotion,
				loading: true,
				error: '',
			};
		}
		case type.error: {
			return {
				...promotion,
				loading: false,
				error: action.payload,
			};
		}
		case clientType.logout: {
			return INITIAL_STATE;
		}
		default:
			return promotion;
	}
}
