interface IconProps {
	className?: string;
	slot?: string;
}

const FacebookIcon = (props: IconProps) => (
	<svg
		{...props}
		id='Grupo_2504'
		data-name='Grupo 2504'
		xmlns='http://www.w3.org/2000/svg'
		width='130.431'
		height='130.431'
		viewBox='0 0 130.431 130.431'>
		<ellipse
			id='Elipse_173'
			data-name='Elipse 173'
			cx='65.215'
			cy='65.216'
			rx='65.215'
			ry='65.216'
			fill='#e11f1c'
		/>
		<path
			id='Trazado_1083'
			data-name='Trazado 1083'
			d='M60.268,96.609c2.369-.079,4.738-.016,7.117-.021h.981V84.248c-1.272-.127-2.6-.311-3.92-.375-2.432-.105-4.87-.227-7.307-.169A17.849,17.849,0,0,0,46.83,86.891a15.406,15.406,0,0,0-6.194,10.3,36.23,36.23,0,0,0-.433,5.3c-.069,2.765-.016,5.535-.011,8.3v1.039H28.385v13.781H40.124v34.642H54.469V125.669h11.7c.6-4.585,1.187-9.122,1.8-13.849H65.338c-3.324.016-10.964,0-10.964,0s.032-6.832.116-9.8C54.607,97.965,57.013,96.725,60.268,96.609Z'
			transform='translate(13.338 -55.61)'
			fill='#fff'
			fillRule='evenodd'
		/>
	</svg>
);

export default FacebookIcon;
