import { useEffect, useState } from 'react';
import { DispatchProp, connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

// actions
import {
	clientGetUpdateCellphonePin,
	setClient,
} from '../../../redux/actions/client';

// models
import { StateModel } from '../../../core/models/state.model';
import { ClientModel } from '../../../core/models/client.model';

// components
import NavLeft from '../../../components/NavLeft';

// utils
import { logPageView } from '../../../utils/firebaseConfig';

interface NewPhoneProps extends DispatchProp {
	client: ClientModel;
}

function NewPhone({ client, dispatch }: NewPhoneProps) {
	const navigate = useNavigate();
	const [isSentChangePhone, setIsSentChangePhone] = useState(false);
	const form = useFormik({
		initialValues: initialValues(),
		validationSchema: Yup.object(validationSchema()),
		validateOnChange: false,
		onSubmit: values => {
			clientGetUpdateCellphonePin(dispatch, client, values.phone);
			setIsSentChangePhone(true);
		},
	});

	useEffect(() => {
		logPageView('Client New Phone', client);
		setClient(dispatch, {
			isChangePhone: false,
			error: '',
			hasValidationPin: false,
		});
		return () => {
			setIsSentChangePhone(false);
		};
	}, []);

	useEffect(() => {
		if (
			isSentChangePhone &&
			client.isChangePhone &&
			client.hasValidationPin &&
			!client.error &&
			!client.loading
		) {
			navigate('/actualizar-celular/verificar-celular');
		}
	}, [client.isChangePhone, isSentChangePhone]);

	return (
		<>
			<NavLeft name='Número Celular' />
			<section className='mt-5 px-5 pb-8'>
				<form onSubmit={form.handleSubmit} className='w-full'>
					<section className='form-input'>
						<label
							htmlFor='phone'
							className='form-label md:mx-auto md:block md:text-center'>
							Ingresa tu número de Celular a 10 dígitos
						</label>
						<section className='input-wrapper md:!w-96'>
							<input
								type='tel'
								id='phone'
								name='phone'
								className='input'
								value={form.values.phone}
								onChange={form.handleChange('phone')}
							/>
						</section>
						{(form.errors.phone ?? client.error) && (
							<p className='error'>{form.errors.phone ?? client.error}</p>
						)}
					</section>
					<button
						type='submit'
						className='btn-primary block mx-auto w-4/5 mt-4 md:mt-10 md:w-1/2 lg:w-1/4'>
						Continuar
					</button>
				</form>
			</section>
		</>
	);
}
function initialValues() {
	return {
		phone: '',
	};
}

function validationSchema() {
	return {
		phone: Yup.string()
			.required('Número de celular es requerido')
			.matches(/^[0-9]+$/, 'Solo se permiten números')
			.max(10, 'Número de celular a 10 dígitos')
			.min(10, 'Número de celular a 10 dígitos')
			.trim(),
	};
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(NewPhone);
