import { connect } from 'react-redux';
import { Navigate, RouteProps, Outlet } from 'react-router-dom';

// models
import { StateModel } from '../core/models/state.model';

interface GuestRouteProps {
	isGuest: boolean;
}

function GuestRoute({ isGuest }: GuestRouteProps & RouteProps) {
	return isGuest ? <Outlet /> : <Navigate to='/' replace />;
}

const mapStateToProps = (state: StateModel) => ({
	isGuest: !(state.client.token && state.client.clienteId),
});

export default connect(mapStateToProps)(GuestRoute);
