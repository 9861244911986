// models
import { ActionModel } from '../../core/models/redux.model';
import { TransactionStateModel } from '../../core/models/transaction.model';

// types
import type from '../types/transaction';
import clientType from '../types/client';

const INITIAL_STATE: TransactionStateModel = {
	transactions: {},
	loading: false,
	error: '',
};

export default function (transaction = INITIAL_STATE, action: ActionModel) {
	switch (action.type) {
		case type.addTransaction: {
			return {
				...transaction,
				transactions: action.payload,
				loading: false,
				error: '',
			};
		}
		case type.loading: {
			return {
				...transaction,
				loading: true,
				error: '',
			};
		}
		case type.error: {
			return {
				...transaction,
				loading: false,
				error: action.payload,
			};
		}
		case clientType.logout: {
			return INITIAL_STATE;
		}
		default:
			return transaction;
	}
}
