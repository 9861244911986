import { connect } from 'react-redux';

// models
import { StateModel } from '../../core/models/state.model';
import { ToastModel } from '../../core/models/toast.model';

// components
import ToastCard from './Card';

interface ToastProps {
	toasts: ToastModel[];
}

function Toast({ toasts }: ToastProps) {
	if (!toasts.length) return null;

	return (
		<section className='pointer-events-none z-10 fixed inset-0 flex items-end px-4 py-6 mt-16 sm:items-start sm:p-6'>
			<section className='flex w-full flex-col items-center space-y-4 sm:items-end'>
				{toasts.map(toast => (
					<ToastCard key={toast.id} toast={toast} />
				))}
			</section>
		</section>
	);
}

const mapStateToProps = (state: StateModel) => ({
	toasts: state.toasts,
});

export default connect(mapStateToProps)(Toast);
