import { useEffect } from 'react';
import { connect } from 'react-redux';

// models
import { StateModel } from '../core/models/state.model';
import { ClientModel } from '../core/models/client.model';

// components
import AdCarousel from '../components/Ads/Carousel';
import PromotionMainList from '../components/Promotion/MainList';
import NavLeft from '../components/NavLeft';

// utils
import { logPageView } from '../utils/firebaseConfig';

interface PromotionProps {
	client: ClientModel;
}

function Promotion({ client }: PromotionProps) {
	useEffect(() => {
		logPageView('Promotions', client);
	}, []);

	return (
		<>
			<NavLeft name='Promos' />
			<AdCarousel />
			<PromotionMainList />
		</>
	);
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(Promotion);
