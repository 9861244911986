import { combineReducers } from 'redux';

// reducers
import modals from './reducers/modals';
import client from './reducers/client';
import brand from './reducers/brand';
import product from './reducers/product';
import map from './reducers/map';
import promotion from './reducers/promotion';
import ad from './reducers/ad';
import transaction from './reducers/transaction';
import coupon from './reducers/coupon';
import toasts from './reducers/toast';

export default combineReducers({
	modals,
	client,
	brand,
	product,
	map,
	promotion,
	ad,
	transaction,
	coupon,
	toasts,
});
