// models
import { ActionModel } from '../../core/models/redux.model';
import { ToastModel } from '../../core/models/toast.model';

// types
import type from '../types/toast';

export default function (toasts: ToastModel[] = [], action: ActionModel) {
	switch (action.type) {
		case type.addToast: {
			const id = toasts.reduce((p, c) => (p > c.id ? p + 1 : c.id + 1), 0);
			return toasts.concat([{ ...action.payload, id }]);
		}
		case type.removeToast: {
			return toasts.filter(toast => toast.id !== action.payload);
		}
		default:
			return toasts;
	}
}
