import { useEffect } from 'react';
import { DispatchProp, connect } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';

// actions
import { addAd } from '../../redux/actions/ad';

// models
import { StateModel } from '../../core/models/state.model';
import { MapModel } from '../../core/models/map.model';
import { AdModel } from '../../core/models/ad.model';

// components
import AdCard from './Card';

// utils
import { getCurrentLocation } from '../../utils';

interface AdCarouselProps extends DispatchProp {
	ad: AdModel;
	map: MapModel;
}

function AdCarousel({ ad, map, dispatch }: AdCarouselProps) {
	useEffect(() => {
		getCurrentLocation(dispatch, {
			latitud: map.latitud,
			longitud: map.longitud,
		});
	}, []);

	useEffect(() => {
		if (
			map.latitud &&
			map.longitud &&
			ad.latitud !== map.latitud &&
			ad.longitud !== map.longitud
		) {
			addAd(dispatch, {
				latitud: map.latitud,
				longitud: map.longitud,
				radio: 5,
			});
		}
	}, [map.latitud, map.longitud]);

	return (
		<Swiper
			spaceBetween={0}
			slidesPerView={1}
			breakpoints={{
				768: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 2.5,
				},
				1280: {
					slidesPerView: 3,
				},
			}}
			wrapperClass='md:justify-center'
			className='max-w-full !mb-10 md:max-w-[90%] lg:max-w-[85%] xl:max-w-[80%] xl:bg-white'>
			{ad.publicidad.map((ad, index) => (
				<SwiperSlide key={index + ad.id} className='!px-4'>
					<AdCard ad={ad} />
				</SwiperSlide>
			))}
		</Swiper>
	);
}

const mapStateToProps = (state: StateModel) => ({
	ad: state.ad,
	map: state.map,
});

export default connect(mapStateToProps)(AdCarousel);
