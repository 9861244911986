import { useEffect } from 'react';
import { connect } from 'react-redux';

// models
import { StateModel } from '../../core/models/state.model';
import { ClientModel } from '../../core/models/client.model';

// components
import CouponsList from '../../components/Coupons/List';

// utils
import { logPageView } from '../../utils/firebaseConfig';

interface CouponsProps {
	client: ClientModel;
}

function Coupons({ client }: CouponsProps) {
	useEffect(() => {
		logPageView('Coupons', client);
	}, []);

	return <CouponsList />;
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(Coupons);
