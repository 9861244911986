import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

export default function Layout({ children }: { children: ReactElement }) {
	const location = useLocation();
	const isHome = location.pathname === '/';

	return (
		<>
			<Navbar />
			<main
				className={`flex-grow mt-20 ${isHome ? 'bg-home bg-no-repeat bg-fixed bg-top bg-contain md:bg-[left_-7.5rem_top] lg:bg-[left_-2rem_top] xl:bg-[left_10rem_top]' : ''}`}>
				{children}
			</main>
			<Footer />
		</>
	);
}
