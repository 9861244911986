import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DispatchProp, connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// actions
import { addModal } from '../../../redux/actions/modals';
import { setClient } from '../../../redux/actions/client';

// models
import { StateModel } from '../../../core/models/state.model';
import { ClientModel } from '../../../core/models/client.model';
import { BrandModel } from '../../../core/models/brand.model';

// components
import FavoriteBrandModal from '../../../components/Brand/FavoriteBrandModal';
import ClientList from '../../../components/Brand/ClientList';
import NavLeft from '../../../components/NavLeft';

// utils
import { MONTHS } from '../../../utils';
import { logPageView } from '../../../utils/firebaseConfig';

interface FormValues {
	name: string;
	lastName: string;
	day: string;
	month: string;
	year: string;
	favoriteBrand: number[];
	referidoId: number | string;
}

interface DataClientProps {
	favoriteBrand: BrandModel[];
	client: ClientModel;
}

function ClientData({
	client,
	favoriteBrand,
	dispatch,
}: DispatchProp & DataClientProps) {
	const navigate = useNavigate();
	const form = useFormik({
		initialValues: initialValues(client),
		validationSchema: Yup.object(validationSchema()),
		validateOnChange: false,
		onSubmit: values => {
			setClient(dispatch, {
				nombre: values.name.trim(),
				apellidos: values.lastName.trim(),
				cumpleanos: `${values.year}-${values.month}-${values.day}`,
				referidoId: values.referidoId,
			});
			navigate('/registro/empresa');
		},
	});

	useEffect(() => {
		logPageView('Register Client Data');
	}, []);

	useEffect(() => {
		if (favoriteBrand.length) {
			form.setFieldValue(
				'favoriteBrand',
				favoriteBrand.map(item => item.marcaId),
			);
		}
	}, [favoriteBrand]);

	return (
		<>
			<NavLeft name='Registro' />
			<h1 className='text-xl text-primary text-center font-bold'>¡HOLA!</h1>
			<p className='text-center text-lg'>Crea tu cuenta rápido y fácil</p>
			<form
				className='w-fit mx-auto max-w-full mt-10 md:grid md:grid-cols-2 md:gap-4 md:w-3/4 lg:w-1/2'
				onSubmit={form.handleSubmit}>
				<section className='form-input'>
					<label htmlFor='name' className='form-label'>
						Nombre
					</label>
					<section className=' input-wrapper'>
						<input
							type='text'
							id='name'
							className='input'
							value={form.values.name}
							onChange={form.handleChange('name')}
						/>
					</section>
					{form.errors.name && <p className='error'>{form.errors.name}</p>}
				</section>
				<section className='form-input'>
					<label htmlFor='lastname' className='form-label'>
						Apellidos
					</label>
					<section className='input-wrapper'>
						<input
							type='text'
							id='lastname'
							className='input'
							value={form.values.lastName}
							onChange={form.handleChange('lastName')}
						/>
					</section>
					{form.errors.lastName && (
						<p className='error'>{form.errors.lastName}</p>
					)}
				</section>
				<section className='form-input'>
					<label className='form-label'>Cumpleaños</label>
					<section className='flex items-center justify-between'>
						<section className='input-wrapper !w-20'>
							<input
								type='number'
								id='day'
								className='input text-center'
								placeholder='Día'
								value={form.values.day}
								onChange={form.handleChange('day')}
							/>
						</section>
						<section className='input-wrapper !w-32 mx-4'>
							<select
								id='month'
								name='month'
								className='text-center bg-white focus-visible:outline-none'
								defaultValue=''
								onChange={e => {
									form.setFieldValue('month', e.target.value);
								}}>
								<option>Mes</option>
								{MONTHS.map((item, index) => (
									<option key={index} value={item.value}>
										{item.label}
									</option>
								))}
							</select>
						</section>
						<section className='input-wrapper !w-20'>
							<input
								type='number'
								id='year'
								className='input text-center'
								placeholder='Año'
								value={form.values.year}
								onChange={form.handleChange('year')}
							/>
						</section>
					</section>
					{(form.errors.day || form.errors.month || form.errors.year) && (
						<p className='error'>
							{form.errors.day || form.errors.month || form.errors.year}
						</p>
					)}
				</section>
				{favoriteBrand.length ? (
					<ClientList />
				) : (
					<section className='form-input md:order-last'>
						<p className='form-label'>¿Cuál es tu cervaza favorita?</p>
						<button
							className='input-wrapper !block text-center'
							type='button'
							onClick={() => {
								addModal(dispatch, {
									component: FavoriteBrandModal,
								});
							}}>
							- Seleccionar Cerveza -
						</button>
						{form.errors.favoriteBrand && (
							<p className='error'>{form.errors.favoriteBrand}</p>
						)}
					</section>
				)}
				<section className='form-input'>
					<label htmlFor='referido' className='form-label'>
						Código de referido (Opcional)
					</label>
					<section className=' input-wrapper'>
						<input type='number' id='referido' className='input' />
					</section>
					{form.errors.referidoId && (
						<p className='error'>{form.errors.referidoId}</p>
					)}
				</section>
				<button
					type='submit'
					className='btn btn-primary w-4/5 my-4 mx-auto block md:order-last md:col-span-2 md:w-1/2'>
					Continuar
				</button>
			</form>
		</>
	);
}

function initialValues(client: ClientModel): FormValues {
	const birthday = client.cumpleanos?.split('-');
	return {
		name: client.nombre ?? '',
		lastName: client.apellidos ?? '',
		day: birthday ? birthday[2] : '',
		month: birthday ? birthday[1] : '',
		year: birthday ? birthday[0] : '',
		favoriteBrand: [],
		referidoId: client.referidoId ?? '',
	};
}

function validationSchema() {
	return {
		name: Yup.string().required('Nombre es requerido').trim(),
		lastName: Yup.string().required('Apellidos es requerido').trim(),
		day: Yup.string()
			.required('Día es requerido')
			.matches(/^(0?[1-9]|[12][0-9]|3[01])$/, 'Día no válido')
			.min(1, 'Día no válido')
			.max(2, 'Día no válido')
			.trim(),
		month: Yup.string().required('Mes es requerido').trim(),
		year: Yup.string()
			.required('Año es requerido')
			.min(4, 'Año no válido')
			.max(4, 'Año no válido')
			.trim(),
		favoriteBrand: Yup.array()
			.required('Cerveza favorita es requerido')
			.min(1, 'Cerveza favorita es requerido'),
		referidoId: Yup.number()
			.typeError('Sólo se permiten números')
			.nullable()
			.optional(),
	};
}

const mapStateToProps = (state: StateModel) => ({
	favoriteBrand: state.client.favoriteBrand,
	client: state.client,
});

export default connect(mapStateToProps)(ClientData);
