import dayjs from 'dayjs';

// models
import { CouponModel } from '../../core/models/coupon.model';

// components
import ClientQrCard from '../Client/QrCard';

interface CouponCardProps {
	small?: boolean;
	coupon: CouponModel;
}

export default function CouponCard({ small, coupon }: CouponCardProps) {
	if (small) {
		return <CuponCardSmall coupon={coupon} />;
	}

	return (
		<section className='card !p-8 w-80 mb-5 mx-auto'>
			<section className='w-full mb-5 px-1 rounded-xl bg-primary text-center text-white py-2 font-bold'>
				{coupon.monto}
				{coupon.monto > 1 ? ' CUPONES DISPONIBLES' : ' CUPÓN DISPONIBLE'}
			</section>
			<ClientQrCard />
			<p className='text-xl text-center font-bold my-5'>{coupon.cuponId}</p>
			<p className='text-primary text-xl text-center uppercase font-bold'>
				{coupon.descripcion}
			</p>
			<p className='text-xs text-center font-medium mt-9'>
				Valido hasta el {dayjs(coupon.vigencia).format('YYYY-MM-DD')}
				<br />o hasta agotar existencias
			</p>
		</section>
	);
}

function CuponCardSmall({ coupon }: CouponCardProps) {
	return (
		<section className='card !p-8 w-80 mb-8'>
			<section className='flex items-center'>
				<img
					src={coupon.imagenUrl}
					className='w-[70px] h-[70px] object-contain mr-5'
				/>
				<section>
					<p
						className='font-bold uppercase'
						style={{ color: coupon.colorCategoria }}>
						{coupon.categoria}
					</p>
					<p className='text-sm'>
						Fecha: {dayjs(coupon.fechaUso).format('DD-MM-YYYY')}
					</p>
					{coupon.tienda && (
						<p className=' max-w-48'>Tienda: {coupon.tienda}</p>
					)}
				</section>
			</section>
		</section>
	);
}
