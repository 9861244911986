import { Fragment, useEffect } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

// actions
import { getProduct } from '../../redux/actions/product';

// models
import { StateModel } from '../../core/models/state.model';
import {
	ProductModel,
	ProductStateModel,
} from '../../core/models/product.model';
import { ClientModel } from '../../core/models/client.model';

// components
import ProductCard from './Card';

interface ProductCarouselProps {
	product: ProductStateModel;
	client: ClientModel;
}

function ProductCarousel({
	product,
	client,
	dispatch,
}: ProductCarouselProps & DispatchProp) {
	const location = useLocation();
	const isHomeScreen = location.pathname === '/';

	useEffect(() => {
		if (!product.data.length) {
			setTimeout(() => {
				getProduct(dispatch);
			}, 100);
		}
	}, []);

	const products = isHomeScreen
		? product.data.filter(p => p.puntos <= client.saldo || p.puntos <= 1700)
		: product.data;

	return (
		<section className='bg-gray-product px-5 pt-8 pb-5'>
			<Swiper
				spaceBetween={0}
				slidesPerView={2}
				className='md:w-1/2'
				wrapperClass='items-center'>
				{products.map((product, index) => (
					<Fragment key={product.productoId + index}>
						{isHomeScreen && index === 0 && (
							<SwiperSlide key='client'>
								<RenderClientItem client={client} product={product} />
							</SwiperSlide>
						)}
						<SwiperSlide key={product.productoId}>
							<ProductCard product={product} />
						</SwiperSlide>
					</Fragment>
				))}
			</Swiper>
		</section>
	);
}

function RenderClientItem({
	client,
	product,
}: {
	client: ClientModel;
	product: ProductModel;
}) {
	const navigate = useNavigate();

	const goToProduct = () => {
		navigate('productos');
	};

	return (
		<section className='w-[170px] mr-5 md:w-60'>
			{client.saldo < product.puntos ? (
				<>
					<p className='text-primary text-center md:text-xl'>
						¡Sigue acumulando!
					</p>
					<p className='text-center mb-3 leading-5 md:text-xl'>
						Estos son los productos <br /> que podrás canjear
						<br /> con {product.puntos.toLocaleString()} puntos
					</p>
				</>
			) : (
				<p className='text-center mb-3'>
					Productos que <br /> puedes canjear
					<br /> con {client.saldo?.toLocaleString()} puntos
				</p>
			)}
			<button
				type='button'
				className='btn-primary text-xs mx-auto !py-2 md:!py-3 md:text-base md:w-11/12'
				onClick={goToProduct}>
				Más Productos
			</button>
		</section>
	);
}

const mapStateToProps = (state: StateModel) => ({
	product: state.product,
	client: state.client,
});

export default connect(mapStateToProps)(ProductCarousel);
