import { useEffect, useState } from 'react';
import { DispatchProp, connect } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

// actions
import { addCoupon } from '../../redux/actions/coupon';

// models
import { StateModel } from '../../core/models/state.model';
import { ClientModel } from '../../core/models/client.model';
import {
	CouponCategoryModel,
	CouponStateModel,
} from '../../core/models/coupon.model';

// components
import CouponCard from './Card';
import CouponDefaultCard from './DefaultCard';

// assets
import expireIcon from '../../assets/icons/expire.png';

interface CouponsListProps extends DispatchProp {
	client: ClientModel;
	coupon: CouponStateModel;
}

function CouponsList({ client, coupon, dispatch }: CouponsListProps) {
	const [isUsed, setIsUsed] = useState<boolean>(false);
	const [category, setCategory] = useState<number | string>('');
	useEffect(() => {
		addCoupon(dispatch, client);
	}, []);

	useEffect(() => {
		if (coupon.categories.length) {
			changeCategory()();
		}
	}, [coupon]);

	const changeCategory = (item?: CouponCategoryModel) => () => {
		if (item?.id === category) {
			return;
		}
		let categoryId: number | null = null;
		if (item) {
			categoryId = item.id;
		} else {
			const defaultCategory = coupon.categories[0];
			categoryId = defaultCategory.id;
		}
		setCategory(categoryId);
	};

	if (coupon.loading) {
		return (
			<section className='flex justify-center mt-11'>
				<p className='text-primary text-xl font-black animate-pulse'>
					Cargando...
				</p>
			</section>
		);
	}

	return (
		<section className='list'>
			<section className='flex px-8 justify-center mt-5 w-full'>
				<button
					className={`btn-primary mx-4 border border-primary w-36 text-sm ${isUsed ? '!bg-white !text-primary' : ''}`}
					onClick={() => {
						setIsUsed(false);
					}}>
					Disponibles
				</button>
				<button
					className={`btn-primary mx-4 border border-primary w-36 text-sm ${!isUsed ? '!bg-white !text-primary' : ''}`}
					onClick={() => {
						setIsUsed(true);
					}}>
					Utilizados
				</button>
			</section>
			{!isUsed ? (
				<CouponsListAvailable
					coupon={coupon}
					category={category}
					onChangeCategory={changeCategory}
				/>
			) : (
				<CouponsListUsed coupon={coupon} />
			)}
		</section>
	);
}

function CouponsListAvailable({
	coupon,
	category,
	onChangeCategory,
}: {
	coupon: CouponStateModel;
	category: number | string;
	onChangeCategory: (item: CouponCategoryModel) => () => void;
}) {
	if (!coupon.categories.length) {
		return (
			<p className='mt-11 text-center font-bold'>
				Aún no tienes cupones disponibles
			</p>
		);
	}
	return (
		<>
			<section className='flex mt-7 mx-4 mb-10 items-center xl:w-3/4 xl:mx-auto'>
				<button className='button-prev disabled:opacity-50'></button>
				<Swiper
					navigation={{
						nextEl: '.button-next',
						prevEl: '.button-prev',
					}}
					modules={[Navigation]}
					slidesPerView={3}
					className='h-[150px] xl:w-full'
					breakpoints={{
						1024: {
							slidesPerView: 9,
						},
					}}
					wrapperClass='xl:justify-center'>
					{coupon.categories.map(item => (
						<SwiperSlide
							key={item.id}
							className='!flex items-center justify-center relative'>
							<button
								className={`flex flex-col items-center h-[90px] ${!item.hasCoupons ? 'opacity-30' : 'opacity-100'}`}
								onClick={onChangeCategory(item)}>
								{item.hasExpiration && (
									<img
										src={expireIcon}
										className='absolute w-6 h-6 z-10 right-5 top-7 object-contain'
									/>
								)}
								<section
									className={`${item.id === category ? 'border-dotted p-1 rounded-[50%] border-2 border-primary' : ''}`}>
									<section className='flex flex-col justify-center rounded-[35px] w-[70px] h-[70px]'>
										<img
											src={item.url_icono_carrusel_app}
											className='w-full h-full rounded-[50%] object-contain'
										/>
									</section>
								</section>
								<span
									className='text-xs text-center mt-1 font-black uppercase'
									style={{ color: item.color }}>
									{item.nombre}
								</span>
							</button>
						</SwiperSlide>
					))}
				</Swiper>
				<button className='button-next disabled:opacity-50'></button>
			</section>
			{coupon.available.filter(item => item.categoriaId === category).length ? (
				coupon.available
					.filter(item => item.categoriaId === category)
					.map(item => <CouponCard key={item.cuponId} coupon={item} />)
			) : (
				<CouponDefaultCard
					category={coupon.categories.find(item => item.id === category)}
				/>
			)}
		</>
	);
}

function CouponsListUsed({ coupon }: { coupon: CouponStateModel }) {
	return (
		<section className='my-10 flex flex-col items-center justify-center'>
			{!coupon.used.length ? (
				<p className='text-center font-bold'>
					Aún no has utilizado ningún cupón
				</p>
			) : (
				coupon.used.map(item => (
					<CouponCard key={item.cuponId} small coupon={item} />
				))
			)}
		</section>
	);
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
	coupon: state.coupon,
});

export default connect(mapStateToProps)(CouponsList);
