import { connect, DispatchProp } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

// actions
import { clientLogout } from '../../redux/actions/client';

// models
import { StateModel } from '../../core/models/state.model';
import { ClientModel } from '../../core/models/client.model';

// assets
import phoneIcon from '../../assets/icons/phone.png';
import notificationIcon from '../../assets/icons/notification.png';
import ClientIcon from '../../svgs/ClientIcon';

interface NavClientProps {
	client: ClientModel;
	onClick: () => void;
	toogleNotification: () => void;
	isNotificationOpen: boolean;
}

function NavClient({
	client,
	onClick,
	toogleNotification,
	isNotificationOpen,
	dispatch,
}: NavClientProps & DispatchProp) {
	const { pathname } = useLocation();

	return (
		<>
			<ClientNavHeader
				onClick={onClick}
				toogleNotification={toogleNotification}
				isNotificationOpen={isNotificationOpen}
			/>
			<Link
				to='/perfil'
				className={`flex items-center w-fit text-primary p-2 mr-9 mb-4 lg:my-0 ${pathname.includes('/perfil') ? 'underline bg-primary !text-white rounded-sm lg:!text-primary lg:bg-transparent' : ''}`}
				onClick={() => {
					onClick();
					if (isNotificationOpen) {
						toogleNotification();
					}
				}}>
				<ClientIcon className='w-12 h-auto mr-2 fill-primary lg:hidden' />
				PERFIL
			</Link>
			<button
				className='flex items-center text-primary p-2 mr-9 my-4 md:my-0'
				onClick={() => {
					clientLogout(dispatch, client);
					onClick();
					if (isNotificationOpen) {
						toogleNotification();
					}
				}}>
				SALIR
			</button>
		</>
	);
}

export function ClientNavHeader({
	onClick,
	isHeader,
	toogleNotification,
	isNotificationOpen,
}: {
	onClick: () => void;
	isHeader?: boolean;
	toogleNotification: () => void;
	isNotificationOpen: boolean;
}) {
	const isOSMobile =
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent,
		);
	return (
		<>
			<Link
				to='tel:55-7005-5166'
				className={`items-center text-primary mt-0  md:my-0 lg:flex ${isHeader ? 'mr-2' : 'hidden my-4 mr-9'}`}>
				<img
					src={phoneIcon}
					alt='Icon'
					className='h-6 min-w-6 w-auto mr-2 ml-2 md:ml-0'
					onClick={e => {
						if (isNotificationOpen) {
							toogleNotification();
						}
						if (!isOSMobile) {
							e.preventDefault();
							navigator.clipboard.writeText('5570055166');
						}
					}}
				/>
			</Link>
			<Link
				to='/notificaciones'
				className={`items-center text-primary mt-0 md:my-0 lg:flex ${isHeader ? 'mr-1' : 'hidden my-4 mr-9'}`}
				onClick={e => {
					e.preventDefault();
					toogleNotification();
				}}>
				<img
					src={notificationIcon}
					alt='Icon'
					className='h-7 min-w-6 mr-2 ml-2 md:ml-0'
				/>
			</Link>
		</>
	);
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(NavClient);
