import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

// models
import { StateModel } from '../../core/models/state.model';
import { ClientModel } from '../../core/models/client.model';

// assets
import ClientIcon from '../../svgs/ClientIcon';

interface ClientHeaderProps {
	client: ClientModel;
}

function ClientHeader({ client }: ClientHeaderProps) {
	const navigate = useNavigate();
	const registrationDate = dayjs(client.fechaAlta).format('DD MMMM YYYY');

	return (
		<section className='flex flex-col items-center lg:w-96 lg:mx-auto'>
			<ClientIcon className='w-[90px] h-[90px] my-4 fill-primary' />
			<p className='text-primary text-xl text-center font-bold'>
				{client.nombre} {client.apellidos}
			</p>
			<p className='text-center font-medium'>
				Miembro desde {registrationDate}
			</p>
			<button
				className='btn-primary py-2 w-11/12 mt-5 md:w-4/5 lg:w-full'
				onClick={() => {
					navigate('/perfil/completar-datos');
				}}>
				Completar Datos de Perfil
			</button>
		</section>
	);
}
const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(ClientHeader);
