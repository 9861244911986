import { Dispatch } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

// services
import {
	loginApi,
	getProfileApi,
	getClientApi,
	validateCellPhoneApi,
	registerApi,
	temporalPasswordApi,
	temporalLoginApi,
	resetPasswordApi,
	logoutApi,
	getUpdateCellphonePinApi,
	updateCellphoneApi,
	updateClientApi,
	validatePasswordPinApi,
	getPasswordPinApi,
	changePasswordApi,
	registerValidationApi,
	updateTokenFirebaseApi,
	deleteClientApi,
} from '../../core/services/client.service';
import {
	addClientBrandApi,
	getBrandsByClientApi,
} from '../../core/services/brand.service';

// actions
import { removeModal } from './modals';

// models
import { BrandModel } from '../../core/models/brand.model';
import { ClientModel } from '../../core/models/client.model';
import { ModalModel } from '../../core/models/modal.model';

// types
import type from '../types/client';

// utils
import { getStorageItem, parseError } from '../../utils';

/**
 * Login a client by making an API call with the client data.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {Partial<ClientModel>} client - The client data for login.
 */
export function clientLogin(dispatch: Dispatch, client: Partial<ClientModel>) {
	dispatch({
		type: type.loading,
	});

	const tokenFirebase = client.tokenFirebase;
	delete client.tokenFirebase;
	loginApi(dispatch, client)
		.then((clientData: ClientModel) => {
			Cookies.set('token', clientData.token, { secure: true });
			setClient(dispatch, {
				...clientData,
				tokenFirebase,
				canShowBiometricsModal: true,
			});
			clientInformation(dispatch, clientData.clienteId);
			if (client.celular) {
				clientProfile(dispatch, client.celular);
			}
		})
		.catch((error: string | object) => {
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}

/**
 * Logout a client by making an API call with the client data.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {ClientModel} client - The client data for logout.
 */
export function clientLogout(dispatch: Dispatch, client?: ClientModel) {
	dispatch({
		type: type.loading,
	});
	// TODO: Temporal fix for logout until the API is ready to handle it. The API needs a token but the token is expired.
	dispatch({
		type: type.logout,
	});
	if (!client) {
		const storage = getStorageItem('persist:root', {});

		if (storage.client) {
			client = JSON.parse(storage.client as string);
		}
	}
	if (client) {
		logoutApi(dispatch, {
			tokenFirebase: client?.tokenFirebase,
			clienteId: client?.clienteId,
		})
			.then(() => {
				dispatch({
					type: type.logout,
				});
				Cookies.remove('token');
			})
			.catch((error: string | object) => {
				Cookies.remove('token');
				dispatch({
					type: type.error,
					payload: parseError(error),
				});
			});
	}
}

/**
 * Fetches and sets client information from the API.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {ClientModel['token']} token - The client token to fetch information for.
 * @param {ClientModel['clienteId']} client - The client ID to fetch information for.
 */
export function clientInformation(
	dispatch: Dispatch,
	client: ClientModel['clienteId'],
) {
	getClientApi(dispatch, client)
		.then((client: ClientModel) => {
			setClient(dispatch, client);
		})
		.catch((error: string | object) => {
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}

/**
 * Fetches and sets client profile information from the API using a cell phone number.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {ClientModel['celular']} cellPhone - The client's cell phone number used to fetch the profile.
 */
export function clientProfile(
	dispatch: Dispatch,
	cellPhone: ClientModel['celular'],
) {
	getProfileApi(dispatch, cellPhone)
		.then((client: ClientModel) => {
			setClient(dispatch, {
				...client,
				isGetCard: true,
			});
		})
		.catch((error: string | object) => {
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}

/**
 * Validates a client's cell phone number by making an API call to verify it exists in the database.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {ClientModel['celular']} cellPhone - The client's cell phone number for validation.
 */
export function clientValidateCellPhone(
	dispatch: Dispatch,
	cellPhone: ClientModel['celular'],
	resend: boolean = false,
) {
	dispatch({
		type: type.loading,
	});
	validateCellPhoneApi(dispatch, cellPhone, resend)
		.then(() => {
			setClient(dispatch, {
				hasValidationPin: true,
				isRegister: true,
				isChangePassword: false,
				isResetPassword: false,
				isChangePhone: false,
			});
		})
		.catch((error: string | object) => {
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}

/**
 * Validates a client's cell phone number by making an API call to verify it exists in the database.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {ClientModel['celular']} cellPhone - The client's cell phone number for validation.
 */
export function clientRegisterValidationPin(
	dispatch: Dispatch,
	client: ClientModel,
) {
	dispatch({ type: type.loading });
	registerValidationApi(dispatch, client.celular, client.pin)
		.then(() => {
			setClient(dispatch, {
				hasValidationPin: true,
				isRegister: true,
				isChangePassword: false,
				isResetPassword: false,
				isChangePhone: false,
			});
		})
		.catch((error: string | object) => {
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}

/**
 * Registers a client by making an API call with the client data.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {Partial<ClientModel>} client - The client data for registration.
 */
export function clientRegister(
	dispatch: Dispatch,
	client: Partial<ClientModel>,
) {
	dispatch({
		type: type.loading,
	});
	registerApi(dispatch, client)
		.then(() => {
			dispatch({
				type: type.setClient,
				payload: {
					celular: client.celular,
					nombre: client.nombre,
					isRegister: true,
					hasValidationPin: false,
					canAddPassword: true,
				},
			});
		})
		.catch((error: string | object) => {
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}

/**
 * Sends a temporal password to reset the client's password to the client's cell phone number by making an API call.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {ClientModel['celular']} celular - The client's cell phone number.
 */
export function clientTemporalPassword(
	dispatch: Dispatch,
	celular: ClientModel['celular'],
) {
	dispatch({
		type: type.loading,
	});
	temporalPasswordApi(dispatch, celular)
		.then(() => {
			setClient(dispatch, {
				celular,
				isResetPassword: true,
				hasValidationPin: true,
				isChangePassword: false,
				isChangePhone: false,
				isRegister: false,
			});
		})
		.catch((error: string | object) => {
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}

/**
 *  Login a client with a temporal password by making an API call.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {Partial<ClientModel>} client - The client data for login.
 */
export function clientTemporalLogin(
	dispatch: Dispatch,
	client: Partial<ClientModel>,
) {
	dispatch({
		type: type.loading,
	});
	temporalLoginApi(dispatch, client)
		.then((clientData: ClientModel) => {
			setClient(dispatch, {
				...client,
				...clientData,
				hasValidationPin: true,
				isResetPassword: true,
				canAddPassword: true,
				isChangePassword: false,
				isChangePhone: false,
				isRegister: false,
			});
		})
		.catch((error: string | object) => {
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}

/**
 * Resets the client's password by making an API call.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {Partial<ClientModel>} client - The client data for password reset.
 */
export function clientResetPassword(
	dispatch: Dispatch,
	client: Partial<ClientModel>,
) {
	dispatch({
		type: type.loading,
	});
	resetPasswordApi(dispatch, client)
		.then(() => {
			setClient(dispatch, {});
		})
		.catch((error: string | object) => {
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}

export function clientUpdate(dispatch: Dispatch, client: ClientModel) {
	dispatch({
		type: type.loading,
	});
	if (client.clienteId && client.token) {
		updateClientApi(dispatch, client.clienteId, {
			nombre: client.nombre,
			aPaterno: client.apellidos,
			aMaterno: client.aMaterno ?? '',
			correo: client.correo,
			cumpleanos: new Date(client.fechaNacimiento).toISOString(),
			codigoPostal: client.codigoPostal,
			calle: client.calle,
			noExterno: client.noExterno,
			colonia: client.colonia,
			entreCalle: client.entreCalle,
			yCalle: client.yCalle,
			delegacion: client.delegacion,
			estado: client.estado,
			sexo: client.sexo,
			empresa: client.empresa,
			numeroSocio: client.numeroSocio,
		})
			.then((newDataClient: ClientModel) => {
				setClient(dispatch, {
					...newDataClient,
					apellidos: newDataClient.aPaterno,
				});
			})
			.catch((error: string | object) => {
				dispatch({
					type: type.error,
					payload: parseError(error),
				});
			});
	}
}

/**
 * Gets a pin to update the client's cell phone number by making an API call.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {ClientModel} client - The client data
 * @param {ClientModel['celular']} celular - The new client's cell phone number.
 */
export function clientGetUpdateCellphonePin(
	dispatch: Dispatch,
	client: ClientModel,
	celular: ClientModel['celular'],
) {
	dispatch({
		type: type.loading,
	});
	getUpdateCellphonePinApi(dispatch, client.clienteId, celular)
		.then(() => {
			setClient(dispatch, {
				isChangePhone: true,
				hasValidationPin: true,
				celularTemporal: celular,
				isChangePassword: false,
				isResetPassword: false,
				isRegister: false,
			});
		})
		.catch((error: string | object) => {
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}

/**
 * Updates the client's cell phone number by making an API call.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {Partial<ClientModel>} client - The client data
 */
export function clientUpdateCellphone(
	dispatch: Dispatch,
	client: Partial<ClientModel>,
) {
	dispatch({
		type: type.loading,
	});

	updateCellphoneApi(dispatch, {
		clienteId: client.clienteId,
		celular: client.celular,
		pin: client.pin,
	})
		.then(() => {
			setClient(dispatch, {
				celular: `52${client.celular}`,
			});
		})
		.catch((error: string | object) => {
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}

/**
 * Gets a pin to validate the client's cell phone number by making an API call.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {ClientModel} client - The client data
 */
export function clientGetValidatePasswordPin(
	dispatch: Dispatch,
	client: ClientModel,
) {
	dispatch({
		type: type.loading,
	});
	getPasswordPinApi(dispatch, client.clienteId, client.celular.slice(2))
		.then(() => {
			setClient(dispatch, {
				hasValidationPin: true,
				isChangePassword: true,
				isChangePhone: false,
				isResetPassword: false,
				isRegister: false,
			});
		})
		.catch((error: string | object) => {
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}

/**
 * Validates the client's password pin by making an API call.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {ClientModel} client - The client data
 */
export function clientValidatePasswordPin(
	dispatch: Dispatch,
	client: ClientModel,
) {
	dispatch({
		type: type.loading,
	});
	validatePasswordPinApi(
		dispatch,
		client.clienteId,
		client.celular.slice(2),
		client.pin,
	)
		.then(() => {
			dispatch({
				type: type.setClient,
				payload: {
					hasValidationPin: true,
					isChangePassword: true,
					canAddPassword: true,
					isChangePhone: false,
					isResetPassword: false,
					isRegister: false,
				},
			});
		})
		.catch((error: string | object) => {
			console.log(error);
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}

/**
 * Changes the client's password by making an API call.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {ClientModel} client - The client data
 */
export function clientChangePassword(dispatch: Dispatch, client: ClientModel) {
	dispatch({
		type: type.loading,
	});
	changePasswordApi(dispatch, {
		clienteId: client.clienteId,
		claveNueva: client.claveNueva,
		claveConfirmacion: client.claveConfirmacion,
	})
		.then(() => {
			dispatch({
				type: type.setClient,
				payload: {
					isChangePassword: true,
					hasValidationPin: false,
					isChangePhone: false,
					isResetPassword: false,
					isRegister: false,
				},
			});
		})
		.catch((error: string | object) => {
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}

/**
 * Gets the client's favorite brands by making an API call.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {ClientModel} client - The client data
 *
 */
export function clientGetFavoriteBrands(
	dispatch: Dispatch,
	client: ClientModel,
) {
	getBrandsByClientApi(dispatch, client.clienteId)
		.then((brands: BrandModel[]) => {
			dispatch({
				type: type.setClient,
				payload: {
					favoriteBrand: brands,
				},
			});
		})
		.catch((error: string | object) => {
			console.log(error);
		});
}

/**
 * Sets the client's favorite brands by making an API call.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {ClientModel} client - The client data
 * @param {BrandModel[]} brands - The client's favorite brands
 * @param {BrandModel} brand - The brand to be added or removed from the list of favorite brands
 * @param {boolean} isRemove - Indicates if the brand should be removed from the list of favorite brands
 */
export function clientSetFavoriteBrands(
	dispatch: Dispatch,
	client: ClientModel,
	brands: BrandModel[],
) {
	addClientBrandApi(dispatch, client.clienteId, {
		new: brands.filter(item => item.isCustom).map(item => item.nombreMarca),
		old: brands.filter(item => !item.isCustom).map(item => item.marcaId),
	})
		.then(() => {
			clientGetFavoriteBrands(dispatch, client);
		})
		.catch((error: string | object) => {
			console.log(error);
		});
}

/**
 * Updates the client's tokenFirebase by making an API call.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {ClientModel} client - The client data
 */
export function clientUpdateTokenFirebase(
	dispatch: Dispatch,
	client: ClientModel,
) {
	updateTokenFirebaseApi(dispatch, {
		clienteId: client.clienteId,
		tokenFirebase: client.tokenFirebase,
	})
		.then(() => {
			setClient(dispatch, {
				tokenFirebase: client.tokenFirebase,
			});
		})
		.catch((error: string | object) => {
			console.log(error);
		});
}

/**
 * Delete the client's account by making an API call.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {ClientModel} client - The client data
 * @param {ModalModel['id']} modalId - The modal ID to be removed
 */
export function clientDeleteAccount(
	dispatch: Dispatch,
	client: ClientModel,
	modalId: ModalModel['id'],
) {
	deleteClientApi(dispatch, client.clienteId)
		.then(() => {
			clientLogout(dispatch, client);
			removeModal(dispatch, modalId);
		})
		.catch((error: string | object) => {
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}

/**
 * Sets the client data to the Redux state by dispatching an action.
 *
 * @param {Dispatch} dispatch - The Redux dispatch function.
 * @param {Partial<ClientModel>} client - The client data to be set.
 */
export function setClient(dispatch: Dispatch, client: Partial<ClientModel>) {
	dispatch({
		type: type.setClient,
		payload: client,
	});
}

/**
 * Adds a favorite brand to the Redux state by dispatching an action.
 *
 * @param {Dispatch} dispatch - The Redux dispatch function.
 * @param {BrandModel} brand - The brand to be added to the list of favorite brands.
 */
export function addFavoriteBrand(dispatch: Dispatch, brand: BrandModel) {
	dispatch({
		type: type.addFavoriteBrand,
		payload: [brand],
	});
}

/**
 * Removes a favorite brand from the Redux state by dispatching an action.
 * @param {Dispatch} dispatch - The Redux dispatch function.
 * @param {BrandModel['marcaId']} brand - The brand Id to be removed from the list of favorite brands.
 */

export function removeFavoriteBrand(
	dispatch: Dispatch,
	brand: BrandModel['marcaId'],
) {
	dispatch({
		type: type.removeFavoriteBrand,
		payload: brand,
	});
}

/**
 * Resets the client state to the initial state by dispatching an action.
 *
 * @param {Dispatch} dispatch - The Redux dispatch function.
 */
export function clientReset(dispatch: Dispatch) {
	dispatch({
		type: type.reset,
	});
}

/**
 * Sets the client's encrypted card to the Redux state by dispatching an action.
 *
 * @param {Dispatch} dispatch - The Redux dispatch function.
 * @param {string} encryptedCard - The client's encrypted card.
 */
export function setClientEncryptedCard(
	dispatch: Dispatch,
	encryptedCard: string,
) {
	dispatch({
		type: type.setEncryptedCard,
		payload: encryptedCard,
	});
}
