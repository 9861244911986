// models
import { ActionModel } from '../../core/models/redux.model';
import { AdModel } from '../../core/models/ad.model';

//  types
import type from '../types/ad';
import clientType from '../types/client';

const INITIAL_STATE: AdModel = {
	publicidad: [],
	latitud: 0,
	longitud: 0,
	loading: false,
	error: '',
};

export default (ad = INITIAL_STATE, action: ActionModel) => {
	switch (action.type) {
		case type.addAd:
			return {
				...ad,
				...action.payload,
				loading: false,
				error: '',
			};
		case type.loading: {
			return {
				...ad,
				loading: true,
			};
		}
		case type.error: {
			return {
				...ad,
				error: action.payload,
			};
		}
		case clientType.logout:
			return INITIAL_STATE;
		default:
			return ad;
	}
};
