interface IconProps {
	className?: string;
	slot?: string;
}

const ClientIcon = (props: IconProps) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 327.5 327.5' {...props}>
		<g id='Grupo_2288' data-name='Grupo 2288' transform='translate(-721 -721)'>
			<path
				id='Trazado_1915'
				data-name='Trazado 1915'
				d='M1048.5,884.75A163.75,163.75,0,1,1,884.75,721,163.322,163.322,0,0,1,1048.5,884.75Z'
			/>
			<path
				id='Trazado_1916'
				data-name='Trazado 1916'
				d='M1359.343,2469.295a163.753,163.753,0,0,1-220.51,0c16.13-33.94,42.269-58.928,73.612-69.942.17.093.339.187.509.279q3.178,1.764,6.5,3.212a74.326,74.326,0,0,0,59.269,0q3.315-1.44,6.5-3.212c.171-.092.34-.186.51-.279C1317.074,2410.367,1343.213,2435.355,1359.343,2469.295Z'
				transform='translate(-364.338 -1463.474)'
				fill='#f4f4f4'
			/>
			<circle
				id='Elipse_234'
				data-name='Elipse 234'
				cx='60.78'
				cy='60.78'
				r='60.78'
				transform='translate(823.97 808.554)'
				fill='#f4f4f4'
			/>
		</g>
	</svg>
);

export default ClientIcon;
