import { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// models
import { StateModel } from '../core/models/state.model';

// components
import NavLink from './NavLink';
import ClientNav, { ClientNavHeader } from './Client/NavClient';

// assets
import logoIcon from '../assets/icons/logo.png';
import promosIcon from '../assets/icons/promos.png';
import sixIcon from '../assets/icons/six.png';
import benefitsIcon from '../assets/icons/beneficios.png';
import cuponsIcon from '../assets/icons/coupons.png';
import Notification from './Client/Notification';

const links = [
	{ to: '/promociones', name: 'Promos', icon: promosIcon },
	{ to: '/mapa', name: 'Six', icon: sixIcon },
];

const linksAuth = [
	{ to: '/promociones', name: 'Promos', icon: promosIcon },
	{ to: '/cupones', name: 'Cupones', icon: cuponsIcon },
	{ to: '/beneficios', name: 'Beneficios', icon: benefitsIcon },
	{ to: '/mapa', name: 'Six', icon: sixIcon },
];

interface NavbarProps {
	isAuth: boolean;
}

function Navbar({ isAuth }: NavbarProps) {
	const [openMenu, setOpenMenu] = useState(false);
	const [openNotification, setOpenNotification] = useState(false);

	const toggleMenu = () => {
		if (window.innerWidth < 1024) {
			setOpenMenu(!openMenu);
			if (!openMenu) {
				document.body.classList.add('overflow-hidden');
			} else {
				document.body.classList.remove('overflow-hidden');
			}
			if (openNotification) {
				setOpenNotification(false);
			}
		}
	};

	const toggleNotification = () => {
		setOpenNotification(!openNotification);
		if (!openNotification) {
			document.body.classList.add('overflow-hidden');
		} else {
			document.body.classList.remove('overflow-hidden');
		}
	};

	return (
		<nav className='nav'>
			<Link
				to={isAuth ? '/' : '/bienvenido'}
				className='max-w-fit block ml-5'
				onClick={() => {
					if (openMenu) {
						toggleMenu();
					}
					if (openNotification) {
						toggleNotification();
					}
				}}>
				<img
					src={logoIcon}
					alt='Logo'
					className='max-h-16 w-auto lg:w-24 lg:max-h-none'
				/>
			</Link>
			{isAuth && (
				<>
					<section
						className={`flex max-h-16 items-center justify-center transition pb-2 absolute bottom-0 top-0 right-16 lg:hidden ${openMenu ? 'opacity-0' : 'opacity-100'}`}>
						<ClientNavHeader
							onClick={toggleMenu}
							toogleNotification={toggleNotification}
							isNotificationOpen={openNotification}
							isHeader
						/>
					</section>
					<Notification open={openNotification} />
				</>
			)}
			<button
				className='w-fit text-3xl mr-2 font-bold absolute top-0 bottom-0 right-4 transition outline-none text-primary lg:hidden'
				onClick={toggleMenu}>
				{openMenu ? 'X' : '☰'}
			</button>
			<section
				className={`items-center lg:w-full lg:flex lg:flex-1 ${!isAuth ? 'lg:justify-end' : 'justify-around'} ${openMenu ? 'h-screen bg-white lg:h-auto' : ''}`}>
				<ul className={`nav-menu ${openMenu ? '!max-h-[34.5rem]' : ''}`}>
					{(isAuth ? linksAuth : links).map((link, index) => (
						<NavLink
							key={link.to + index}
							to={link.to}
							name={link.name}
							icon={link.icon}
							onClick={toggleMenu}
							isNotificationOpen={openNotification}
							toogleNotification={toggleNotification}
						/>
					))}
				</ul>
				{isAuth && (
					<ul className={`nav-menu ${openMenu ? '!max-h-fit' : ''}`}>
						<ClientNav
							onClick={toggleMenu}
							toogleNotification={toggleNotification}
							isNotificationOpen={openNotification}
						/>
					</ul>
				)}
			</section>
		</nav>
	);
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
	isAuth: !!(
		state.client.token &&
		state.client.clienteId &&
		!state.client.claveTemporal
	),
});

export default connect(mapStateToProps)(Navbar);
