const {
	REACT_APP_API_URL,
	REACT_APP_API_VERSION,
	REACT_APP_GOOGLE_MAPS_API_KEY,
} = process.env;

export const API_URL = REACT_APP_API_URL;
export const API_VERSION = REACT_APP_API_VERSION;
export const API_URI = `${API_URL}/${API_VERSION}`;
export const GOOGLE_MAPS_API_KEY = REACT_APP_GOOGLE_MAPS_API_KEY || '';
