interface IconProps {
	className?: string;
	slot?: string;
}

const EyeSlashIcon = (props: IconProps) => (
	<svg
		{...props}
		xmlns='http://www.w3.org/2000/svg'
		width='44.098'
		height='27.87'
		viewBox='0 0 44.098 27.87'>
		<g id='Grupo_2147' data-name='Grupo 2147' transform='translate(0 1.844)'>
			<g
				id='Trazado_1702'
				data-name='Trazado 1702'
				transform='translate(0 0)'
				fill='#fff'>
				<path
					d='M 22.04908180236816 22.26016235351562 C 16.23786163330078 22.26016235351562 10.78229141235352 21.0962028503418 6.687351703643799 18.98270225524902 C 4.723361968994141 17.96904373168945 3.186501741409302 16.79487228393555 2.119451761245728 15.49280261993408 C 1.044861793518066 14.18152332305908 0.5000017881393433 12.79780292510986 0.5000017881393433 11.38008308410645 C 0.5000017881393433 9.962363243103027 1.044861793518066 8.578642845153809 2.119451761245728 7.26736307144165 C 3.186501741409302 5.965292930603027 4.723361968994141 4.791122913360596 6.687351703643799 3.77746319770813 C 10.78229141235352 1.663963079452515 16.23786163330078 0.5000030994415283 22.04908180236816 0.5000030994415283 C 25.89858245849609 0.5000030994415283 29.52124214172363 1.002053141593933 32.81644058227539 1.992213129997253 C 35.99378204345703 2.946953058242798 38.75617218017578 4.351283073425293 40.80500030517578 6.053382873535156 C 42.58077239990234 7.514263153076172 43.59816360473633 9.45521354675293 43.59816360473633 11.38008308410645 C 43.59816360473633 12.79780292510986 43.05330276489258 14.18152332305908 41.97871017456055 15.49280261993408 C 40.91166305541992 16.79487228393555 39.37480163574219 17.96904373168945 37.41081237792969 18.98270225524902 C 33.31587219238281 21.0962028503418 27.86030197143555 22.26016235351562 22.04908180236816 22.26016235351562 Z'
					stroke='none'
					className='stroke-primary'
				/>
				<path
					d='M 22.04908180236816 1.000003814697266 C 16.316162109375 1.000003814697266 10.94203186035156 2.144182205200195 6.916671752929688 4.221773147583008 C 5.013641357421875 5.203973770141602 3.529743194580078 6.335283279418945 2.506183624267578 7.584293365478516 C 1.506752014160156 8.803853034973145 1 10.08094310760498 1 11.38008308410645 C 1 12.67922306060791 1.506752014160156 13.95631313323975 2.506183624267578 15.17587280273438 C 3.529743194580078 16.42488288879395 5.013641357421875 17.55619239807129 6.916671752929688 18.53839302062988 C 10.94203186035156 20.61598205566406 16.316162109375 21.76016235351562 22.04908180236816 21.76016235351562 C 27.78200149536133 21.76016235351562 33.15613174438477 20.61598205566406 37.18149185180664 18.53839302062988 C 39.08452224731445 17.55619239807129 40.56842041015625 16.42488288879395 41.59197998046875 15.17587280273438 C 42.59141159057617 13.95631313323975 43.09816360473633 12.67922306060791 43.09816360473633 11.38008308410645 C 43.09816360473633 9.605273246765137 42.14723205566406 7.805063247680664 40.48549270629883 6.437982559204102 C 38.48784255981445 4.778392791748047 35.78617095947266 3.406652450561523 32.67256164550781 2.471063613891602 C 29.4240608215332 1.494943618774414 25.84981155395508 1.000003814697266 22.04908180236816 1.000003814697266 M 22.04908180236816 3.814697265625e-06 C 29.95219039916992 3.814697265625e-06 36.88416290283203 2.146032333374023 41.12451171875 5.668792724609375 C 42.88196182250977 7.114593505859375 44.09816360473633 9.174033164978027 44.09816360473633 11.38008308410645 C 44.09816360473633 17.66513252258301 34.2264518737793 22.76016235351562 22.04908180236816 22.76016235351562 C 9.871711730957031 22.76016235351562 0 17.66513252258301 0 11.38008308410645 C 0 5.095033645629883 9.871711730957031 3.814697265625e-06 22.04908180236816 3.814697265625e-06 Z'
					stroke='none'
					className='stroke-primary'
				/>
			</g>
			<g
				id='Trazado_15'
				data-name='Trazado 15'
				transform='translate(15.648 4.268)'>
				<path
					d='M 7.112607479095459 13.72510242462158 C 3.466397523880005 13.72510242462158 0.499997466802597 10.75872230529785 0.499997466802597 7.112552642822266 C 0.499997466802597 3.466382741928101 3.466397523880005 0.5000026226043701 7.112607479095459 0.5000026226043701 C 10.75881767272949 0.5000026226043701 13.72521781921387 3.466382741928101 13.72521781921387 7.112552642822266 C 13.72521781921387 10.75872230529785 10.75881767272949 13.72510242462158 7.112607479095459 13.72510242462158 Z'
					stroke='none'
				/>
				<path
					d='M 7.112607479095459 1.000002861022949 C 3.742107391357422 1.000002861022949 0.9999971389770508 3.742082595825195 0.9999971389770508 7.112552642822266 C 0.9999971389770508 10.48302268981934 3.742107391357422 13.22510242462158 7.112607479095459 13.22510242462158 C 10.4831075668335 13.22510242462158 13.22521781921387 10.48302268981934 13.22521781921387 7.112552642822266 C 13.22521781921387 3.742082595825195 10.4831075668335 1.000002861022949 7.112607479095459 1.000002861022949 M 7.112607479095459 2.86102294921875e-06 C 11.04078769683838 2.86102294921875e-06 14.22521781921387 3.184392929077148 14.22521781921387 7.112552642822266 C 14.22521781921387 11.04070281982422 11.04078769683838 14.22510242462158 7.112607479095459 14.22510242462158 C 3.184417724609375 14.22510242462158 -2.86102294921875e-06 11.04070281982422 -2.86102294921875e-06 7.112552642822266 C -2.86102294921875e-06 3.184392929077148 3.184417724609375 2.86102294921875e-06 7.112607479095459 2.86102294921875e-06 Z'
					stroke='none'
				/>
			</g>
			<path
				id='Trazado_1658'
				data-name='Trazado 1658'
				d='M0,22.76,36.986,0'
				transform='translate(4.979 0.711)'
				fill='#fff'
				stroke='#fff'
				strokeWidth='6'
			/>
			<path
				id='Trazado_1709'
				data-name='Trazado 1709'
				d='M0,22.76,36.986,0'
				transform='translate(4.979 0.711)'
				strokeWidth='2'
			/>
		</g>
	</svg>
);

export default EyeSlashIcon;
