import { Dispatch } from '@reduxjs/toolkit';

// models
import { ToastModel } from '../../core/models/toast.model';

// types
import type from '../types/toast';

/**
 * Adds a toast to the Redux state by dispatching an action.
 *
 * @param dispatch - The Redux dispatch function.
 * @param toast - The toast component to be added to the state.
 */
export function addToast(dispatch: Dispatch, toast: Partial<ToastModel>) {
	dispatch({
		type: type.addToast,
		payload: toast,
	});
}

/**
 * Removes a toast from the Redux state by dispatching an action.
 *
 * @param dispatch - The Redux dispatch function.
 * @param id - The unique identifier of the toast to be removed.
 */
export function removeToast(dispatch: Dispatch, id: ToastModel['id']) {
	dispatch({
		type: type.removeToast,
		payload: id,
	});
}
