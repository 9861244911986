import { useEffect, useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';

// actions
import { addPromotion } from '../../redux/actions/promotion';

// models
import { StateModel } from '../../core/models/state.model';
import { MapModel } from '../../core/models/map.model';
import { ClientModel } from '../../core/models/client.model';
import { PromotionStateModel } from '../../core/models/promotion.model';

// components
import MainCard from '../../components/Promotion/MainCard';

// utils
import { getCurrentLocation } from '../../utils';

interface PromotionListProps extends DispatchProp {
	map: MapModel;
	promotion: PromotionStateModel;
	client: ClientModel;
}

function PromotionMainList({
	client,
	map,
	promotion,
	dispatch,
}: PromotionListProps) {
	const [isUsed, setIsUsed] = useState<boolean>(false);

	useEffect(() => {
		getCurrentLocation(dispatch, {
			latitud: map.latitud,
			longitud: map.longitud,
		});
	}, []);

	useEffect(() => {
		if (
			map.latitud &&
			map.longitud &&
			promotion.latitud !== map.latitud &&
			promotion.longitud !== map.longitud
		) {
			addPromotion(
				dispatch,
				{
					latitud: map.latitud,
					longitud: map.longitud,
				},
				client,
			);
		}
	}, [map.latitud, map.longitud, client.token, client.clienteId]);

	if (
		map.loading ||
		((!map.latitud || !map.longitud) && map.locationPermission !== 'denied')
	) {
		return (
			<section className='mt-16'>
				<p className='text-primary text-xl font-black italic text-center mb-3'>
					Cargando...
				</p>
				<svg
					className='animate-spin mx-auto h-10 w-10 text-primary'
					xmlns='http://www.w3.org/2000/svg'
					fill='none'
					viewBox='0 0 24 24'>
					<circle
						className='opacity-25'
						cx='12'
						cy='12'
						r='10'
						stroke='currentColor'
						strokeWidth='4'></circle>
					<path
						className='opacity-75'
						fill='currentColor'
						d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
				</svg>
			</section>
		);
	}

	if (!map.loading && map.locationPermission === 'denied') {
		return (
			<section className='mt-16'>
				<p className='text-primary text-xl font-black italic text-center mb-3'>
					Activa tu ubicación para localizar tus <br /> Six cercanos
				</p>
			</section>
		);
	}

	return (
		<section className=''>
			<section className='flex px-8 justify-center mt-5 mb-8 w-full'>
				<button
					className={`btn-primary mx-4 border border-primary w-36 text-sm ${isUsed ? '!bg-white !text-primary' : ''}`}
					onClick={() => {
						setIsUsed(false);
					}}>
					Disponibles
				</button>
				<button
					className={`btn-primary mx-4 border border-primary w-36 text-sm ${!isUsed ? '!bg-white !text-primary' : ''}`}
					onClick={() => {
						setIsUsed(true);
					}}>
					Utilizados
				</button>
			</section>
			<Swiper
				slidesPerView={2.2}
				spaceBetween={20}
				breakpoints={{
					768: {
						slidesPerView: 3.5,
					},
					1024: {
						slidesPerView: 4.2,
					},
					1280: {
						slidesPerView: 7.2,
						spaceBetween: 25,
					},
				}}
				className='!ml-6 xl:ml-0'>
				{promotion.mainPromotion.promociones.map(item => (
					<SwiperSlide key={item.idPromo}>
						<MainCard promotion={item} />
					</SwiperSlide>
				))}
			</Swiper>
		</section>
	);
}

const mapStateToProps = (state: StateModel) => ({
	promotion: state.promotion,
	map: state.map,
	client: state.client,
});

export default connect(mapStateToProps)(PromotionMainList);
