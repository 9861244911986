import { useEffect } from 'react';
import { connect } from 'react-redux';

// models
import { StateModel } from '../core/models/state.model';
import { ClientModel } from '../core/models/client.model';

// componets
import NavLeft from '../components/NavLeft';

// utils
import { logPageView } from '../utils/firebaseConfig';

interface TermsConditionsProps {
	client: ClientModel;
}

function TermsConditions({ client }: TermsConditionsProps) {
	useEffect(() => {
		logPageView('Terms and Conditions', client);
	}, []);

	return (
		<>
			<NavLeft name='Terminos y Condiciones' />
			<section className='sumasix-terms mt-8'>
				<h1 className='text-center title-page-terms'>TÉRMINOS DEL PROGRAMA</h1>
				<br />
				<p>Aplicabilidad</p>
				<p>
					Estos términos y condiciones de uso (en adelante, &quot;Términos de
					Uso&quot;) se aplican a todos los visitantes y a todos los usuarios de
					este sitio web www.tiendasix.com.mx (en adelante, “Sitio Web”), de
					Controladora de Negocios Comerciales S.A. de C.V., sus empresas
					filiales y subsidiarias (en adelante, &quot;CNC&quot;), así como toda
					la información, recomendaciones y servicios que se proporcionan a
					través de este Sitio Web (la &quot;Información&quot;).
				</p>
				<p>
					Al usar este Sitio Web está aceptando la validez de estos Términos de
					Uso. Estos Términos de Uso pueden modificarse unilateralmente, en
					cualquier momento y sin previo aviso a los usuarios. Tales
					modificaciones entrarán en vigor en cuanto se publiquen los nuevos
					Términos de Uso. Se aconseja a los usuarios del sitio que lean con
					regularidad los Términos de Uso, para que estén al tanto de cualquier
					posible cambio.
				</p>
				<br />
				<p>
					POLÍTICA DE UGC (USER GENERATED CONTENT POLICY) Y FAN (FORWARD ADVICE
					NOTICE)
				</p>
				<br />
				<p>
					Como una de las principales productoras mundiales de cerveza,
					promovemos el consumo de cerveza con moderación, como parte de un
					estilo de vida saludable y equilibrada.
				</p>
				<p>
					Comercializamos y vendemos nuestras marcas de forma responsable y
					trabajamos en diversas asociaciones para reducir los daños
					relacionados con el alcohol. Somos orgullosos embajadores de nuestro
					negocio, sentimos pasión por nuestras marcas y por su consumo con
					moderación.
				</p>
				<p>
					En todas nuestras comunicaciones, incluidas las redes sociales, ser
					embajadores responsables significa que cada uno de nosotros se
					comporta de forma transparente, veraz y prudente.
				</p>
				<p>
					Alentamos activamente tus opiniones y comentarios en nuestras
					plataformas sociales, así como la publicación de videos, fotos y
					publicaciones en el muro que sean apropiados; Sin embargo, las
					publicaciones NO deberán:
				</p>
				<ul style={{ fontSize: '20px', paddingLeft: '25px' }}>
					<li>
						<p>
							Promover el consumo excesivo o irresponsable de bebidas
							alcohólicas.
						</p>
					</li>
					<li>
						<p>
							Ser obscenas u ofensivas, desagradables, vulgares, abusivas,
							amenazantes, acosadoras, sexistas o racistas.
						</p>
					</li>
					<li>
						<p>
							Violar los derechos de propiedad intelectual o de privacidad de
							otras personas o empresas.
						</p>
					</li>
					<li>
						<p>
							Promover o incitar conductas violentas, peligrosas o antisociales.
						</p>
					</li>
					<li>
						<p>
							Violar cualquier ley aplicable o cualquier otra regulación, guía o
							norma comunitaria.
						</p>
					</li>
					<li>
						<p>Contener spam o causar complicaciones técnicas a esta página.</p>
					</li>
				</ul>
				<p>
					Nuestras redes sociales están catalogadas como foros abiertos al
					público, por lo mismo hay que tratar con respeto a todos los que la
					integran; trata de evitar comentarios vulgares, racistas, sexistas y/o
					religiosos, nos reservamos el derecho de bloquear a las personas que
					hagan este tipo de comentarios.
				</p>
				<p>
					El contenido de nuestras plataformas sociales es exclusivo para
					mayores de 18 años y residentes en la República Mexicana. Sé
					responsable, no compartas nuestras publicaciones con menores de edad.
				</p>
				<p>
					HEINEKEN México a través de CCM y sus marcas se reservan el derecho de
					eliminar cualquier material a su completa discreción y sin aviso
					previo que no cumpla con sus estándares, no sea apropiado, elegible o
					no cumpla con las reglas de la casa; Asimismo no somos responsables de
					cualquier fallo o retraso en la eliminación de dicho material.
				</p>
				<p>
					HEINEKEN México a través de CCM ky sus marcas no avalan las opiniones
					que no son emitidas por la propia empresa en sus plataformas sociales.
					Sin embargo, incentivamos que las comunidades compartan sus contenidos
					y expresen sus opiniones. Aun así, no tenemos la obligación de evaluar
					previamente cualquier material que es publicado por los usuarios de
					nuestras redes sociales.
				</p>
				<br />
				<br />
				<p>
					TÉRMINOS Y CONDICIONES DEL PROGRAMA SUMA SIX (en lo sucesivo los
					“Términos y Condiciones”)
				</p>
				<p>
					El presente documento, constituye un acuerdo de voluntades, entre
					Controladora de Negocios Comerciales, S.A. de C.V., (en lo sucesivo
					“SIX”) y las personas mayores de edad (i) que cumplan con todos los
					requisitos señalados en los presentes Términos y Condiciones y (ii)
					que decidan participar en el programa de lealtad/recompensas
					denominado “Suma Six”, a quienes en lo sucesivo se les denominará de
					manera conjunta como los “Clientes” o en lo individual como el
					“Cliente”. A fin de que los Clientes puedan participar en este
					programa, SIX les entregará una tarjeta (en lo sucesivo denominada
					“Tarjeta Suma Six”, y el uso de la misma representa la total
					aceptación por parte del Cliente a los presentes los Términos y
					Condiciones bajo los cuales se rige el Programa Suma Six.
				</p>
				<p>I. BASES DEL PROGRAMA</p>
				<p>1.REQUISITOS PARA PARTICIPAR</p>
				<p>
					Los Clientes que deseen participar en Suma Six deberán reunir los
					siguientes requisitos:
				</p>
				<p>
					Ser residente de forma legal en México. Tener al menos 18 años de edad
					cumplidos al momento de su registro y tener además un modo honesto de
					vivir comprobable. No contar o laborar para algún establecimiento
					dedicado a la venta de bebidas alcohólicas. Este programa está
					destinado a clientes minoristas de la cadena SIX por lo que quedan
					excluidos: comerciantes y sus familiares, mayoristas y revendedores.
					En caso de sospecha, SIX se reserva el derecho de bloquear temporal o
					definitivamente las cuentas de los Clientes que hayan obtenido su
					Tarjeta Suma Six. Acudir a alguna de las tiendas SIX participantes a
					fin de que le sea proporcionada la Tarjeta Suma Six. Proporcionar
					información real y comprobable al momento de su registro, entre la que
					se encuentra, de manera enunciativa y no limitativa: nombre completo,
					fecha de nacimiento, domicilio, celular, entre otros, lo anterior en
					el entendido que dicha información se encuentra protegida de
					conformidad con la Ley Federal de Protección de Datos Personales.
				</p>
				<p>
					Descargar en su celular la aplicación denominada “Suma Six” a fin de
					que puedan revisar constantemente los presentes Términos y
					Condiciones, por si llegasen a sufrir algún cambio los mismos. No
					tener más de una Tarjeta Suma Six. En caso que SIX detecte que alguno
					de los Clientes que ya cuente con una tarjeta solicita una nueva, SIX
					podrá
				</p>
				<p>
					(i) eliminará la tarjeta anterior, perdiendo el Cliente todos los
					puntos que haya acumulado en dicha tarjeta, o
				</p>
				<p>
					(ii) negar la participación en Suma Six, por considerar que es una
					actividad desleal.
				</p>
				<p>
					2.PRODUCTOS PARTICIPANTES. Los productos que participan en el programa
					Suma SIX, y que generan recompensas a favor de los Clientes, son todos
					los productos de las marcas que actualmente comercializa y/o
					distribuye, o que llegue a distribuir y/o comercializar en un futuro
					la sociedad denominada Cervezas Cuauhtémoc Moctezuma, S.A. de C.V.,
					entre los que se encuentran, las cervezas, sidras, y bebidas tipo
					seltzer, en todas sus presentaciones, en lo sucesivo de manera
					conjunta como los “Productos HMEX”.
				</p>
				<p>
					3. ACUMULACIÓN DE SIXTOS. El cliente podrá obtener su tarjeta Suma SIX
					en cualquiera de las tiendas participantes. Para registrarla, el
					cliente deberá proporcionar sus datos, incluyendo un número celular,
					al comerciante. Una vez inscrito a Suma Six, al momento de realizar la
					compra de los Productos HMEX (en lo sucesivo la “Transacción” o
					“Transacciones”), los Clientes deberán presentar, al responsable de la
					tienda participante, su Tarjeta Suma Six, para ser acreedor a la
					acumulación de puntos (“en lo sucesivo “Sixtos”) en los términos del
					presente documento. Los Sixtos son personales, intransferibles, y no
					podrán ser cambiados por dinero en efectivo. Por cada $1.00 m.n. (Un
					peso 00/100 Moneda Nacional) de compra que realice el Cliente en
					Productos HMEX en las tiendas SIX participantes, SIX le otorgará al
					Cliente 5 (cinco) Sixtos los cuales serán acumulados en la Tarjeta
					Suma Six del Cliente, de conformidad con los presentes Términos y
					Condiciones. Cada 100 (cien) Sixtos que acumule el Cliente equivaldrá
					a $1.00 m.n. (un peso 00/100 moneda nacional). A fin de evitar un mal
					uso de la Tarjeta Suma Six y promover un consumo responsable por parte
					de los Clientes, SIX establece las siguientes consideraciones y/o
					limitaciones para la acumulación de Sixtos: El Cliente podrá realizar
					un máximo de 10 (diez) transacciones al día, en caso de que el Cliente
					realice más de 10 (diez) transacciones al día, SIX tendrá el derecho
					de bloquear hasta por 24 (veinticuatro) horas la Tarjeta Suma Six,
					tiempo en el cual no se acumularán Sixtos, ni podrán ser redimidos los
					mismos. El Cliente podrá adquirir, para que le generen Sixtos, hasta
					un máximo de 150 unidades de 355 ml cada una, o su presentación
					equivalente, por lo que en caso que el Cliente utilice su Tarjeta Suma
					Six para adquirir más de esa cantidad, las unidades excedentes no le
					generarán Sixtos, y SIX tendrá el derecho de bloquear hasta por 24
					(veinticuatro) horas la Tarjeta Suma Six, tiempo en el cual no se
					acumularán Sixtos, ni podrán ser redimidos los mismos. En caso de que
					el Cliente acumule 3 (tres) o más bloqueos de Tarjeta Suma Six en un
					término de 30 (Treinta) días calendario, derivados de un mal uso de
					dicha tarjeta, SIX tendrá el derecho a cancelar de manera definitiva
					dicha tarjeta y dar de baja al Cliente de Suma Six. En caso que
					derivado de las transacciones que realice el Cliente, este acumule en
					su Tarjeta Suma Six más de 21,886 Sixtos al mes calendario, se
					considerará como un consumo excesivo y SIX tendrá el derecho de
					bloquear hasta por 30 (treinta) días calendario la Tarjeta Suma Six,
					tiempo en el cual no se acumularán Sixtos, ni podrán ser redimidos los
					mismos.
				</p>
				<p>
					4. REDENCIÓN DE SIXTOS. Los Sixtos acumulados por el Cliente en su
					Tarjeta Suma Six pueden ser utilizados para adquirir únicamente
					Productos HMEX en cualquiera de las tiendas SIX participantes. Los
					Productos se deberán pagar en su totalidad con Sixtos, no se permite
					realizar un pago mixto de puntos y otra con dinero. Es obligatorio
					presentar la Tarjeta Suma Six para la redención o canje de Productos
					HMEX. Al finalizar la compra, el Cliente recibirá un ticket con su
					saldo total actualizado. Las compras del Cliente que sean pagadas con
					Sixtos no generarán puntos a acumular. En caso de que el Cliente
					intente canjear una promoción más veces de las establecidas o
					asignadas para determinada promoción, SIX tendrá el derecho de
					bloquear hasta por 24 (veinticuatro) horas la Tarjeta Suma Six, tiempo
					en el cual no se acumularán Sixtos, ni podrán ser redimidos los
					mismos.
				</p>
				<p>
					5. VALIDACIÓN DE DATOS Y BONO DE BIENVENIDA El Cliente recibirá un SMS
					de parte de Suma Six, el cual deberá responder desde su número celular
					registrado en un periodo máximo de 10 (diez) días posteriores a su
					registro para validar que sus datos son correctos. En caso de que
					transcurran el plazo antes señalado, sin que el Cliente haya dado
					respuesta el SMS, el Cliente podrá seguir utilizando su Tarjeta Suma
					Six y acumular y no perderá su saldo, mas no podrá canjear hasta que
					se haya validado toda su información, debiendo responder el mensaje o
					bien contactar a SIX para aclarar este tema. Al momento de la
					validación de la información, el Cliente recibe un bono equivalente a
					una lata de cerveza de 355 ml de la marca de su preferencia limitada a
					disponibilidad en tienda.
				</p>
				<p>
					6. CONDICIONES Y VIGENCIA Los Sixtos acumulados no podrán canjearse
					por dinero en efectivo, o por productos distintos a los Productos
					HMEX. En el caso de devoluciones se descontarán los Sixtos acumulados
					en esa transacción al saldo total del Cliente participante. Los Sixtos
					tendrán una vigencia del año calendario, es decir, del 1° (Primero) de
					enero al 31 (Treinta y uno) de diciembre, por lo que en caso de que
					los Clientes no los redimen en el mismo año en que los acumularon, los
					Sixtos se cancelarán sin responsabilidad alguna para SIX. Salvo que de
					manera discrecional SIX emita algún comunicado extendiendo la vigencia
					de los mismos. En caso que algún Cliente que no haya hecho ni una sola
					Transacción en 6 (seis) meses se considerará como Cliente inactivo y
					por consecuencia, SIX tendrá el derecho a cancelar los Sixtos
					acumulados en ese año en su Tarjeta Suma Six.
				</p>
				<p>
					7. REPOSICIÓN DE TARJETAS En caso de pérdida o daño de la Tarjeta Suma
					Six, el Cliente puede contactar al callcenter de SIX. El saldo en
					puntos de la tarjeta sólo será protegido desde el momento de la
					notificación. El saldo de la cuenta se traspasará a una nueva tarjeta,
					la cual se puede obtener en cualquier de las tiendas participantes.
				</p>
				<p>
					8. CANALES DE COMUNICACIÓN Toda la información referente a la cuenta,
					saldo y promociones vigente, estará disponible en la página
					www.sumasix.com. Además, ponemos a tu disposición el callcenter 01800-
					SUMA -777 (01800-7862-777) en un horario de 9 a.m. a 9 p.m. Si se
					desea enviar un correo, lo pueden hacer a la dirección
					info@sumasix.com
				</p>
				<p>
					II. CONDICIONES GENERALES. SIX se reserva el derecho de modificar las
					bases del programa Suma Six, los beneficios, la lista de productos
					participantes, o de suspender el programa sin previa notificación. SIX
					se reserva el derecho de canje de Sixtos en caso de sospecha de
					falsificación o derivada del mal uso de la misma. Por limitaciones
					tecnológicas, SIX no garantiza que las Tarjetas Suma Six tendrán
					siempre acceso o serán siempre aceptadas.
				</p>
				<p>
					1. Aplicabilidad Estos términos y condiciones de uso (en adelante,
					“Términos de Uso”) se aplican a todos los visitantes y a todos los
					usuarios de este sitio web www.sumasix.com (en adelante, “Sitio Web”),
					de SIX, sus empresas filiales y subsidiarias (en adelante, “SIX”), así
					como toda la información, recomendaciones y servicios que se
					proporcionan a través de este Sitio Web (la “Información”). Al usar
					este Sitio Web está aceptando la validez de estos Términos de Uso.
					Estos Términos de Uso pueden modificarse unilateralmente, en cualquier
					momento y sin previo aviso a los usuarios. Tales modificaciones
					entrarán en vigor en cuanto se publiquen los nuevos Términos de Uso.
					Se aconseja a los usuarios del sitio que lean con regularidad los
					Términos de Uso, para que estén al tanto de cualquier posible cambio.
				</p>
				<p>
					2. Información y Responsabilidad La Información es sólo para
					propósitos generales y no constituye una recomendación. SIX no será
					responsable de ningún daño resultante del uso (o imposibilidad de
					hacer uso) de este Sitio Web e Información, incluyendo daños causados
					por virus o cualquier incorrección o falta de integridad de la
					Información, a menos que tales daños sean resultado de cualquier mala
					conducta deliberada o negligencia grave por parte de SIX. SIX no será
					responsable de los daños resultantes por la falta de adecuación,
					oportunidad o precisión de este Sitio Web o de la Información. Los
					usuarios se comprometen a utilizar el Sitio Web y la Información de
					conformidad con la ley, estos Términos de Uso, y cualquier otra
					política publicada por en el Sitio Web de SIX, así como de acuerdo con
					la moral, las buenas costumbres y el orden público. Los usuarios se
					obligan a abstenerse de utilizar el Sitio Web y/o la Información con
					fines o efectos ilícitos, contrarios a lo establecido en los Términos
					de Uso, lesivos de los derechos e intereses de terceros o que de
					cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar el
					Sitio Web y/o la Información o impedir la normal utilización o
					disfrute del Sitio Web y/o la Información por parte de los usuarios.
					Está prohibido el uso de cualquier dispositivo, software, u otro medio
					tendiente a interferir tanto en las actividades y operaciones de SIX
					como en el Sitio Web, Información o en las bases de datos de SIX.
					Cualquier intromisión, tentativa o actividad violatoria y/o a las
					prohibiciones estipuladas en estos Términos de Uso harán a su
					responsable sujeto de las acciones legales pertinentes, y a las
					sanciones previstas por este acuerdo, así como responsable de
					indemnizar por los daños ocasionados.
				</p>
				<p>
					3. Exclusión de responsabilidad por fallas en el sistema SIX no se
					responsabiliza por ningún daño, perjuicio o pérdida al usuario causada
					por fallas en el sistema, en el servidor o en internet. SIX tampoco
					será responsable por cualquier virus que pudiera infectar el equipo
					del usuario como consecuencia del acceso, uso o examen de su Sitio Web
					o a raíz de cualquier transferencia de datos, archivos, imágenes,
					textos, o audio contenidos en el mismo. Los usuarios no podrán
					imputarle responsabilidad alguna, en virtud de perjuicios resultantes
					de dificultades técnicas o fallas en los sistemas o en internet. SIX
					no garantiza el acceso y uso continuado o ininterrumpido de su Sitio
					Web. El sistema puede eventualmente no estar disponible, debido a
					dificultades técnicas o fallas de internet, o por cualquier otra
					circunstancia ajena a SIX; en tales casos se procurará restablecerlo
					con la mayor celeridad posible sin que por ello pueda imputarse algún
					tipo de responsabilidad. SIX no será responsable por ningún error u
					omisión contenidos en su Sitio Web. SIX niega cualquier garantía
					incluyendo, entre otras, las garantías de condición, calidad,
					mercantibilidad, adecuación para un propósito determinado y no
					infracción de derechos.
				</p>
				<p>
					4. Información de Terceros La información procedente de terceros
					constituye una expresión de las opiniones personales de dichos
					terceros. SIX no se hace responsable de dicha información. Los
					hipervínculos de este Sitio Web pueden dirigir a los visitantes a
					sitios web externos mantenidos por terceros. SIX no se hace
					responsable por el contenido y el funcionamiento de tales sitios web
					externos. SIX tampoco será responsable de la calidad de los productos
					o servicios que puedan ofrecerse en tales sitios web externos.
				</p>
				<p>
					5. Protección de Datos Personales SIX ha adoptado los niveles de
					seguridad de protección de los Datos Personales requeridos, y procura
					instalar aquellos otros medios y medidas técnicas adicionales a su
					alcance para evitar la pérdida, mal uso, alteración, acceso no
					autorizado y robo de los Datos Personales facilitados a SIX. Para lo
					anterior y mejor conocimiento de los usuarios, está disponible el
					Aviso de Privacidad.
				</p>
				<p>
					6. Tecnología “Cookie” en el Sitio Web El Sitio Web de SIX hace uso de
					las denominadas “cookies”. Las “cookies” son pequeños archivos de
					texto que el Sitio Web almacena localmente en el equipo. El Sitio Web
					utiliza “cookies” para reconocerle si lo visita, permite que SIX
					reconozca al usuario registrado después de que éstos se hayan
					registrado por primera vez, sin que se tengan que registrar en cada
					visita para acceder a las áreas y servicios reservados exclusivamente
					a ellos. De esta manera, SIX es capaz de mejorar la facilidad de uso
					del Sitio Web y de ajustarlo a sus necesidades. Las “cookies” del
					Sitio Web que se almacenan en el equipo son válidas por un período
					ilimitado después de su primera visita. Al cambiar la configuración de
					su navegador Web, puede establecer que se le dé aviso si se almacenan
					“cookies” en su computadora. Al cambiar la configuración, también
					puede establecer que su navegador no acepte “cookies” de este Sitio
					Web. Sin embargo, si su navegador no acepta “cookies”, no podrá
					acceder o utilizar todas las características de este Sitio Web.
				</p>
				<p>
					7. Propiedad Intelectual A menos que se indique lo contrario, todos
					los derechos de propiedad intelectual de este Sitio Web y la
					Información contenida en él son propiedad de S IX. Estos derechos
					incluyen, entre otros, los programas, bases de datos, redes, archivos,
					derechos de autor, los derechos de los nombres comerciales, marcas,
					marcas comerciales, diseños y logotipos que son propiedad de SIX y
					están protegidas por las leyes de propiedad intelectual. SIX no
					concede licencia o autorización alguna de uso de ninguna clase sobre
					sus derechos de propiedad industrial e intelectual, o sobre cualquier
					otra propiedad o derecho relacionado con el Sitio Web y/o la
					Información. Los usuarios están autorizados a leer este Sitio Web y la
					Información y hacer copias para su uso personal, por ejemplo, imprimir
					o guardar. Cualquier otro uso del Sitio Web o de la Información, como
					el almacenamiento o reproducción de (parte de) el Sitio Web o de la
					Información de SIX, en cualquier sitio de Internet externo está
					prohibido. Dicho uso indebido y reproducción total o parcial de dichos
					contenidos será objeto de las acciones legales pertinentes.
				</p>
				<p>
					8. Ideas No Solicitadas En caso de que el usuario publique ideas no
					solicitadas o materiales ya sea en forma de textos, imágenes, sonidos,
					software, información o de otro tipo (en adelante, los “Materiales”)
					en este Sitio Web o envíe materiales a SIX por correo electrónico o de
					otra manera, SIX tendrá derecho para usar, copiar o explotar
					comercialmente dichos Materiales en la mayor medida y de forma
					gratuita. SIX no estará sujeto a ninguna obligación de
					confidencialidad respecto de tal Material. Por la presente, el usuario
					libera de toda responsabilidad a SIX en caso de cualquier acción,
					reclamo o responsabilidad, sufrida, incurrida o que afecte a SIX, como
					resultado del uso o la explotación de Materiales que infrinjan los
					derechos (de propiedad intelectual) de terceros o que de otra manera
					sean ilícitos y afecten a un tercero.
				</p>
				<p>
					9. Divisibilidad Si estos Términos de Uso se anulan parcialmente, las
					partes seguirán estando vinculadas por el resto de los mismos. Las
					partes deberán sustituir la parte sin efecto por disposiciones que
					sean válidas, tengan efectos jurídicos y correspondan con las de la
					parte nula, en la medida de lo posible, teniendo en cuenta el
					contenido y el significado de estos Términos de Uso.
				</p>
				<p>
					10. Ley Aplicable y Jurisdicción Estos Términos de Uso se regirán
					exclusivamente por las leyes aplicables de México. Todas las
					controversias que surjan en relación con estos Términos de Uso
					incluyendo, entre otras, las controversias sobre la existencia y
					validez de los mismos, se resolverán en los tribunales competentes de
					la ciudad de Monterrey, Nuevo León, México.
				</p>
				<button
					className='btn-primary mx-auto mt-8 !px-8'
					onClick={() => {
						window.scrollTo(0, 0);
					}}>
					Ir arriba
				</button>
			</section>
		</>
	);
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(TermsConditions);
