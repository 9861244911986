import dayjs from 'dayjs';

// models
import { PromotionModel } from '../../core/models/promotion.model';

import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

interface PromotionMainCardProps {
	promotion: PromotionModel;
}

export default function PromotionMainCard({
	promotion,
}: PromotionMainCardProps) {
	return (
		<section className='relative pt-3 mb-2'>
			<section
				className='absolute left-1/2 translate-x-[-50%] top-0 text-nowrap rounded-lg py-1 w-10/12 text-center'
				style={{ backgroundColor: promotion.color_app }}>
				<span className='font-bold text-white text-sm'>3 DISPONIBLES</span>
			</section>
			<section className='card min-w-[170px]'>
				<img
					src={promotion.imagen_app_card}
					className='w-32 h-16 mx-auto my-5'
				/>
				<p className='text-sm font-bold text-center mx-3'>
					{promotion.nombrePromocion}
				</p>
				<p className='text-xs text-red-app text-center mt-5'>
					Valido en SIX Participantes
				</p>
				<p className='text-xs text-center mb-2'>
					Vigencia {dayjs(promotion.vigencia, 'DD/MM/YYYY').format('DD MMMM')}
				</p>
			</section>
		</section>
	);
}
