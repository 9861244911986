import { Dispatch } from '@reduxjs/toolkit';

// services
import {
	getNearsPromotionsApi,
	getPromotionsCarouselApi,
} from '../../core/services/promotion.service';

// models
import { LocationModel } from '../../core/models/map.model';
import { ClientModel } from '../../core/models/client.model';
import {
	PromotionStateModel,
	PromotionsModel,
} from '../../core/models/promotion.model';

// types
import type from '../types/promotion';

// utils
import { parseError } from '../../utils';

/**
 * Retrieves and adds promotions to the Redux state for a specific location and client.
 *
 * @param {Dispatch} dispatch - The Redux dispatch function.
 * @param {LocationModel} location - The location for which promotions are to be fetched.
 * @param {ClientModel} client - The client information, including the authentication token.
 */
export function addPromotion(
	dispatch: Dispatch,
	location: LocationModel,
	client: ClientModel,
) {
	dispatch({
		type: type.loading,
	});
	if (client.token) {
		getNearsPromotionsApi(dispatch, {
			...location,
			radio: 5,
			clienteId: client.clienteId,
		})
			.then((promotions: PromotionsModel) => {
				dispatch({
					type: type.addPromotion,
					payload: {
						mainPromotion: promotions,
						...location,
					},
				});
			})
			.catch((error: string | object) => {
				dispatch({
					type: type.error,
					payload: parseError(error),
				});
			});

		getPromotionsCarouselApi(dispatch, {
			...location,
			clienteId: client.clienteId,
			radio: 5,
		})
			.then((promotions: PromotionStateModel['carouselPromotions']) => {
				dispatch({
					type: type.addPromotion,
					payload: {
						carouselPromotions: promotions,
					},
				});
			})
			.catch((error: string | object) => {
				dispatch({
					type: type.error,
					payload: parseError(error),
				});
			});
	}
}
