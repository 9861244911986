interface IconProps {
	className?: string;
	slot?: string;
}

const EyeIcon = (props: IconProps) => (
	<svg
		{...props}
		id='Grupo_2148'
		data-name='Grupo 2148'
		xmlns='http://www.w3.org/2000/svg'
		width='44.098'
		height='22.76'
		viewBox='0 0 44.098 22.76'>
		<g
			id='Elipse_5'
			data-name='Elipse 5'
			transform='translate(0 0)'
			strokeWidth='1'>
			<ellipse cx='22.049' cy='11.38' rx='22.049' ry='11.38' stroke='none' />
			<ellipse cx='22.049' cy='11.38' rx='21.549' ry='10.88' fill='none' />
		</g>
		<g
			id='Trazado_15'
			data-name='Trazado 15'
			transform='translate(15.648 4.268)'
			fill='#fff'>
			<path
				d='M 7.112607479095459 13.72510242462158 C 3.466397523880005 13.72510242462158 0.499997466802597 10.75872230529785 0.499997466802597 7.112552642822266 C 0.499997466802597 3.466382741928101 3.466397523880005 0.5000026226043701 7.112607479095459 0.5000026226043701 C 10.75881767272949 0.5000026226043701 13.72521781921387 3.466382741928101 13.72521781921387 7.112552642822266 C 13.72521781921387 10.75872230529785 10.75881767272949 13.72510242462158 7.112607479095459 13.72510242462158 Z'
				stroke='none'
			/>
			<path
				d='M 7.112607479095459 1.000002861022949 C 3.742107391357422 1.000002861022949 0.9999971389770508 3.742082595825195 0.9999971389770508 7.112552642822266 C 0.9999971389770508 10.48302268981934 3.742107391357422 13.22510242462158 7.112607479095459 13.22510242462158 C 10.4831075668335 13.22510242462158 13.22521781921387 10.48302268981934 13.22521781921387 7.112552642822266 C 13.22521781921387 3.742082595825195 10.4831075668335 1.000002861022949 7.112607479095459 1.000002861022949 M 7.112607479095459 2.86102294921875e-06 C 11.04078769683838 2.86102294921875e-06 14.22521781921387 3.184392929077148 14.22521781921387 7.112552642822266 C 14.22521781921387 11.04070281982422 11.04078769683838 14.22510242462158 7.112607479095459 14.22510242462158 C 3.184417724609375 14.22510242462158 -2.86102294921875e-06 11.04070281982422 -2.86102294921875e-06 7.112552642822266 C -2.86102294921875e-06 3.184392929077148 3.184417724609375 2.86102294921875e-06 7.112607479095459 2.86102294921875e-06 Z'
				stroke='none'
			/>
		</g>
	</svg>
);

export default EyeIcon;
