import { connect } from 'react-redux';

// models
import { StateModel } from '../../core/models/state.model';

interface NotificationProps {
	open: boolean;
}

function Notification({ open }: NotificationProps) {
	return (
		<section
			className={`bg-white shadow-md rounded-lg overflow-hidden ease-out duration-100 w-full absolute top-16 left-0 xl:w-96 xl:right-24 xl:left-auto ${open ? 'h-auto' : 'h-0'} `}>
			<p className='text-center my-6'>No tienes notificaciones pendientes</p>
		</section>
	);
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(Notification);
