import { useEffect } from 'react';
import { DispatchProp, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// actions
import { clientReset } from '../../redux/actions/client';

// models
import { StateModel } from '../../core/models/state.model';
import { ClientModel } from '../../core/models/client.model';

// utils
import { logPageView } from '../../utils/firebaseConfig';

interface SuccessChangePaswordProps {
	client: ClientModel;
}

function SuccessChangePasword({
	client,
	dispatch,
}: DispatchProp & SuccessChangePaswordProps) {
	const navigate = useNavigate();

	useEffect(() => {
		logPageView('Success Change Password', client);
		return () => {
			if (client.isResetPassword) {
				clientReset(dispatch);
			}
		};
	}, []);

	const goTo = () => {
		if (client.isChangePassword) {
			navigate('/perfil/completar-datos');
		} else if (client.isResetPassword) {
			navigate('login');
		} else {
			navigate('/');
		}
	};

	return (
		<section className='flex items-center justify-center flex-col md:h-[calc(100vh-11rem)]'>
			<p className='text-xl text-center'>
				¡Tu contraseña fue <br /> actualizada con éxito!
			</p>
			<button
				onClick={goTo}
				className='btn-primary mt-12 w-11/12 mx-auto'
				type='button'>
				Aceptar
			</button>
		</section>
	);
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(SuccessChangePasword);
