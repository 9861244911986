import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

// models
import { StateModel } from '../../../core/models/state.model';
import { ClientModel } from '../../../core/models/client.model';

// utils
import { logPageView } from '../../../utils/firebaseConfig';

interface ClientChangePhoneSuccessProps {
	client: ClientModel;
}

function ClientChangePhoneSuccess({ client }: ClientChangePhoneSuccessProps) {
	const navigate = useNavigate();

	useEffect(() => {
		logPageView('Client Change Phone Success', client);
	}, []);

	return (
		<section className='mt-5 px-5 flex flex-col items-center pb-8'>
			<p className='text-xl text-center'>
				¡Tu número celular fue <br /> actualizado con éxito!
			</p>
			<button
				className='btn-primary w-11/12 mt-10 py-4 mx-auto md:w-56'
				onClick={() => {
					navigate('/perfil/completar-datos');
				}}>
				Aceptar
			</button>
		</section>
	);
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(ClientChangePhoneSuccess);
