import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import { Navigation, Pagination } from 'swiper/modules';

// Models
import { StateModel } from '../core/models/state.model';
import { ClientModel } from '../core/models/client.model';

// utils
import { logPageView } from '../utils/firebaseConfig';

// assets
import Benefit1 from '../assets/images/benefits/uno.png';
import Benefit2 from '../assets/images/benefits/dos.png';
import Benefit3 from '../assets/images/benefits/tres.png';

interface BenefitsProps {
	client: ClientModel;
	isAuth: boolean;
}

function Benefits({ client }: BenefitsProps) {
	const navigate = useNavigate();
	const slides = [
		{
			content: (
				<>
					<p className='text-xl text-center font-bold mt-2 leading-5 lg:text-2xl'>
						Gana un cupón <br /> de bienvenida para canjear <br /> un latón en
						tu
						<span className='text-primary'> SIX </span>
						favorito
					</p>
					<section className='order-first'>
						<img
							src={Benefit1}
							className='h-48 mx-auto mt-7 object-contain lg:h-56'
						/>
						{client.token && (
							<button
								className='btn-primary !py-4 !px-8 mt-5 text-base mx-auto'
								onClick={() => {
									navigate('/cupones');
								}}>
								Canjear Mi Cupón
							</button>
						)}
					</section>
				</>
			),
		},
		{
			content: (
				<>
					<section>
						<p className='text-2xl text-primary mt-3 text-center font-black italic lg:text-3xl'>
							¡ACUMULA 5 PUNTOS
						</p>
						<p className='text-2xl text-center font-bold mt-2 leading-5 lg:text-3xl'>
							por cada peso de compra!
						</p>
					</section>
					<section className='order-first'>
						<img
							src={Benefit2}
							className=' h-52 mt-4 mb-5 mx-auto object-contain lg:h-64'
						/>
						<p className='text-xs text-center font-medium mt-5 leading-5 md:text-sm lg:text-base'>
							<span className='text-primary'>Categorías Participantes: </span>
							Cerveza, <br /> Sidras, RTDs, Rehidratantes, Spirits,
							<br /> Vinos y Licores, Sueros y Artículos SIX.
						</p>
					</section>
				</>
			),
		},
		{
			content: (
				<>
					<section>
						<p className='text-2xl text-center font-bold mb-2 mt-3 leading-5 lg:text-3xl'>
							¡Obtén cupones
						</p>
						<p className='text-2xl text-center text-primary font-bold mt-2 leading-5 lg:text-3xl'>
							EXCLUSIVOS!
						</p>
					</section>
					<img
						src={Benefit3}
						className='h-52 mx-auto mt-5 object-contain order-first lg:h-80'
					/>
				</>
			),
		},
	];

	useEffect(() => {
		logPageView('Benefits', client);
	}, []);

	return (
		<section className='flex items-center justify-center flex-col relative h-[calc(100vh-11rem)]'>
			<button className='button-prev rotate-90 disabled:opacity-0'></button>
			<section className='block w-full max-w-full h-96 px-5 lg:px-16'>
				<Swiper
					slidesPerView={1}
					spaceBetween={5}
					direction='vertical'
					navigation={{
						nextEl: '.button-next',
						prevEl: '.button-prev',
					}}
					pagination={{ clickable: true }}
					modules={[Navigation, Pagination]}
					className='max-h-full'
					wrapperClass='max-h-full'>
					{slides.map((slide, index) => (
						<SwiperSlide
							key={index}
							className='md:!grid md:grid-cols-2 md:items-center md:mx-auto md:!w-11/12 lg:!w-9/12 xl:!w-1/2'>
							{slide.content}
						</SwiperSlide>
					))}
				</Swiper>
			</section>
			<button className='button-next rotate-90 disabled:opacity-0'></button>
		</section>
	);
}
const mapStateToProps = (state: StateModel) => ({
	client: state.client,
	isAuth: !!(
		state.client.token &&
		state.client.clienteId &&
		!state.client.claveTemporal
	),
});

export default connect(mapStateToProps)(Benefits);
