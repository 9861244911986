import { Dispatch } from '@reduxjs/toolkit';

// services
import { getNearsShopsApi } from '../../core/services/shop.service';

// models
import { ShopModel } from '../../core/models/shop.model';
import { LocationModel } from '../../core/models/map.model';

// types
import type from '../types/map';

// utils
import { parseError } from '../../utils';

/**
 * Get the nears shops by making an API call with the current client location.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {LocationModel} location - The current client location.
 */
export function addNearsShops(dispatch: Dispatch, location: LocationModel) {
	dispatch({
		type: type.loading,
	});
	getNearsShopsApi(dispatch, location)
		.then((shops: ShopModel[]) => {
			const shopsData = shops.sort((a, b) => {
				return a.distancia > b.distancia
					? 1
					: a.distancia < b.distancia
						? -1
						: 0;
			});
			dispatch({
				type: type.addNearbyStoreMap,
				payload: shopsData,
			});
		})
		.catch((error: string | object) => {
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}

/**
 * Add the current client location to the Redux store.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {LocationModel} location - The current client location.
 */
export function addLocation(dispatch: Dispatch, location: LocationModel) {
	dispatch({
		type: type.addLocation,
		payload: location,
	});
}

/**
 * Add the current client location permission to the Redux store.
 *
 * @param {Dispatch} dispatch - The dispatch function from the Redux store.
 * @param {string} permission - The current client location permission.
 */
export function addLocationPermission(dispatch: Dispatch, permission: string) {
	dispatch({
		type: type.addLocationPermission,
		payload: permission,
	});
}
