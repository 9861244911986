import { useEffect } from 'react';
import { connect, DispatchProp } from 'react-redux';

// actions
import { clientProfile } from '../../redux/actions/client';

// models
import { StateModel } from '../../core/models/state.model';
import { ClientModel } from '../../core/models/client.model';

// components
import AdCarousel from '../../components/Ads/Carousel';
import ClientCard from '../../components/Client/Card';
import ClientPoints from '../../components/Client/Points';
import ProductCarousel from '../../components/Product/Carousel';

// utils
import { logPageView } from '../../utils/firebaseConfig';

interface HomeProps {
	client: ClientModel;
}

function Home({ client, dispatch }: HomeProps & DispatchProp) {
	useEffect(() => {
		logPageView('Home', client);
		if (client.celular) {
			const celular =
				client.celular.length > 10 ? client.celular.slice(2) : client.celular;
			clientProfile(dispatch, celular);
		}
	}, []);

	return (
		<>
			<AdCarousel />
			<section className='md:flex md:justify-evenly md:items-center md:my-5'>
				<ClientCard />
				<ClientPoints />
			</section>
			<ProductCarousel />
		</>
	);
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(Home);
