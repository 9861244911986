// models
import { MapModel } from '../../core/models/map.model';
import { ActionModel } from '../../core/models/redux.model';

// types
import type from '../types/map';

const INITIAL_STATE = {
	action: '',
	shops: [],
	latitud: 0,
	longitud: 0,
	nearsShops: {
		latitud: 0,
		longitud: 0,
		shops: [],
	},
	locationPermission: '',
	loading: false,
	error: '',
};

export default function (map: MapModel = INITIAL_STATE, action: ActionModel) {
	switch (action.type) {
		case type.addNearbyStoreMap: {
			return {
				...map,
				action: 'nearby',
				nearsShops: {
					latitud: map.latitud,
					longitud: map.longitud,
					shops: action.payload,
				},
				loading: false,
				error: '',
			};
		}
		case type.addStoresMap: {
			return { ...map, nearsShops: action.payload, loading: false, error: '' };
		}
		case type.addPromotionMap: {
			return {
				...map,
				action: 'promotion',
				shops: action.payload,
				loading: false,
				error: '',
			};
		}
		case type.removeMapAction: {
			return {
				...map,
				...INITIAL_STATE,
				nearsShops: map.nearsShops,
				latitud: map.latitud,
				longitud: map.longitud,
				loading: false,
				error: '',
			};
		}
		case type.addLocation: {
			return { ...map, ...action.payload, loading: false, error: '' };
		}
		case type.loading: {
			return { ...map, loading: true, error: '' };
		}
		case type.error: {
			return { ...map, loading: false, error: action.payload };
		}
		case type.addLocationPermission: {
			return {
				...map,
				locationPermission: action.payload,
				loading: false,
				error: '',
			};
		}
		default: {
			return map;
		}
	}
}
