import { useEffect, useState } from 'react';
import { DispatchProp, connect } from 'react-redux';
import dayjs from 'dayjs';
import { Swiper, SwiperSlide } from 'swiper/react';

// models
import { StateModel } from '../../core/models/state.model';
import { ClientModel } from '../../core/models/client.model';
import { addTransaction } from '../../redux/actions/transaction';
import { TransactionStateModel } from '../../core/models/transaction.model';

// components
import TransactionCard from './Card';

const filters = ['Todo', '30 días', '15 días', '7 días', '3 días'];

interface TransactionListProps extends DispatchProp {
	client: ClientModel;
	trasaction: TransactionStateModel;
}

function TransactionList({
	client,
	trasaction,
	dispatch,
}: TransactionListProps) {
	const [filter, setFilter] = useState('Todo');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		addTransaction(dispatch, client);
		setLoading(false);
		return () => {
			setLoading(true);
		};
	}, []);

	return (
		<section className='bg-red-points pt-5 pl-4 pr-2 pb-8 md:w-11/12 md:mx-auto lg:w-5/6 xl:w-1/2'>
			<p className='font-medium ml-5 md:text-center md:text-xl'>
				Historial de Puntos
			</p>
			<Swiper
				className='filters mt-6'
				spaceBetween={10}
				slidesPerView={3.2}
				breakpoints={{
					768: {
						slidesPerView: 5,
					},
				}}>
				{filters.map((item, index) => (
					<SwiperSlide key={index}>
						<button
							className={`btn-primary border border-primary text-sm w-28 !py-2 ${filter !== item.split(' ')[0] ? '!bg-white !text-primary' : ''}`}
							onClick={() => {
								setFilter(item.split(' ')[0]);
							}}>
							{item}
						</button>
					</SwiperSlide>
				))}
			</Swiper>
			<section className='flex items-center mt-8 justify-evenly'>
				{!loading &&
				!trasaction.error &&
				!trasaction.loading &&
				!Object.values(trasaction.transactions).length ? (
					<p className='text-center my-7'>
						No tienes ningún movimiento registrado
					</p>
				) : (
					<section className='w-72 xl:w-80'>
						{Object.keys(trasaction.transactions)
							.filter(item => {
								if (filter === 'Todo') {
									return true;
								} else {
									const date = dayjs(item);
									const now = dayjs();
									const diff = now.diff(date, 'day');
									return diff <= parseInt(filter);
								}
							})
							.map((item, index) => (
								<section key={index} className=' mb-8'>
									<TransactionCard
										date={item}
										trasaction={trasaction.transactions[item]}
									/>
								</section>
							))}
					</section>
				)}
			</section>
		</section>
	);
}
const mapStateToProps = (state: StateModel) => ({
	client: state.client,
	trasaction: state.transaction,
});

export default connect(mapStateToProps)(TransactionList);
