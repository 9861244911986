import { Route, Routes } from 'react-router-dom';

// Middleware
import GuestRoute from './GuestRoute';
import ProtectedRoute from './ProtectedRoute';

// components
import Welcome from '../pages/Welcome';
import NotFound from '../pages/NotFound';
import Login from '../pages/Auth/Login';
import ClientData from '../pages/Auth/Register/ClientData';
import ClientCompany from '../pages/Auth/Register/Company';
import ClientPhone from '../pages/Auth/Register/Phone';
import ValidationPhone from '../pages/ValidationPhone';
import ClientPassword from '../pages/Client/Password';
import SuccessRegister from '../pages/Auth/Register/Success';
import RequestResetPassword from '../pages/Auth/Password/Request';
import SuccessChangePassword from '../pages/Client/SuccessChangePassword';
import TermsConditions from '../pages/TermsConditions';
import Benefits from '../pages/Benefits';
import Map from '../pages/Map';
import Promotion from '../pages/Promotion';

import Home from '../pages/Client/Home';
import Product from '../pages/Client/Product';
import PointHistory from '../pages/Client/PointHistory';
import Coupons from '../pages/Client/Coupons';
import Profile from '../pages/Client/Profile';
import ProfileData from '../pages/Client/ClientData';
import ClientNewPhone from '../pages/Client/ChangePhone/NewPhone';
import ClientNewPhoneSuccess from '../pages/Client/ChangePhone/Success';
import InviteFriends from '../pages/Client/InviteFriends';
import DeleteAccount from '../pages/Client/DeleteAccount';

export default function AppRoutes() {
	return (
		<Routes>
			<Route element={<GuestRoute />}>
				<Route path='/bienvenido' element={<Welcome />} />
				<Route path='/login' element={<Login />} />
				<Route path='/registro'>
					<Route path='datos' element={<ClientData />} />
					<Route path='empresa' element={<ClientCompany />} />
					<Route path='celular' element={<ClientPhone />} />
					{/* <Route path='verificar-celular' element={<ValidationPhone />} /> */}
					{/* <Route path='contrasena' element={<ClientPassword />} /> */}
					<Route path='completado' element={<SuccessRegister />} />
				</Route>
			</Route>
			<Route element={<ProtectedRoute />}>
				<Route path='/' index element={<Home />} />
				<Route path='/productos' element={<Product />} />
				<Route path='/historial-de-puntos' element={<PointHistory />} />
				<Route path='/cupones' element={<Coupons />} />
				<Route path='/perfil'>
					<Route index element={<Profile />} />
					<Route path='completar-datos' element={<ProfileData />} />
					<Route path='actualizar-celular' element={<ClientNewPhone />} />
				</Route>
				<Route
					path='/actualizar-celular/completado'
					element={<ClientNewPhoneSuccess />}
				/>
				<Route path='/invitar-amigos' element={<InviteFriends />} />
				<Route path='/baja-del-programa' element={<DeleteAccount />} />
			</Route>
			<Route path='/terminos' element={<TermsConditions />} />
			<Route path='/beneficios' element={<Benefits />} />
			<Route path='/recuperar-contrasena'>
				<Route index element={<RequestResetPassword />} />
			</Route>
			<Route path=':path/verificar-celular' element={<ValidationPhone />} />
			<Route path=':path/contrasena' element={<ClientPassword />} />
			<Route path=':path/completado' element={<SuccessChangePassword />} />
			<Route path='/mapa' element={<Map />} />
			<Route path='/promociones' element={<Promotion />} />
			<Route path='*' element={<NotFound />} />
		</Routes>
	);
}
