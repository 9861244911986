import { Dispatch } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

// services
import { getTransactionsApi } from '../../core/services/transaction.service';

// models
import { ClientModel } from '../../core/models/client.model';
import {
	TransactionGroupModel,
	TransactionModel,
} from '../../core/models/transaction.model';

// types
import type from '../types/transaction';

// utils
import { parseError } from '../../utils';

/**
 * Retrieves and adds transactions to the Redux state for a specific client.
 *
 * @param {Dispatch} dispatch - The Redux dispatch function.
 * @param {ClientModel} client - The client information, including the authentication token.
 */
export function addTransaction(dispatch: Dispatch, client: ClientModel) {
	dispatch({
		type: type.loading,
	});
	if (client.token) {
		getTransactionsApi(dispatch, client.clienteId)
			.then((transactions: TransactionModel[]) => {
				const groupTransactions = transactions
					.sort((a, b) => (a.fecha < b.fecha ? 1 : a.fecha > b.fecha ? -1 : 0))
					.reduce((acc: TransactionGroupModel, transaction) => {
						const date = dayjs(transaction.fecha).format('YYYY-MM-DD');
						transaction.details = [];
						acc[date] = acc[date] || [];
						const coupons = Object.values(transaction.cupones);
						const promotions = Object.values(transaction.promociones);
						if (coupons.length) {
							transaction.details = coupons.map(coupon => ({
								...coupon,
								value: `${coupon.nombre} x ${coupon.cantidad}`,
								type: 'Cupón',
								class: 'coupon',
							}));
						}
						if (promotions.length) {
							transaction.details = promotions.map(promotion => ({
								...promotion,
								value: promotion.nombre,
								type: 'Promo',
								class: 'coupon',
							}));
						}
						if (transaction.acumulado > 0) {
							transaction.details.push({
								type: 'Puntos (+)',
								value: `+${transaction.acumulado.toLocaleString()} puntos`,
								class: 'added',
							});
						}
						if (transaction.pagoPuntos > 0) {
							transaction.details.push({
								type: 'Puntos (-)',
								value: `-${transaction.acumulado.toLocaleString()} puntos`,
								class: 'spending',
							});
						}
						if (transaction.articulos > 0) {
							transaction.details.push({
								type: 'Total',
								value: `${transaction.articulos} Artículos`,
								class: 'coupon',
							});
						}
						acc[date].push(transaction);
						return acc;
					}, {});
				dispatch({
					type: type.addTransaction,
					payload: groupTransactions,
				});
			})
			.catch((error: string | object) => {
				dispatch({
					type: type.error,
					payload: parseError(error),
				});
			});
	}
}
