import { useLocation } from 'react-router-dom';
import { DispatchProp, connect } from 'react-redux';

// actions
import { addModal } from '../../redux/actions/modals';
import {
	addFavoriteBrand,
	removeFavoriteBrand,
	clientSetFavoriteBrands,
} from '../../redux/actions/client';

// models
import { StateModel } from '../../core/models/state.model';
import { BrandModel } from '../../core/models/brand.model';
import { ClientModel } from '../../core/models/client.model';

// components
import FavoriteBrandModal from './FavoriteBrandModal';
import AddBrandModal from './AddModal';

// assets
import addFavoriteBrandIcon from '../../assets/icons/add-favorite-brand.png';
import addIcon from '../../assets/icons/add.png';

// utils
import { replaceSpacesWithNewLine } from '../../utils';

interface BrandCardProps {
	brand: BrandModel;
	isSelected: boolean;
	maxFavoriteBrands: boolean;
	isClient?: boolean;
	isModal?: boolean;
	client: ClientModel;
	defaultImage: string;
}

function BrandCard({
	brand,
	client,
	isModal,
	isClient,
	isSelected,
	maxFavoriteBrands,
	dispatch,
}: BrandCardProps & DispatchProp) {
	const isAnotherBrand = brand.nombreMarca.toLowerCase() === 'otra';
	const location = useLocation();
	const isProfileScreen = location.pathname.includes('perfil');

	const onClickCard = () => {
		if (isAnotherBrand) {
			if (isClient) {
				addModal(dispatch, {
					component: FavoriteBrandModal,
				});
				return;
			}
			addModal(dispatch, {
				component: AddBrandModal,
			});
			return;
		}

		if (isProfileScreen) {
			let newFavoriteBrands: BrandModel[] = JSON.parse(
				JSON.stringify(client.favoriteBrand),
			);
			let isRemove = true;
			if (isSelected || isClient) {
				newFavoriteBrands = client.favoriteBrand.filter(
					item => item.marcaId !== brand.marcaId,
				);
			} else if (!maxFavoriteBrands) {
				isRemove = false;
				newFavoriteBrands.push(brand);
			}
			if (maxFavoriteBrands && !isRemove) return;
			clientSetFavoriteBrands(dispatch, client, newFavoriteBrands);
		} else if (isSelected || isClient) {
			removeFavoriteBrand(dispatch, brand.marcaId);
			return;
		}

		if (maxFavoriteBrands) return;

		addFavoriteBrand(dispatch, brand);
	};

	return (
		<>
			{isProfileScreen && isAnotherBrand && !isModal ? (
				<ProfileBrandCard onClickCard={onClickCard} />
			) : (
				<button
					className={`brand-card ${isClient && isAnotherBrand ? '!bg-red-app' : ''} ${isSelected ? '!border-primary' : ''}`}
					type='button'
					onClick={onClickCard}>
					<img
						src={brand.imagen}
						alt={brand.nombreMarca}
						className='h-[90px]'
					/>
					{isAnotherBrand && (
						<img
							src={addIcon}
							alt='Add icon'
							className='absolute top-1/2 -right-2 w-6 z-10'
						/>
					)}
					<p
						className={`text-xs text-center text-gray-app mt-2 ${isClient && isAnotherBrand ? 'text-white' : ''}`}
						dangerouslySetInnerHTML={{
							__html: replaceSpacesWithNewLine(brand.nombreMarca),
						}}></p>
				</button>
			)}
		</>
	);
}

function ProfileBrandCard({
	onClickCard,
}: {
	onClickCard: () => void;
}): JSX.Element {
	return (
		<section className='w-full'>
			<button
				className='card flex flex-col items-center w-[90px] h-[155px] py-3 relative justify-center'
				onClick={onClickCard}>
				<img
					src={addFavoriteBrandIcon}
					alt='Icon agregar'
					className='w-16 object-contain'
				/>
				<img
					src={addIcon}
					alt='Agregar'
					className='absolute top-1/2 -right-2 w-6 z-10'
				/>
				<p className='text-primary text-xs font-bold text-center mt-3'>
					Agregar
				</p>
			</button>
		</section>
	);
}

const mapStateToProps = (state: StateModel) => ({
	maxFavoriteBrands: state.client.favoriteBrand.length === 5,
	client: state.client,
	defaultImage: state.brand.defaultImage,
});

export default connect(mapStateToProps)(BrandCard);
