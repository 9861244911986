// models
import { BrandModel, BrandStateModel } from '../../core/models/brand.model';
import { ActionModel } from '../../core/models/redux.model';
import { updateBrands } from '../../utils';

// types
import type from '../types/brand';

const INITIAL_STATE: BrandStateModel = {
	error: '',
	loading: false,
	data: [],
	defaultImage: '',
};

export default function (
	brand: BrandStateModel = INITIAL_STATE,
	action: ActionModel,
) {
	switch (action.type) {
		case type.setBrands: {
			const unRepeated: BrandModel[] = action.payload;
			return {
				...brand,
				data: updateBrands(brand.data, unRepeated),
			};
		}
		case type.removeBrand: {
			return {
				...brand,
				data: brand.data.filter(item => item.marcaId !== action.payload),
			};
		}
		case type.addDefaultImage: {
			return { ...brand, defaultImage: action.payload };
		}
		default:
			return brand;
	}
}
