import { useEffect } from 'react';
import { connect, DispatchProp } from 'react-redux';

// actions
import { getBrand } from '../../redux/actions/brand';

// models
import { StateModel } from '../../core/models/state.model';
import { BrandModel } from '../../core/models/brand.model';
import { ClientModel } from '../../core/models/client.model';

// components
import BrandCard from './Card';

interface BrandListProps {
	brands: BrandModel[];
	client: ClientModel;
	favoriteBrand: BrandModel[];
}

function ClientBrandList({
	brands,
	favoriteBrand,
	dispatch,
}: BrandListProps & DispatchProp) {
	useEffect(() => {
		if (!brands.length) {
			getBrand(dispatch);
		}
	}, []);

	return (
		<section className='list ml-6 grid grid-flow-col gap-5 justify-start py-4 pr-4 my-4 overflow-x-auto max-w-full md:order-last md:col-span-2 md:justify-center md:pr-0 md:my-1 lg:ml-0'>
			{favoriteBrand.map(item => (
				<BrandCard
					key={item.marcaId}
					brand={item}
					isSelected={false}
					isClient
				/>
			))}
		</section>
	);
}

const mapStateToProps = (state: StateModel) => {
	const newState: StateModel = JSON.parse(JSON.stringify(state));
	const { brand, client } = newState;
	const { favoriteBrand } = client;
	const newBrands = brand.data;

	const defaultBrandIndex = newBrands.findIndex(
		item => item.nombreMarca.toLowerCase() === 'otra',
	);
	if (defaultBrandIndex !== -1) {
		const defaultBrand = newBrands[defaultBrandIndex];
		newBrands.splice(defaultBrandIndex, 1);
		if (favoriteBrand.length < 5) {
			favoriteBrand.push(defaultBrand);
		}
	}
	return {
		favoriteBrand,
		brands: newBrands,
		client: state.client,
	};
};

export default connect(mapStateToProps)(ClientBrandList);
