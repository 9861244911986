import { useEffect } from 'react';
import { connect, DispatchProp } from 'react-redux';

// actions
import { addModal } from '../../redux/actions/modals';
import { setClient } from '../../redux/actions/client';

// models
import { StateModel } from '../../core/models/state.model';
import { ClientModel } from '../../core/models/client.model';

// components
import DeleteAccountModal from '../../components/Client/DeleteAccountModal';

// utils
import { logPageView } from '../../utils/firebaseConfig';

// assets
import CheckIcon from '../../assets/icons/check.png';

const benefits = [
	'Acumula puntos <br /> por cada compra',
	'Obtén promociones <br /> exclusivas',
	'Recibe cupones <br /> pensando solo para ti',
	'Y ¡mucho más! ',
];

interface DeleteAccountProps extends DispatchProp {
	client: ClientModel;
}

function DeleteAccount({ client, dispatch }: DeleteAccountProps) {
	useEffect(() => {
		logPageView('Delete Account', client);
		return () => {
			setClient(dispatch, {});
		};
	}, []);

	return (
		<>
			<p className='text-center text-lg font-medium leading-5 mb-10 mt-9'>
				Recuerda que al eliminar tu cuenta <br /> perderás tus beneficios
				<br />
				SUMA SIX
			</p>
			<section className='w-4/5 mx-auto md:w-3/5 lg:w-1/2 '>
				{benefits.map((benefit, index) => (
					<section
						className='w-full flex items-center justify-start mt-1 mb-5 lg:mx-auto lg:w-3/12'
						key={index}>
						<img src={CheckIcon} className='w-10 h-10 mr-2' />
						<p
							className='font-bold'
							dangerouslySetInnerHTML={{ __html: benefit }}></p>
					</section>
				))}
				<button
					className='btn-primary w-11/12 mx-auto !py-4 my-5 lg:w-1/2'
					onClick={() => {
						addModal(dispatch, {
							component: DeleteAccountModal,
						});
					}}>
					Eliminar mi cuenta
				</button>
			</section>
		</>
	);
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(DeleteAccount);
