import { connect } from 'react-redux';
import { Navigate, RouteProps, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';

// models
import { StateModel } from '../core/models/state.model';
import { ClientModel } from '../core/models/client.model';

interface GuestRouteProps {
	isAuth: boolean;
	client: ClientModel;
}

function ProtectedRoute({ isAuth, client }: GuestRouteProps & RouteProps) {
	if (isAuth) {
		const token = Cookies.get('token');
		if (!token) {
			Cookies.set('token', client.token, { secure: true });
		}
	}
	return isAuth ? <Outlet /> : <Navigate to='/login' replace />;
}

const mapStateToProps = (state: StateModel) => ({
	isAuth: !!(
		state.client.token &&
		state.client.clienteId &&
		!state.client.claveTemporal
	),
	client: state.client,
});

export default connect(mapStateToProps)(ProtectedRoute);
