import { useEffect } from 'react';
import { DispatchProp, connect } from 'react-redux';
import QRCode from 'react-qr-code';

// models
import { StateModel } from '../../core/models/state.model';
import { ClientModel } from '../../core/models/client.model';

// actions
import { setClientEncryptedCard } from '../../redux/actions/client';

// utils
import { encodeCardAsToken } from '../../utils';
import { logClickEvent } from '../../utils/firebaseConfig';

interface ClientQrCardProps {
	client: ClientModel;
}

function ClientQrCard({ client, dispatch }: ClientQrCardProps & DispatchProp) {
	useEffect(() => {
		onEncodeCard();
	}, [client.tarjeta]);

	const onEncodeCard = () => {
		logClickEvent('QR Card');
		if (client.tarjeta?.numeroTarjeta) {
			const textCard = encodeCardAsToken(client.tarjeta.numeroTarjeta);
			if (textCard) {
				setClientEncryptedCard(dispatch, textCard);
			}
		}
	};

	if (!client.encryptedCard) {
		return <></>;
	}

	return (
		<button type='button' className='block mx-auto' onClick={onEncodeCard}>
			<QRCode
				value={client.encryptedCard}
				size={215}
				color='#000'
				bgColor='#fff'
			/>
		</button>
	);
}
const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(ClientQrCard);
