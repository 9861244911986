interface IconProps {
	className?: string;
	slot?: string;
}

const InstagramIcon = (props: IconProps) => (
	<svg
		{...props}
		id='Grupo_2505'
		data-name='Grupo 2505'
		xmlns='http://www.w3.org/2000/svg'
		width='130.431'
		height='130.431'
		viewBox='0 0 130.431 130.431'>
		<ellipse
			id='Elipse_174'
			data-name='Elipse 174'
			cx='65.215'
			cy='65.216'
			rx='65.215'
			ry='65.216'
			fill='#e11f1c'
		/>
		<path
			id='Trazado_1084'
			data-name='Trazado 1084'
			d='M150.522,101.529a18.3,18.3,0,0,0-5.587-11.787A19.134,19.134,0,0,0,132.273,84.6c-8.12-.443-27.4-.723-34.173.38C90.366,86.239,85.3,90.755,83.159,98.4c-1.646,5.877-1.319,32.97-.359,38.779,1.308,7.946,6,13.037,13.876,15.068,5.45,1.409,32.231,1.224,38.24.253,7.951-1.287,13.069-5.957,15.132-13.818C151.63,132.647,151.076,106.974,150.522,101.529Zm-6.358,34.521c-.923,6.036-4.928,9.771-11,10.436-5.571.612-30.458.944-35.629-.5-4.944-1.382-7.772-4.78-8.589-9.755-.786-4.754-.981-28.506-.011-34.864.907-5.983,4.912-9.713,10.948-10.388,6.12-.686,28.142-.76,34,.137,6.02.923,9.755,4.938,10.415,11.016C144.925,107.8,145.1,129.956,144.165,136.05ZM116.55,101.028a17.712,17.712,0,1,0,17.7,17.722A17.713,17.713,0,0,0,116.55,101.028Zm-.121,29.155a11.455,11.455,0,1,1,11.554-11.354A11.459,11.459,0,0,1,116.429,130.183ZM139.1,100.358a4.147,4.147,0,1,1-4.136-4.157A4.148,4.148,0,0,1,139.1,100.358Z'
			transform='translate(-50.639 -53.391)'
			fill='#fff'
		/>
		<path
			id='Trazado_1085'
			data-name='Trazado 1085'
			d='M150.522,101.529a18.3,18.3,0,0,0-5.587-11.787A19.134,19.134,0,0,0,132.273,84.6c-8.12-.443-27.4-.723-34.173.38C90.366,86.239,85.3,90.755,83.159,98.4c-1.646,5.877-1.319,32.97-.359,38.779,1.308,7.946,6,13.037,13.876,15.068,5.45,1.409,32.231,1.224,38.24.253,7.951-1.287,13.069-5.957,15.132-13.818C151.63,132.647,151.076,106.974,150.522,101.529Zm-6.358,34.521c-.923,6.036-4.928,9.771-11,10.436-5.571.612-30.458.944-35.629-.5-4.944-1.382-7.772-4.78-8.589-9.755-.786-4.754-.981-28.506-.011-34.864.907-5.983,4.912-9.713,10.948-10.388,6.12-.686,28.142-.76,34,.137,6.02.923,9.755,4.938,10.415,11.016C144.925,107.8,145.1,129.956,144.165,136.05ZM116.55,101.028a17.712,17.712,0,1,0,17.7,17.722A17.713,17.713,0,0,0,116.55,101.028Zm-.121,29.155a11.455,11.455,0,1,1,11.554-11.354A11.459,11.459,0,0,1,116.429,130.183ZM139.1,100.358a4.147,4.147,0,1,1-4.136-4.157A4.148,4.148,0,0,1,139.1,100.358Z'
			transform='translate(-50.639 -53.391)'
			fill='#fff'
		/>
	</svg>
);

export default InstagramIcon;
