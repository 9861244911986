import { useCallback, useEffect, useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

// actions
import { addNearsShops } from '../redux/actions/map';
import { addToast } from '../redux/actions/toast';

// models
import { StateModel } from '../core/models/state.model';
import { MapModel } from '../core/models/map.model';
import { ClientModel } from '../core/models/client.model';
import { ShopModel } from '../core/models/shop.model';

// assets
import shareMapIcon from '../assets/icons/map/share-map.png';
import goMapIcon from '../assets/icons/map/go-map.png';
import markerIcon from '../assets/icons/map/marker.png';

// utils
import { GOOGLE_MAPS_API_KEY } from '../utils/config';
import { getCurrentLocation } from '../utils';
import { logPageView } from '../utils/firebaseConfig';

interface AppMapProps extends DispatchProp {
	map: MapModel;
	client: ClientModel;
}

const mapUrl = 'https://www.google.com/maps/search/?api=1&query=';

function AppMap({ client, map, dispatch }: AppMapProps) {
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: GOOGLE_MAPS_API_KEY,
	});

	useEffect(() => {
		logPageView('Map', client);
		(async () => {
			getCurrentLocation(dispatch, {
				latitud: map.latitud,
				longitud: map.longitud,
			});
		})();
	}, []);

	useEffect(() => {
		if (
			map.latitud &&
			map.longitud &&
			map.nearsShops.latitud !== map.latitud &&
			map.nearsShops.longitud !== map.longitud
		) {
			addNearsShops(dispatch, {
				latitud: map.latitud,
				longitud: map.longitud,
				radio: 5,
			});
		}
	}, [map.latitud, map.longitud, client.token]);

	const [currentMap, setCurrentMap] = useState<google.maps.Map | null>(null);

	const onLoaded = useCallback((googleMap: google.maps.Map) => {
		setCurrentMap(googleMap);
	}, []);

	const goToMap = (shop: ShopModel) => () => {
		window.open(`${mapUrl}${shop.latitud},${shop.longitud}`);
	};

	const shareShop = (shop: ShopModel) => () => {
		const text = `Hola, visita mi SIX favorito ${map.nearsShops.shops[0].nombre}, ${mapUrl}${shop.latitud},${shop.longitud}`;
		const message = {
			title: 'SumaSix',
			text,
		};

		if (navigator.share) {
			navigator.share(message);
		} else {
			navigator.clipboard.writeText(text);
			addToast(dispatch, {
				title: 'Ubicación copiada al portapapeles',
			});
		}
	};

	const centerMap = (shop: ShopModel) => () => {
		if (currentMap) {
			currentMap.setCenter({
				lat: Number(shop.latitud),
				lng: Number(shop.longitud),
			});
			currentMap.setZoom(16);
		}
	};

	if (
		map.loading ||
		!isLoaded ||
		((!map.latitud || !map.longitud) && map.locationPermission !== 'denied')
	) {
		return (
			<section className='mt-16'>
				<p className='text-primary text-xl font-black italic text-center mb-3'>
					Cargando...
				</p>
				<svg
					className='animate-spin mx-auto h-10 w-10 text-primary'
					xmlns='http://www.w3.org/2000/svg'
					fill='none'
					viewBox='0 0 24 24'>
					<circle
						className='opacity-25'
						cx='12'
						cy='12'
						r='10'
						stroke='currentColor'
						strokeWidth='4'></circle>
					<path
						className='opacity-75'
						fill='currentColor'
						d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
				</svg>
			</section>
		);
	}

	if (!map.loading && isLoaded && map.locationPermission === 'denied') {
		return (
			<section className='mt-16'>
				<p className='text-primary text-xl font-black italic text-center mb-3'>
					Activa tu ubicación para localizar tus <br /> Six cercanos
				</p>
			</section>
		);
	}

	return (
		<section className='mt-4 xl:grid xl:grid-cols-[.7fr_1fr] xl:grid-rows-[6rem_1fr_1fr] xl:pl-6 xl:gap-x-16'>
			<section className='px-8'>
				<p className=''>Encuentra tu SIX Favorito</p>
				<button className='btn-primary'>SIX cercanos</button>
			</section>
			<GoogleMap
				id='suma-six-map'
				zoom={13}
				onLoad={onLoaded}
				center={{
					lat: map.latitud,
					lng: map.longitud,
				}}
				mapContainerClassName='mt-5 w-full h-[430px] row-span-3 xl:h-[calc(100vh-13rem)]'
				options={{
					zoomControl: false,
					mapTypeControl: false,
					scaleControl: false,
					streetViewControl: false,
					rotateControl: false,
					fullscreenControl: false,
				}}>
				<Marker
					position={{
						lat: map.latitud,
						lng: map.longitud,
					}}
				/>
				{map.nearsShops.shops.map((shop: ShopModel) => (
					<Marker
						key={shop.numeroNegocio}
						position={{
							lat: Number(shop.latitud),
							lng: Number(shop.longitud),
						}}
						icon={{
							url: markerIcon,
							scaledSize: new google.maps.Size(60, 70),
						}}
						onClick={centerMap(shop)}
						animation={google.maps.Animation.DROP}
					/>
				))}
			</GoogleMap>
			<section className='xl:row-span-2'>
				<p className='font-bold mt-4 ml-4 xl:text-xl'>SIX cercanos</p>
				<section className='max-h-40 overflow-hidden overflow-y-auto xl:max-h-[calc(100vh-22rem)]'>
					{map.nearsShops.shops.map((shop: ShopModel) => (
						<section
							key={shop.numeroNegocio}
							className='flex justify-between mt-3 px-4 w-[95%] mb-2 mx-auto'
							onClick={centerMap(shop)}>
							<section className='w-11/12'>
								<p className='text-primary font-bold text-[15px] xl:text-base'>
									{shop.nombre}
								</p>
								<p className='text-[10px] text-left max-w-[90%] xl:text-sm'>
									A {shop.distancia} Km de ti
								</p>
								<p className='text-[10px] text-left max-w-[90%] xl:text-sm'>
									{shop.direccion}
								</p>
							</section>
							<section className='text-center'>
								<button onClick={shareShop(shop)} className='mx-2'>
									<img src={shareMapIcon} alt='share' className='w-7 h-7' />
								</button>
								<button onClick={goToMap(shop)}>
									<img src={goMapIcon} alt='go' className='w-7 h-7' />
								</button>
							</section>
						</section>
					))}
				</section>
			</section>
		</section>
	);
}

const mapStateToProps = (state: StateModel) => ({
	map: state.map,
	client: state.client,
});

export default connect(mapStateToProps)(AppMap);
