import { Dispatch } from '@reduxjs/toolkit';

// services
import { getAdsApi } from '../../core/services/ad.service';

// models
import { AdModel } from '../../core/models/ad.model';
import { LocationModel } from '../../core/models/map.model';

// utils
import { parseError } from '../../utils';

// types
import type from '../types/ad';

/**
 * Retrieves and adds ads to the Redux state for a specific location and client.
 *
 * @param {Dispatch} dispatch - The Redux dispatch function.
 * @param {LocationModel} location - The location for which ads are to be fetched.
 */
export function addAd(dispatch: Dispatch, location: LocationModel) {
	dispatch({
		type: type.loading,
	});
	getAdsApi(dispatch, location)
		.then((ads: AdModel) => {
			dispatch({
				type: type.addAd,
				payload: {
					publicidad: ads.publicidad,
					latitud: location.latitud,
					longitud: location.longitud,
				},
			});
		})
		.catch((error: string | object) => {
			dispatch({
				type: type.error,
				payload: parseError(error),
			});
		});
}
