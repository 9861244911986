import { useNavigate } from 'react-router-dom';

// assets
import addFriendsImage from '../../assets/images/add-friends.png';

export default function InviteFriends() {
	const navigate = useNavigate();

	const goToInviteFriends = () => {
		navigate('/invitar-amigos');
	};

	return (
		<button
			className='card w-11/12 flex h-[90px] justify-evenly items-center !p-2 mb-5 mx-auto !border !border-gray-friends !bg-primary md:w-4/5 lg:w-96'
			onClick={goToInviteFriends}>
			<img
				src={addFriendsImage}
				alt='Invitar amigos'
				className='w-[150px] h-[75px] object-contain'
			/>
			<p className='text-white font-black leading-5'>
				Invita a tus amigos <br /> y gana 300 puntos
			</p>
		</button>
	);
}
