// models
import { ProductModel } from '../../core/models/product.model';

interface ProductCardProps {
	product: ProductModel;
	isCard?: boolean;
}

export default function ProductCard({ product, isCard }: ProductCardProps) {
	return (
		<section
			className={`card flex items-center justify-center w-[150px] md:w-52 ${!isCard ? '!shadow-none !bg-transparent !border-none' : 'w-48 h-32'}`}>
			<img
				src={product.imagenUrl}
				alt={product.nombre}
				className={`w-[52px] h-28 mr-2 ${!isCard ? 'md:h-32 md:w-16' : ''}`}
			/>
			<p
				className={`text-xs text-center max-w-[71px] ml-2 leading-4 ${isCard ? 'max-w-[100px] font-medium' : 'md:text-lg md:max-w-32'}`}>
				{product.nombre}
			</p>
		</section>
	);
}
