import { useEffect } from 'react';
import { connect } from 'react-redux';

// models
import { StateModel } from '../core/models/state.model';
import { ModalModel } from '../core/models/modal.model';

// components
import Modal from './Modal';

interface ModalsProps {
	modals: ModalModel[];
}

function Modals({ modals }: ModalsProps) {
	useEffect(() => {
		const body = document.querySelector('body');
		if (modals.length) {
			body?.classList.add('overflow-hidden');
		} else {
			body?.classList.remove('overflow-hidden');
		}
	}, [modals]);

	return (
		<>
			{modals.map(modal => (
				<Modal key={modal.id} modal={modal} />
			))}
		</>
	);
}

const mapStateToProps = (state: StateModel) => ({
	modals: state.modals,
});

export default connect(mapStateToProps)(Modals);
