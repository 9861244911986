import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducer from './reducer';
import { StateModel } from '../core/models/state.model';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['client'],
};

const persistedReducer = persistReducer<StateModel>(persistConfig, reducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export default store;
