import { connect } from 'react-redux';
import dayjs from 'dayjs';

// models
import { StateModel } from '../../core/models/state.model';
import { ClientModel } from '../../core/models/client.model';
import { useNavigate } from 'react-router-dom';

interface PointsProps {
	client: ClientModel;
}

function Points({ client }: PointsProps) {
	const navigate = useNavigate();

	const goToPointHistory = () => {
		navigate('/historial-de-puntos');
	};
	return (
		<section className='mt-5 px-5 mx-auto md:!m-0 md:px-0'>
			<section className='flex items-center justify-center'>
				<p className='text-center font-bold mr-4 md:text-xl'>
					Puntos <br /> Disponibles
				</p>
				<p className='text-3xl text-center font-bold ml-4'>
					{client.saldo?.toLocaleString()}
				</p>
			</section>
			<p className='text-xs text-center my-5 md:text-base'>
				Vigencia: 31 Diciembre {dayjs().year()}
			</p>
			<button
				className='btn-secondary mx-auto w-[90%] mt-3 py-3 md:w-80'
				onClick={goToPointHistory}>
				Ver Historial de Puntos
			</button>
		</section>
	);
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(Points);
