import { Dispatch } from '@reduxjs/toolkit';

// services
import { getCouponApi } from '../../core/services/coupon.service';

// models
import { ClientModel } from '../../core/models/client.model';
import {
	CouponCategoryModel,
	CouponModel,
} from '../../core/models/coupon.model';

// types
import type from '../types/coupon';

// utils
import { parseError } from '../../utils';
import dayjs from 'dayjs';

/**
 * Adds coupons to the Redux state for a specific client.
 *
 * @param {Dispatch} dispatch - The Redux dispatch function.
 * @param {ClientModel} client - The client information, including the authentication token.
 */
export function addCoupon(dispatch: Dispatch, client: ClientModel) {
	dispatch({ type: type.loading });
	getCouponApi(dispatch, client.clienteId)
		.then(
			(coupon: {
				coupons: {
					available: CouponModel[];
					used: CouponModel[];
				};
				cuponCategories: CouponCategoryModel[];
			}) => {
				const categories = coupon.cuponCategories.map(item => {
					const hasExpiration = coupon.coupons.available.some(
						coupon =>
							coupon.categoriaId === item.id &&
							dayjs(coupon.vigencia).diff(dayjs(), 'day') < 30,
					);
					const hasCoupons = coupon.coupons.available.some(
						coupon => coupon.categoriaId === item.id,
					);
					return {
						...item,
						hasExpiration,
						hasCoupons,
					};
				});

				dispatch({
					type: type.addCoupon,
					payload: {
						available: coupon.coupons.available,
						used: coupon.coupons.used,
						categories: categories.sort((a, b) => {
							return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
						}),
					},
				});
			},
		)
		.catch((error: string) => {
			dispatch({ type: type.error, payload: parseError(error) });
		});
}

/**
 * Removes coupon from the Redux state for a specific client.
 *
 * @param {Dispatch} dispatch - The Redux dispatch function.
 * @param {CouponModel} coupon - The coupon to be redeemed.
 */
export function removeCoupon(dispatch: Dispatch, coupon: Partial<CouponModel>) {
	dispatch({ type: type.removeCoupon, payload: coupon });
}

/**
 * Sets a coupon to the Redux state.
 *
 * @param {Dispatch} dispatch - The Redux dispatch function.
 * @param {CouponModel} coupon - The coupon to be redeemed.
 */
export function setCoupon(dispatch: Dispatch, coupon: CouponModel) {
	dispatch({ type: type.addCoupon, payload: [{ ...coupon, isNew: false }] });
}
