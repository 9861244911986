// models
import { ProductStateModel } from '../../core/models/product.model';
import { ActionModel } from '../../core/models/redux.model';

// types
import type from '../types/product';

const INITIAL_STATE: ProductStateModel = {
	error: '',
	loading: false,
	data: [],
};

export default (product = INITIAL_STATE, action: ActionModel) => {
	switch (action.type) {
		case type.setProduct:
			return {
				...product,
				data: action.payload,
				error: '',
				loading: false,
			};
		case type.error:
			return {
				...product,
				error: action.payload,
				loading: false,
			};
		case type.loading:
			return {
				...product,
				loading: true,
			};
		default:
			return product;
	}
};
