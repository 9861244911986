import { DispatchProp, connect } from 'react-redux';

// actions
import { removeToast } from '../../redux/actions/toast';

// models
import { ToastModel } from '../../core/models/toast.model';
import { useEffect } from 'react';

interface CardProps extends DispatchProp {
	toast: ToastModel;
}

function Card({ toast, dispatch }: CardProps) {
	useEffect(() => {
		const timer = setTimeout(() => {
			removeToast(dispatch, toast.id);
		}, 5000);
		return () => {
			clearTimeout(timer);
		};
	}, []);

	const closeToast = () => {
		removeToast(dispatch, toast.id);
	};

	return (
		<section className='pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
			<section className='p-4'>
				<section className='flex items-start'>
					<section className='flex-shrink-0'>
						<RenderIcon type={toast.type} />
					</section>
					<section className='ml-3 w-0 flex-1 pt-0.5'>
						<p className='text-sm font-medium text-gray-900'>
							{toast.title} {toast.id}
						</p>
						{toast.description && (
							<p className='mt-1 text-sm text-gray-500'>{toast.description}</p>
						)}
					</section>
					<section className='ml-4 flex flex-shrink-0'>
						<button
							type='button'
							className='inline-flex rounded-md bg-white text-primary hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2'
							onClick={closeToast}>
							<span className='sr-only'>Close</span>
							<svg
								className='h-5 w-5'
								viewBox='0 0 20 20'
								fill='currentColor'
								aria-hidden='true'>
								<path d='M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z' />
							</svg>
						</button>
					</section>
				</section>
			</section>
		</section>
	);
}

function RenderIcon({ type }: { type: ToastModel['icon'] }) {
	switch (type) {
		case 'error':
			return (
				<svg
					className='h-6 w-6 text-red-400'
					fill='none'
					viewBox='0 0 24 24'
					stroke='currentColor'
					aria-hidden='true'>
					<circle cx='12' cy='12' r='9' />
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth={2}
						d='M6 18L18 6M6 6l12 12'
					/>
				</svg>
			);
		default:
			return (
				<svg
					className='h-6 w-6 text-green-400'
					fill='none'
					viewBox='0 0 24 24'
					strokeWidth='1.5'
					stroke='currentColor'
					aria-hidden='true'>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
				</svg>
			);
	}
}

export default connect()(Card);
