// models
import { ActionModel } from '../../core/models/redux.model';
import { ModalModel } from '../../core/models/modal.model';

// types
import type from '../types/modal';

export default function (modals: ModalModel[] = [], action: ActionModel) {
	switch (action.type) {
		case type.addModal: {
			const id = modals.reduce((p, c) => (p > c.id ? p + 1 : c.id + 1), 0);
			return modals.concat([{ ...action.payload, id }]);
		}
		case type.removeModal: {
			return modals.filter(modal => modal.id !== action.payload);
		}
		default:
			return modals;
	}
}
