import { useEffect, useState } from 'react';
import { DispatchProp, connect } from 'react-redux';

// actions
import { setClientEncryptedCard } from '../../redux/actions/client';

// models
import { StateModel } from '../../core/models/state.model';
import { ClientModel } from '../../core/models/client.model';

// components
import ClientQrCard from './QrCard';

// utils
import { encodeCardAsToken } from '../../utils';
import { logClickEvent } from '../../utils/firebaseConfig';

// assets
import appIcon from '../../assets/icons/app.png';

interface ClientCardProps {
	client: ClientModel;
}

function ClientCard({ client, dispatch }: ClientCardProps & DispatchProp) {
	const [showQR, setShowQR] = useState(false);
	let name = '';

	useEffect(() => {
		if (!client.encryptedCard) {
			onEncodeCard();
		}
	}, [client.tarjeta]);

	if (client.nombre) {
		name = client.nombre.split(' ')[0];
	}

	const toggleShowQR = () => {
		logClickEvent('toggle show qr');
		setShowQR(!showQR);
	};

	const onEncodeCard = () => {
		logClickEvent('QR Card');
		if (client.tarjeta?.numeroTarjeta) {
			const textCard = encodeCardAsToken(client.tarjeta.numeroTarjeta);
			if (textCard) {
				setClientEncryptedCard(dispatch, textCard);
			}
		}
	};

	return (
		<section>
			<section className='mb-7'>
				<p className='text-xl font-bold text-center'>Tarjeta SUMA SIX</p>
			</section>
			<section className='flex items-center justify-center'>
				<section className='card w-[350px] h-80 mb-5 !py-4'>
					{showQR ? (
						<ClientQrCard />
					) : (
						<section className='flex items-center w-[90%] justify-evenly mx-auto'>
							<section>
								<p className='text-primary text-xl font-bold text-center'>
									{name}
								</p>
								<p className='text-xl font-black text-center'>
									{client.encryptedCard
										?.slice(-12)
										.replace(/.(?=.{4})/g, '*') ?? ''}
								</p>
							</section>
							<img src={appIcon} className='py-3 w-32 h-48 object-contain' />
						</section>
					)}
					{client.encryptedCard && (
						<button
							type='button'
							className='btn-primary w-40 mx-auto py-3 mt-5'
							onClick={toggleShowQR}>
							{showQR ? 'Ocultar QR' : 'Ver QR Tarjeta'}
						</button>
					)}
				</section>
			</section>
		</section>
	);
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(ClientCard);
