import { useEffect } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// actions
import {
	clientGetFavoriteBrands,
	clientLogout,
} from '../../redux/actions/client';
import { getBrand } from '../../redux/actions/brand';

// models
import { ClientModel } from '../../core/models/client.model';
import { StateModel } from '../../core/models/state.model';

// components
import ClientHeader from '../../components/Client/Header';
import ClientBrands from '../../components/Brand/ClientList';
import InviteFriends from '../../components/Client/InviteFriends';
import PointHistoryList from '../../components/Transaction/List';

// utils
import { logPageView } from '../../utils/firebaseConfig';

interface ProfileProps {
	client: ClientModel;
}

function Profile({ client, dispatch }: ProfileProps & DispatchProp) {
	const navigate = useNavigate();

	useEffect(() => {
		logPageView('Profile', client);
		getBrand(dispatch);
		clientGetFavoriteBrands(dispatch, client);
	}, []);

	const goToTerms = () => {
		navigate('/terminos');
	};

	const goToDeleteAccount = () => {
		navigate('/baja-del-programa');
	};
	return (
		<section className='md:px-10 lg:grid lg:grid-cols-2 lg:px-0 lg:items-center'>
			<ClientHeader />
			<section className=''>
				<p className='font-bold mt-7 ml-11 lg:text-center lg:ml-0 lg:text-xl'>
					Mis Marcas Favoritas
				</p>
				<ClientBrands />
				<InviteFriends />
			</section>
			<section className='bg-red-points col-span-2 lg:px-16'>
				<PointHistoryList />
			</section>
			<section className='flex flex-col items-center col-span-2 lg:flex-row lg:px-8'>
				<button
					className='btn-primary w-11/12 py-4 my-5 md:w-4/5'
					onClick={() => {
						clientLogout(dispatch, client);
					}}>
					Cerrar Sesión
				</button>
				<button
					className='btn-secondary w-11/12 py-4 md:w-4/5 lg:mx-6'
					onClick={goToTerms}>
					Consulta Términos y Condiciones
				</button>
				<button
					className='btn-primary w-11/12 py-4 my-4 md:w-4/5'
					onClick={goToDeleteAccount}>
					Baja del Programa
				</button>
			</section>
		</section>
	);
}

const mapStateToProps = (state: StateModel) => ({
	client: state.client,
});

export default connect(mapStateToProps)(Profile);
